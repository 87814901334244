import styled from 'styled-components';
import { ChevronRight, ChevronLeft } from 'react-feather';

export const ArrowRightIcon = styled(ChevronRight)`
  cursor: pointer;

  height: 30px;
  width: 40px;

  opacity: ${({ disabled }) => disabled && '0.5'};

  color: #fafafa;
`;

export const ArrowLeftIcon = styled(ChevronLeft)`
  cursor: pointer;

  height: 30px;
  width: 40px;

  opacity: ${({ disabled }) => disabled && '0.5'};

  color: #fafafa;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  max-width: 600px;
  border-radius: 3px;
`;

export const GroupUp = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding: 10px;

  height: 100%;
`;

export const ContainerArrows = styled.div`
  display: flex;

  justify-content: space-evenly;
  align-items: center;
  align-content: center;

  margin: 10px 0px;

  height: 25px;
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const Slide = styled.div`
  min-width: 100%;
`;

export const Image = styled.img`
  vertical-align: top;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
`;

export const GroupCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  padding: 5px;
`;

export const CheckBox = styled.input`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;

  margin-left: 5px;
  width: 15px;
  height: 15px;
  font-size: 10px;
  padding: 2px;

  background-color: #1b212a;
  border: 1px solid #fafafa;

  &:checked::after {
    content: '✓';
    color: ${({ theme }) => theme.primary};
  }
`;

export const CheckText = styled.span`
  font-size: 10px;

  color: #bdc3c7;
`;

export const Button = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  font-family: Gorga Grotesque Regular;
  -webkit-tap-highlight-color: transparent;

  font-size: 10px;
  width: 130px;
  height: 35px;
  border-radius: 3px;
  margin: 0px 10px;
  margin: 5px;
  padding: 5px;

  color: #1b212a;
  border: 1px solid #1b212a;

  background-color: ${({ theme }) => theme.primary};

  &:hover {
    opacity: 0.8;
  }

  &.primary {
    color: ${({ theme }) => theme.text};
  }
`;

export const ButtonAction = styled(Button)`
  background-color: #bdc3c7;
`;

export const GroupButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;

  padding: 10px 0px;
  background-color: #1b212a;
`;

export const SubGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;

  padding: 5px;

  background-color: #1b212a;
`;
