import { Errors as partnerErrors } from './partner/Errors';
import { Errors as keycloakErrors } from './keyCloak/Errors';

let Errors = keycloakErrors;

if (process.env.REACT_APP_PARTNER_ENABLE == `true`) {
  Errors = partnerErrors;
}

export { Errors };
