import * as React from 'react';

function Icon(props) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.48 122.88"
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#f39d00}'}</style>
      </defs>
      <path
        className="prefix__cls-1"
        d="M3.21 18.74h16.65q0-4 .06-8.26V0h73.13v18.75h17.24a3.08 3.08 0 013.07 2.93 77.67 77.67 0 01-.4 13.9A34 34 0 01109.11 48a21.77 21.77 0 01-8.8 8.6 31.91 31.91 0 01-13.9 3.4c-3.27 5.43-7.63 8-12.73 12.67-6.17 4.71-10.81 8.26-7.2 19.13h5.39a7.84 7.84 0 017.82 7.82v3.15h.77a7.69 7.69 0 015.45 2.23 7.67 7.67 0 012.26 5.45v5.23a1.77 1.77 0 01-1.77 1.77H26.58a1.77 1.77 0 01-1.77-1.77v-5.23a7.66 7.66 0 012.26-5.45 7.66 7.66 0 015.44-2.26h.77v-3.12a7.75 7.75 0 012.3-5.51 7.81 7.81 0 015.51-2.29h6.06c3.22-10.26-1-13.58-6.83-18.17A44.47 44.47 0 0127.34 60 31.87 31.87 0 0113 56.54a21.47 21.47 0 01-8.73-8.6A34.07 34.07 0 01.51 35.58 78.1 78.1 0 01.13 21.9v-.08a3.08 3.08 0 013.09-3.08zM92.71 30a121.67 121.67 0 01-2 18 15.17 15.17 0 005-1.9 10.49 10.49 0 003.69-3.89 18 18 0 001.93-6 45.37 45.37 0 00.5-6.25h-9.12zm-72.59 0H12a49.78 49.78 0 00.45 6.27 18.41 18.41 0 001.8 6 10.13 10.13 0 003.57 3.88A14.57 14.57 0 0022.54 48a92 92 0 01-2.42-18z"
      />
      <path
        d="M20.08 21.82H3.21C2.75 31.1 3.34 40 7 46.43c3.43 6.11 9.7 10.15 20.62 10.46a27.9 27.9 0 01-3.48-5.51c-6.56-.88-10.36-3.59-12.54-7.67s-2.66-9.39-2.66-15.57a1.23 1.23 0 011.23-1.23h9.91v-5.09zm72.77 5.1h10.82a1.23 1.23 0 011.23 1.22c0 6.2-.56 11.54-2.84 15.6s-6.16 6.75-12.7 7.64a25.38 25.38 0 01-3.69 5.52c11-.29 17.29-4.33 20.77-10.45 3.67-6.47 4.29-15.34 3.84-24.62H92.85v5.09z"
        fill="#f9c809"
      />
      <path
        d="M79.69 102.76h.77a7.69 7.69 0 015.45 2.24 7.67 7.67 0 012.26 5.45v10.63a1.77 1.77 0 01-1.77 1.77H26.58a1.77 1.77 0 01-1.77-1.77v-10.6a7.66 7.66 0 012.26-5.48 7.66 7.66 0 015.44-2.26h47.18z"
        fill="#222"
      />
      <path
        d="M70.64 108H35.72a4.22 4.22 0 00-3 1.25 4.26 4.26 0 00-1.25 3v5.28h50.08v-5.28a4.26 4.26 0 00-1.26-3 4.31 4.31 0 00-3-1.26z"
        fill="#ead79e"
      />
      <path
        d="M50.71 93h6V75.21c-22.17-7.88-24.26-35-29.55-72.57h-4.63v26.77C23 39.6 24.68 47.14 27 52.91a38.19 38.19 0 008.39 12.8 68.65 68.65 0 006.71 5.78C49.11 77 54.19 81 50.71 93z"
        fill="#f8b705"
      />
      <path
        d="M56.71 93H63c-3.88-12.71 1.68-17 9-22.55 8.05-6.14 18.5-14.12 18.5-40.35V2.64H27.16c3.42 24.28 5.5 44.17 12.51 57.36A39.14 39.14 0 0049 71.13a29.3 29.3 0 005.47 3.17 19.1 19.1 0 002.21.74v.15h.07V93z"
        fill="#fac809"
      />
      <path
        className="prefix__cls-1"
        d="M58.26 20.13l2.8 6.87 7.39.56a1.9 1.9 0 011 3.41l-5.59 4.74 1.76 7.18a1.9 1.9 0 01-1.41 2.29 1.88 1.88 0 01-1.49-.26L56.5 41l-6.29 3.89a1.9 1.9 0 01-2.62-.62 1.85 1.85 0 01-.23-1.44l1.75-7.18-5.66-4.8a1.91 1.91 0 011.09-3.35l7.39-.5 2.81-6.84a1.91 1.91 0 013.52 0z"
      />
      <path
        fill="#fff"
        d="M56.5 20.86l3.25 7.92 8.56.65-6.55 5.55 2.03 8.32-7.29-4.51-7.29 4.51 2.03-8.32-6.55-5.55 8.55-.65 3.26-7.92z"
      />
      <path
        d="M76.62 47.62l-.07.1a3.79 3.79 0 00-5.17.83l-.1-.08a3.52 3.52 0 00.62-2.75 3.57 3.57 0 00-1.44-2.42 26.79 26.79 0 002.82.53 3.58 3.58 0 002.42-1.45l.1.07a3.81 3.81 0 00.82 5.17zm7.65-12.82l-.07.1a3.78 3.78 0 00-5.17.82l-.1-.07a3.79 3.79 0 00-.83-5.17l.07-.1a3.8 3.8 0 005.18-.83l.09.08a3.79 3.79 0 00.83 5.17zm.06-13.56l-.13.18a6.94 6.94 0 00-9.46 1.51l-.18-.13a6.5 6.5 0 001.14-5 6.49 6.49 0 00-2.65-4.43l.13-.18a6.94 6.94 0 009.46-1.51l.18.13a6.5 6.5 0 00-1.14 5 6.51 6.51 0 002.65 4.43z"
        fillRule="evenodd"
        fill="#fff"
      />
    </svg>
  );
}

export default Icon;
