import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const Toast = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 3px;
    color: #2c3543;
    font-family: Gorga Grotesque Light;
  }

  .Toastify__toast--info {
    background-color: pink;
  }
  .Toastify__toast--success {
    background-color: #cbf96a;
  }
  .Toastify__toast--warning {
    background-color: pink;
  }
  .Toastify__toast--error {
    background-color: #ef5d5f;
  }
  .Toastify__toast-body {
    /* background-color: pink; */
  }
`;
