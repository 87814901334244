const customSymbols = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'mil' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'MM' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];

export const formatCurrency = ({ num, digits, int, abbrev_py, prize }) => {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const number = num
    .toFixed(0)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  const simbols =
    abbrev_py && !prize
      ? customSymbols
      : [
          { value: 1, symbol: '' },
          { value: 1e3, symbol: 'k' },
          { value: 1e6, symbol: 'M' },
          { value: 1e9, symbol: 'MM' },
          { value: 1e12, symbol: 'T' },
          { value: 1e15, symbol: 'P' },
          { value: 1e18, symbol: 'E' },
        ];
  // const simbols = [
  //   { value: 1, symbol: '' },
  //   { value: 1e3, symbol: 'K' },
  //   { value: 1e6, symbol: 'M' },
  //   { value: 1e9, symbol: 'G' },
  //   { value: 1e12, symbol: 'T' },
  //   { value: 1e15, symbol: 'P' },
  //   { value: 1e18, symbol: 'E' },
  // ];

  let item = simbols
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  if (int) return number;

  if (item) {
    if ((num / item.value) % 1 > 0) {
      return (
        parseInt(num / item.value) +
        '.' +
        ((num / item.value) % 1)
          .toString()
          .slice(2, 2 + digits)
          .replace(rx, '$1') +
        item.symbol
      );
    }

    return (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol;
  }

  return '0';
};
