import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { X } from 'react-feather';
import { useSpring } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { IconBg } from '../../LineVertical/style';
import { useTranslation, Trans } from 'react-i18next';
import { useNumberFormat } from '../../../../hooks/numberFormat';

import LineVertical from '../../../Shared/LineVertical/LineVertical';
import Line from '../Line/Line';

import {
  EventWrapper,
  Info,
  DateStyle,
  Day,
  BadgeHours,
  Hours,
  Teams,
  Home,
  DescriptionEvent,
  LineWrapper,
  MessageLoss,
  Title,
  Description,
  ButtonChallenge,
  MessageWin,
  InfoSurvives,
  SurvivesDescription,
  CountUsers,
  GroupInfo,
} from './style';

import { useChallenge } from '../../../../pages/ChallengeDetails/Context';

const Event = ({ round, event, idx, isLastEvent }) => {
  const { challenge } = useChallenge();

  const history = useHistory();
  const { t } = useTranslation();

  const { separator, moneyIntl, currencySymbol } = useNumberFormat();

  const opacity = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 0,
    duration: 100,
  });

  const ref = useRef(null);

  const handleGetColor = useCallback(
    (myResult) => {
      switch (myResult) {
        case 'Selected':
          return 'white';

        case 'Correct':
          return '#cbf96a';

        case 'Wrong':
          return '#ef5d5f';

        case 'None':
          return '#7E889A';

        default:
          return '#7E889A';
      }
    },
    [round]
  );

  const isStatusFinished = challenge.status === 'Finished';

  return (
    <>
      <EventWrapper key={idx} marginTop={idx !== 0}>
        <Info>
          <Teams>
            <Home canceled={round.myResult === 'Interrupted'}>
              {event.name}
            </Home>

            <DescriptionEvent canceled={round.myResult === 'Interrupted'}>
              {event.tips}
            </DescriptionEvent>
          </Teams>

          <DateStyle>
            <Day
              canceled={round.myResult === 'Interrupted'}
              finalized={event.status === 'Ended'}
            >
              {event.startDate.date}
            </Day>

            <BadgeHours finalized={event.status === 'Ended'}>
              <Hours canceled={round.myResult === 'Interrupted'}>
                {event.startDate.hour}
              </Hours>
            </BadgeHours>
          </DateStyle>
        </Info>

        <LineWrapper>
          {event.lines.map((line) => {
            const countLines = event && event.lines.length;

            return (
              <Line
                key={line.ticket}
                idx={line.ticket}
                countLines={countLines}
                line={line}
                handleGetColor={handleGetColor}
                event={event}
                round={round}
              />
            );
          })}
        </LineWrapper>
      </EventWrapper>
      {isLastEvent && round.countPlayers && (
        <InfoSurvives style={opacity}>
          <GroupInfo>
            {isStatusFinished && round.endGame && (
              <SurvivesDescription>
                <CountUsers>{separator(round.countPlayers.survive)}</CountUsers>

                <Trans
                  i18nKey="round.count.user.win.finished"
                  count={round.countPlayers.survive === 1 ? 1 : 2}
                />
              </SurvivesDescription>
            )}

            {!round.endGame && (
              <SurvivesDescription>
                <CountUsers>{separator(round.countPlayers.survive)}</CountUsers>
                <Trans
                  i18nKey="round.count.user.win"
                  count={round.countPlayers.survive}
                />
              </SurvivesDescription>
            )}
          </GroupInfo>

          <LineVertical />

          <GroupInfo>
            {round.countPlayers.eliminated === 0 && (
              <SurvivesDescription>
                <>{t('round.count.userEmpty.loss')}</>
              </SurvivesDescription>
            )}

            {round.countPlayers.eliminated > 0 && (
              <SurvivesDescription>
                <CountUsers>
                  {separator(round.countPlayers.eliminated)}
                </CountUsers>
                <Trans
                  i18nKey="round.count.user.loss"
                  count={round.countPlayers.eliminated}
                />
              </SurvivesDescription>
            )}

            {round.countPlayers.voided > 0 && (
              <SurvivesDescription>
                {t('round.count.user.fernandochange')}
                <CountUsers>{separator(round.countPlayers.voided)}</CountUsers>

                <Trans
                  i18nKey="round.count.user.notbet"
                  count={round.countPlayers.voided}
                />
              </SurvivesDescription>
            )}
          </GroupInfo>
        </InfoSurvives>
      )}
      {isLastEvent && round.myResult === 'Interrupted' && (
        <div style={{ width: `1px`, margin: '5px auto -5px' }}>
          <LineVertical />
        </div>
      )}

      {!round.endGame && round.myResult === 'Eliminated' && isLastEvent && (
        <MessageLoss style={opacity}>
          <IconBg size={50}>
            <X color="#EF5D5F" />
          </IconBg>
          <Title>{t('round.message.title.loss')}</Title>
          <Title size={13}>
            {t('round.message.subTitle.loss').toUpperCase()}
          </Title>
          <Description>{t('round.message.description.loss')}</Description>
          <ButtonChallenge onClick={() => history.push('/')}>
            {t('round.button.challenges')}
          </ButtonChallenge>
        </MessageLoss>
      )}
    </>
  );
};

Event.propTypes = {
  idx: PropTypes.number.isRequired,
  round: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  isLastEvent: PropTypes.bool,
};

Event.defaultProps = {
  handleClose: () => {},
};

export default Event;
