import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Round from '../../components/Shared/Round/Round';
import Button from '../../components/Shared/Button/Button';

import * as S from './style';
import { useMutation } from '@apollo/client';
import { MUT_JOIN } from '../../queries/challenge';
import { Star } from 'react-feather';

const Join = (props) => {
  if (props.status !== 'Sale' || props.myRole === 'Player') {
    return null;
  }

  return <InnerButtonJoin {...props} settings={buttonSettings(props)} />;
};

Join.propTypes = {
  onJoined: PropTypes.func,
  status: PropTypes.string,
  isFree: PropTypes.bool,
  id: PropTypes.string,
  isVip: PropTypes.bool,
  allowManualJoin: PropTypes.bool,
  isUserVip: PropTypes.bool,
  myRole: PropTypes.string,
};

const buttonSettings = (props) => {
  const { isFree, isVip, isUserVip, allowManualJoin } = props;

  if (isVip && isUserVip) {
    return {
      title: 'challenge.button.title.confirmVip',
      description: 'challenge.button.description.confirmVip',
      descriptionConfirm: null,
      confirm: true,
      disabled: false,
      variant: 'vip',
      icon: <Star color="#654200" size="14" />,
    };
  } else if (isVip && !allowManualJoin && !isUserVip) {
    return {
      title: 'challenge.button.title.vipOnly',
      description: 'challenge.button.description.vipOnly',
      descriptionConfirm: null,
      confirm: false,
      disabled: true,
      icon: null,
    };
  } else if (isFree) {
    return {
      title: 'challenge.button.title.free',
      description: 'challenge.button.description.free',
      descriptionConfirm: null,
      confirm: true,
      disabled: false,
    };
  }
  return {
    title: 'challenge.button.title.buyin',
    description: 'challenge.button.description.buyin',
    descriptionConfirm: 'challenge.button.description.confirmBuyin',
    confirm: true,
    disabled: false,
  };
};

const InnerButtonJoin = ({ onJoined, settings, id }) => {
  const { t } = useTranslation();
  const [doJoin] = useMutation(MUT_JOIN);
  const [feedbackMessage, setFeedbackMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const { title, description, descriptionConfirm, ...settingProps } = settings;

  const handleClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await doJoin({
        variables: {
          joinChallengeId: id,
        },
      });
      onJoined();
    } catch (error) {
      setLoading(false);
      setFeedbackMessage(() => buildErrorMessage(error, t));
    }
  };

  return (
    <S.Events>
      <Round variant="join" />
      <Button
        title={t(title)}
        description={t(description)}
        onPress={handleClick}
        descriptionConfirm={t(descriptionConfirm)}
        {...settingProps}
      />
    </S.Events>
  );
};

InnerButtonJoin.propTypes = {
  onJoined: PropTypes.func,
  settings: PropTypes.object,
  id: PropTypes.string,
};

const buildErrorMessage = (error, t) => {
  let message = error.message;

  if (
    error.graphQLErrors &&
    error.graphQLErrors[0].extensions.code === 'WALLET_INSUFFICIENT_FUNDS'
  ) {
    message = t('challenge.notEnoughMoney');
  }

  return {
    message,
    type: 'error',
  };
};

export default Join;
