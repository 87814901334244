import React from 'react';
import PropTypes from 'prop-types';

import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';

import ShareButton from '../ShareButton/ShareButton';

import { Wrapper, Content, Aside, BadgeIcon } from './style';

const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

const Header = ({ goBackPath, children, shareButton, fixed }) => {
  const history = useHistory();

  const handleGoBackPath = () => {
    if (history.action === 'POP') {
      return history.push(goBackPath);
    }
    history.goBack();
  };

  return (
    <Wrapper fixed={fixed}>
      <Aside>
        <BadgeIcon onClick={handleGoBackPath}>
          <ArrowLeft icon="arrow-left" size={20} color="#ffff" />
        </BadgeIcon>
      </Aside>

      <Content>{children}</Content>

      <Aside>{shareButton && !compactMode && <ShareButton />}</Aside>
    </Wrapper>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  goBackPath: PropTypes.string,
  length: PropTypes.number,
  fixed: PropTypes.bool,
  shareButton: PropTypes.bool,
};

Header.defaultProps = {
  goBackPath: '/',
  children: null,
  title: '',
};

export default Header;
