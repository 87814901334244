import React from 'react';
import { Wrapper, Content, Icon, Title, SubTitle, Button } from './style';
import Robot404 from './../../assets/robot404.json';
import Lottie from 'lottie-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Icon>
        <Lottie
          loop={false}
          animationData={Robot404}
          style={{ width: '100%' }}
        />
      </Icon>

      <Content>
        <Title>{t('notFound.content.title')}</Title>

        <SubTitle>{t('notFound.content.subTitle')}</SubTitle>

        <Button onClick={() => history.push('/')}>
          {t('notFound.content.button')}
        </Button>
      </Content>
    </Wrapper>
  );
};

export default NotFound;
