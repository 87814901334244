import React from 'react';
import PropTypes from 'prop-types';

import { ApolloProvider } from '@apollo/client';
import client from './instance';

const Apollo = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

Apollo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

Apollo.defaultProps = {
  children: null,
};

export default Apollo;
