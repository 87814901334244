import React from 'react';

import { useTranslation } from 'react-i18next';
import { useChallenge } from '../../../pages/ChallengeDetails/Context';

import FinalMessage from './FinalMessage/FinalMessage';
import Round from '../Round/Round';
import CountDownMyResult from '../CountDownMyResult/CountDownMyResult';

import 'react-toastify/dist/ReactToastify.css';

import * as S from './style';

const statusTranslate = {
  WaitingNewRound: 'challenge.waitingNewRound',
  WaitingResult: 'challenge.waitingResult',
};

const Challenge = () => {
  const { challenge, myResultResolver } = useChallenge();
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Events>
        {Object.keys(statusTranslate).includes(myResultResolver) && (
          <>
            <S.Status myResult={myResultResolver}>
              <S.Clock />

              <S.StatusFeedBack>
                {t(statusTranslate[myResultResolver])}
              </S.StatusFeedBack>
            </S.Status>
            <S.Group>
              <CountDownMyResult
                hideIcon
                hideLabel
                hideWhenDone
                myRole={challenge.myRole}
                myResult={myResultResolver}
                timestamps={{
                  nextRoundStartDate: challenge.nextRoundStartDate,
                }}
              />
            </S.Group>
          </>
        )}
        <FinalMessage challenge={challenge} />

        <Round />
      </S.Events>
    </S.Wrapper>
  );
};

export default Challenge;
