import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { MUT_BET } from '../../queries/challenge';
import { listenToChallengeSlug } from '../../components/AppSub/AppSub';

const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

const defaultValue = {
  challenge: undefined,
  selectedEvents: {},
  feedbackMessage: {},
  toggleEvent: () => {},
  isAllEventsSelected: false,
  clearSelectedEvents: () => {},
  handleBet: () => {},
  isAvaliableToBet: false,
  isRegisteringBet: false,
  myResultResolver: undefined,
  scrollToEnd: () => {},
};

const ChallengeContext = createContext(defaultValue);

let firstLoad = false;

export const ChallengeProvider = ({
  children,
  challenge,
  subscriptionHandler,
  refetch,
}) => {
  const [isRegisteringBet, setRegisteringBet] = useState(false);
  const [countSelectedEvents, setCountSelectedEvents] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState({});
  const [myResultResolver, setMyResultResolver] = useState();

  const [selectedEvents, setSelectedEvents] = useState(
    defaultValue.selectedEvents
  );

  const { t } = useTranslation();
  const [doBet] = useMutation(MUT_BET);

  const toggleEvent = useCallback(
    (event, ticket) => {
      setFeedbackMessage(() => ({}));
      selectedEvents[event] = ticket;
      setSelectedEvents(() => ({ ...selectedEvents }));
      setCountSelectedEvents(() => Object.keys(selectedEvents).length);
    },
    [selectedEvents]
  );

  const clearSelectedEvents = useCallback(() => {
    setSelectedEvents(() => ({}));
    setCountSelectedEvents(() => 0);
  }, [selectedEvents]);

  const isAllEventsSelected = useCallback(() => {
    const lastRound = challenge.rounds[challenge.rounds.length - 1];
    if (!lastRound) return false;
    return countSelectedEvents === lastRound.events.length;
  }, [selectedEvents]);

  const isAvaliableToBet = (round) => {
    return (
      round.myResult === 'PendingBet' && challenge.myResult === 'PendingBet'
    );
  };

  const handleBet = useCallback(
    async (roundId) => {
      if (!isAllEventsSelected()) {
        setFeedbackMessage({
          message: t('challenge.toast.error'),
          type: 'error',
        });

        return;
      }

      setRegisteringBet(true);
      const tickets = Object.values(selectedEvents);

      try {
        const bet = await doBet({
          variables: {
            challengeId: challenge.id,
            roundId,
            tickets,
          },
        });

        setRegisteringBet(false);
        clearSelectedEvents();
        if (bet.data) {
          setFeedbackMessage({
            message: t(`feedback.${bet.data.appBet.message}`),
            type: 'success',
          });
          refetch();
        }
      } catch (error) {
        setFeedbackMessage({ message: error.message, type: 'error' });
        setRegisteringBet(false);
      }
    },
    [selectedEvents]
  );

  useEffect(() => {
    setMyResultResolver(
      challenge.myResult === 'Eliminated' || challenge.myRole === 'Guest'
        ? challenge.lastRoundStatus
        : challenge.myResult
    );

    clearSelectedEvents();
    setFeedbackMessage(() => ({}));

    const sub = listenToChallengeSlug(
      subscriptionHandler,
      challenge.id,
      (newChallenge) => {
        const { rounds: _rounds } = challenge;
        const { rounds } = newChallenge;

        const { status: _status } = _rounds[_rounds.length - 1] || [];
        const { status } = rounds[rounds.length - 1] || [];

        if (_rounds.length != rounds.length || _status !== status) {
          clearSelectedEvents();
          setFeedbackMessage(() => ({}));
        }
        challenge = { ...newChallenge };
      }
    );
    return () => {
      sub && sub();
      firstLoad = false;
    };
  }, []);

  useEffect(() => {
    setMyResultResolver(
      challenge.myResult === 'Eliminated' ||
        challenge.myResult === 'None' ||
        challenge.myRole === 'Guest'
        ? challenge.lastRoundStatus
        : challenge.myResult
    );
  }, [challenge.myResult, challenge.lastRoundStatus, challenge.myRole]);

  const scrollToEnd = (ref) => {
    if (ref?.current && !firstLoad) {
      firstLoad = true;

      if (compactMode) {
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          window.top.postMessage(
            {
              target: 'survive',
              content: {
                name: 'scrollTo',
                value: ref.current.parentElement.getBoundingClientRect().height,
              },
            },
            '*'
          );
        }, 200);
      }
    }
  };

  return (
    <ChallengeContext.Provider
      value={{
        ...defaultValue,
        selectedEvents,
        toggleEvent,
        clearSelectedEvents,
        isAllEventsSelected,
        handleBet,
        isRegisteringBet,
        feedbackMessage,
        challenge,
        isAvaliableToBet,
        myResultResolver,
        scrollToEnd,
      }}
    >
      {children}
    </ChallengeContext.Provider>
  );
};

ChallengeProvider.propTypes = {
  children: PropTypes.node,
  challenge: PropTypes.object,
  subscriptionHandler: PropTypes.func,
  refetch: PropTypes.func,
};

export const useChallenge = () => {
  const values = useContext(ChallengeContext);
  return values;
};
