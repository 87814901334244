/* eslint-disable react/prop-types */
import React from 'react';
import { Trans } from 'react-i18next';
import { useChallenge } from '../../../../../pages/ChallengeDetails/Context';
import { useNumberFormat } from '../../../../../hooks/numberFormat';

const AllPlayersWrong = ({ typePrize, components }) => {
  const { currencySymbol, moneyIntl } = useNumberFormat();

  const {
    challenge: { resultPrize, otherPrizeDescriptionSingular, prizePool },
  } = useChallenge();

  const unitPrizeSurvive =
    typePrize === 'Other'
      ? prizePool
      : moneyIntl(resultPrize.unitPrizeWrong.float);

  const values = {
    currency: currencySymbol(),
    totalPrize: moneyIntl(resultPrize.totalPrize.float),
    resultPrizeWrongs: resultPrize.wrongs,
    unitPrizeWrong: unitPrizeSurvive,
    otherPrizeDescriptionSingular: otherPrizeDescriptionSingular,
  };

  const options = { components, values };

  const i18nKey =
    typePrize === 'Other' ? 'endGame.allWrong.other' : 'endGame.allWrong';

  return <Trans {...{ i18nKey, ...options }} />;
};

export default AllPlayersWrong;
