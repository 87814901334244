/* eslint-disable react/prop-types */
import React from 'react';
import { Trans } from 'react-i18next';
import { useChallenge } from '../../../../../pages/ChallengeDetails/Context';

const Survive = ({ myResult, isGuest, eliminatedBefore, components }) => {
  const {
    challenge: { resultPrize },
  } = useChallenge();

  const options = { components, count: resultPrize.winners };

  if (eliminatedBefore || isGuest)
    return <Trans i18nKey="congratulation.guest.survive" {...options} />;

  if (myResult === 'Survive')
    return <Trans i18nKey="congratulation.survive" {...options} />;

  if (myResult === 'Eliminated')
    return <Trans i18nKey="congratulation.eliminated" {...options} />;
};

export default Survive;
