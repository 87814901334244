import styled from 'styled-components';

import { Clock as ClockDefaultIcon } from 'react-feather';

export const Wrapper = styled.div`
  user-select: none;

  margin-bottom: 10px;
`;

export const Events = styled.div`
  padding: 0px 10px;
`;

export const Error = styled.h1`
  font-family: Gorga Grotesque Light;
  text-align: right;

  font-size: 9px;

  color: #ef5d5f;
`;

export const DescriptionModal = styled.h1`
  font-family: Gorga Grotesque Light;

  font-size: 13px;

  color: #ffff;
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: ${({ myResult }) =>
    myResult === 'WaitingNewRound' ? '20px 20px 0 20px' : '20px'};
`;

export const StatusFeedBack = styled.h1`
  font-family: Gorga Grotesque Light;

  font-size: 12px;

  color: #ffff;
`;

export const Clock = styled(ClockDefaultIcon).attrs(({ size, icon }) => {
  return {
    icon: icon,
    size: size,
  };
})`
  ${({ width }) => width && `width: 20px;`}

  position: relative;

  width: 13px;
  height: 13px;
  margin-right: 5px;
  top: -1px;

  color: #fff;
`;

export const Group = styled.div`
  &:not(:empty) {
    padding: 5px 20px 0;

    display: flex;
    justify-content: center;
  }
`;
