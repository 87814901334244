import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
`;

export const Group = styled.div`
  padding: 0px 10px;

  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  padding-top: 5px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-family: Gorga Grotesque Light;
`;

export const LineGradientHeader = styled.div`
  margin: 10px 0px;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
`;

export const Content = styled.div`
  margin-top: 10px;
  padding: 10px 10px;

  background-color: #252c39;

  width: 100%;

  @media (max-width: 600px) {
    margin-top: 55px;
  }
`;

export const Description = styled.div`
  padding: 10px;
  font-size: 14px;

  color: #fafafa;
  font-family: Gorga Grotesque Light;
  line-height: 25px;
`;

export const SubTitle = styled.h1`
  margin-top: 20px;
  padding: 5px;
  font-size: 15px;

  color: #fafafa;

  font-family: Gorga Grotesque Regular;
`;

export const Contact = styled.div`
  margin: 5px 0px;

  display: flex;
  justify-content: flex-start;
`;

export const Type = styled.h1`
  font-size: 12px;
  padding: 0px 5px;
  min-width: 120px;

  color: #fafafa;

  font-family: Gorga Grotesque Light;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
`;

export const Info = styled.h1`
  padding: 0px 5px;
  font-size: 12px;

  color: #fafafa;

  font-family: Gorga Grotesque Light;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.div`
  color: ${({ color }) => color};

  padding-right: 5px;
`;
