import { gql } from '@apollo/client';

export const USER_ME = gql`
  query getUserMe {
    userMe {
      hasTelegram
    }
  }
`;

export const MUT_NOTIFICATION_TELEGRAM = gql`
  mutation NotificationTelegram($chat_id: String!) {
    createUserNotificationTelegram(chatId: $chat_id)
  }
`;
