import { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
// import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import { useSurviveAuth } from '../../../SurviveAuth';
import { globalErrorMessageVar } from '../../../Apollo/vars';

export const Errors = () => {
  // const { t } = useTranslation();
  const { login } = useSurviveAuth();

  const errorMessage = useReactiveVar(globalErrorMessageVar);
  const errorsToLogout = ['E_UNAUTHORIZED', 'EMPTY_TOKEN', 'INVALID_TOKEN'];

  // const messages = {
  //   WALLET_INSUFFICIENT_FUNDS: 'WALLET_INSUFFICIENT_FUNDS', // Adicionar as chaves de tradução
  //   E_UNAUTHORIZED: 'E_UNAUTHORIZED',
  //   EMPTY_TOKEN: 'EMPTY_TOKEN',
  //   INVALID_TOKEN: 'INVALID_TOKEN',
  // };

  useEffect(() => {
    if (isEmpty(errorMessage)) return;

    toast.error(errorMessage.message);

    if (errorsToLogout.includes(errorMessage.codeError)) login();
  }, [errorMessage]);

  return null;
};
