/* eslint-disable no-undef */
import Keycloak from 'keycloak-js';

import { app } from './app';

const config = {
  clientId: app.keycloack.clientId,
  realm: app.keycloack.realm,
  url: app.keycloack.url,
  scheme: app.keycloack.scheme,
};

let initConfig = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  checkLoginIframe: true,
};

let keycloak = new Keycloak(config);

export { initConfig };

export default keycloak;
