/* eslint-disable react/prop-types */
import React from 'react';

import * as S from '../style';

import Survive from './Survive';
import SurviveWithWrong from './SurviveWithWrong';
import AllPlayersWrong from './AllPlayersWrong';

const components = {
  bold: <S.TextBold />,
  br: <br />,
};

const descriptions = {
  Survive,
  SurviveWithWrong,
  AllPlayersWrong,
};

const Description = ({ typeWinner, ...props }) => {
  const Component = descriptions[typeWinner];

  return (
    <S.Message>
      <Component {...props} components={components} />
    </S.Message>
  );
};

export default Description;
