import styled, { css } from 'styled-components';
import LottieReact from 'lottie-react';
import { Bell as BealIcon } from 'react-feather';

export const Wrapper = styled.div`
  font-family: Gorga Grotesque Light;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 5px 1px #191e27;
  padding: 20px;
  border-radius: 4px;
  font-size: 0.5em;
  background: #169be514;
  border: ${({ variant }) =>
    variant === 'default' ? 'solid 1px rgba(22, 155, 229, 0.4)' : ''};

  ${({ variant }) =>
    variant === 'modal'
      ? css`
          box-shadow: none;
          font-size: 0.6em;

          ${Header} {
            flex-direction: column;
            align-items: center;

            h1 {
              font-size: 1.7em;
            }

            img {
              margin-right: 0;
              margin-bottom: 20px;
            }
          }

          ${Body} {
            margin: 10px 0 0 0;
            justify-content: center;
            text-align: center;

            > div {
              justify-content: center;
              margin-bottom: 0;
            }
            a {
              text-align: center;
            }
          }
        `
      : ''}

  overflow: hidden;

  background: #222b36;

  @media (max-width: 480px) {
    font-size: 0.6em;
  }
`;

export const Header = styled.div`
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;

    img {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  img {
    height: 50px;
    margin-right: 12px;
  }

  p {
    margin-top: 6px;
    font-size: 1.3em;
    line-height: 1.7em;
    text-align: justify;
  }
`;

export const Body = styled.div`
  margin: 10px 0 0 60px;

  > div {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0 20px;
  }

  @media (max-width: 480px) {
    margin: 10px 0 0 0;
    justify-content: center;
    text-align: center;

    > div {
      justify-content: center;
    }
    a {
      text-align: center;
    }
  }

  a {
    color: white;
    font-size: 1.3em;
    margin-top: 12px;

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  box-sizing: border-box;
  position: relative;
  min-width: 1.5em;
  min-height: 1.5em;
  color: yellow;
  border: 1px solid #169be5;
  border-radius: 4px;
  appearance: none;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  cursor: pointer;
  margin-left: 10px;

  &:before {
    box-sizing: border-box;
    position: absolute;
    content: ' ';
    display: block;
    top: 0px;
    left: 6px;
    width: 7px;
    height: 14px;
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: white;
    border-color: transparent;
    background: #169be5;
    &::before {
      opacity: 1;
    }
  }

  ~ label {
    cursor: pointer;
    margin-left: 10px;
    font-size: 1.2em;
    text-align: left;
  }
`;

export const Bell = styled(BealIcon)`
  color: white;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.3);
  }

  &.error {
    color: #b1292bcf;
  }
  &.success {
    color: #a7cb5b;
  }
`;

export const Button = styled.button`
  position: relative;
  max-width: 350px;
  margin: 20px 0 0;
  border: none;
  color: white;
  background: #169be5;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all ease-in-out 200ms;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.3);
  }

  &.error {
    background: #b1292bcf;

    &:after {
      content: attr(data-error-message);
      position: absolute;
      font-size: 0.6em;
      top: -11px;
      right: 0;
      left: 0;
      opacity: 0.5;
    }
  }
  &.success {
    background: #a7cb5b;
  }
`;

export const Loading = styled(LottieReact)`
  position: absolute;
  top: 11px;
  left: -34px;

  @media (max-width: 480px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;
