import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';

export const Wrapper = styled.div`
  user-select: none;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #a6aebc;

  margin-top: 1px;
  padding-right: 10px;
`;

export const Aside = styled.div`
  display: flex;
  align-items: center;
`;

export const BadgeIcon = styled.div`
  background: #1e242e;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin-right: 10px;
  padding-bottom: 4px;
  width: 30px;
  height: 35px;
`;

export const Icon = styled(FeatherIcon).attrs(({ size, icon }) => {
  return {
    icon: icon,
    size: size,
  };
})`
  color: #a6aebc;
`;

export const Label = styled.h1`
  font-family: Gorga Grotesque Regular;
  color: #ffffff;

  text-align: center;
  padding-top: 3px;
  padding-right: 10px;
  font-size: 17px;
`;

export const DescriptionChallenge = styled.div`
  margin: 10px;
`;

export const DescriptionText = styled.div`
  font-family: Gorga Grotesque Light;
  text-align: left;
  color: #7e889a;
  font-size: 12px;
  line-height: 16px;
`;

export const Events = styled.div`
  padding: 0px 10px;
  > div:first-child {
    margin-bottom: 20px;
  }
`;

export const Error = styled.h1`
  font-family: Gorga Grotesque Light;
  font-size: 9px;
  text-align: right;

  color: #ef5d5f;
`;

export const Notification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled.h1`
  font-family: Gorga Grotesque Regular;
  color: #7e889a;
  font-size: 13px;
`;

export const Description = styled.h1`
  font-family: Gorga Grotesque Light;
  color: #4f5867;
  font-size: 11px;
`;

export const ButtonNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 101px;
  height: 24px;
  cursor: pointer;

  text-align: center;
  font-family: Gorga Grotesque Regular;
  font-size: 10px;
  color: #00000090;
  background: #9ff191;
  border-radius: 30px;
  margin-top: 10px;
`;

export const DescriptionModal = styled.h1`
  font-family: Gorga Grotesque Light;
  color: #ffff;

  font-size: 13px;
`;

export const TitleModal = styled.h1`
  font-family: Gorga Grotesque Regular;
  color: #ffffff;

  text-align: center;
  padding: 10px;
  font-size: 17px;
`;

export const Group = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
