import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { app } from '../../config/app';
import { GET_PARTNER_INFO } from '../../queries/partnerInfo';
import keycloak from '../../config/keycloack';
import { globalErrorMessageVar, partnerInfoVar } from './vars';

function getToken() {
  if (process.env.REACT_APP_PARTNER_ENABLE == `true`) {
    // eslint-disable-next-line no-undef
    return localStorage.getItem('survive@session');
  }

  return keycloak.token;
}

const authLink = setContext((_, { ...context }) => {
  return {
    headers: {
      Authorization: getToken() ? `Bearer ${getToken()}` : '',
      'X-Custom-Lang': partnerInfoVar().language,
    },
    ...context,
  };
});

const wsLink = new WebSocketLink({
  uri: app.apollo.wsURI,
  options: {
    reconnect: true,
    connectionParams: () => ({
      ...app.apollo.headers,
      Authorization: getToken() ? `Bearer ${getToken()}` : '',
    }),
  },
});

const errorsHandleByComponent = ['WALLET_INSUFFICIENT_FUNDS'];
const httpError = onError(({ graphQLErrors = null }) => {
  if (graphQLErrors) {
    const codeError = graphQLErrors[0].extensions.code;

    if (!errorsHandleByComponent.includes(codeError)) {
      const message =
        graphQLErrors[0].extensions?.exception?.message || codeError;
      globalErrorMessageVar({ codeError, message });
    }
  }
});

const httpLink = new HttpLink({
  uri: app.apollo.httpURI,
  credentials: app.apollo.credentials,
  headers: {
    ...app.apollo.headers,
  },
});

const splitLink = split(
  (operation) => {
    const definition = getMainDefinition(operation.query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  authLink.concat(wsLink),
  authLink.concat(httpError.concat(httpLink))
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {},
  }),
  link: splitLink,
});

client
  .query({
    query: GET_PARTNER_INFO,
  })
  .then((response) => {
    partnerInfoVar(response.data.partnerInfo);
  });

export default client;
