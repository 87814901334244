import React from 'react';

import Header from '../../components/Shared/Header/Header';
import Acordion from '../../components/Shared/Acordion/Acordion';

import { useTranslation } from 'react-i18next';

import { Wrapper, Title, Content, Group } from './style';

const Faq = () => {
  const { t } = useTranslation();

  const data = t('faq', { returnObjects: true }).filter(
    (row) => row.question !== `empty`
  );

  return (
    <Wrapper>
      <Header fixed>
        <Title>{t('faq.title')}</Title>
      </Header>
      <Group>
        <Content>
          <Acordion data={data} />
        </Content>
      </Group>
    </Wrapper>
  );
};

export default Faq;
