import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from '../Shared/Loading/Loading';
import { useSurviveAuth } from '../SurviveAuth';
import { meVar, partnerInfoVar } from '../Apollo/vars';
import { useReactiveVar } from '@apollo/client';
import client from '../Apollo/instance';
import { USER_ME } from '../../queries/user';

const ApplicationLoading = ({ children }) => {
  const { initialized } = useSurviveAuth();
  const partnerInfo = useReactiveVar(partnerInfoVar);

  if (!initialized || !partnerInfo.host) return <LoadingIndicator global />;

  // eslint-disable-next-line no-undef
  if (partnerInfo.isProvider && window.location === window.parent.location) {
    // eslint-disable-next-line no-undef
    window.location.href = partnerInfo.urlApi;
  }

  client
    .query({
      query: USER_ME,
    })
    .then((response) => {
      meVar(response.data.userMe);
    });

  return <>{children}</>;
};

ApplicationLoading.propTypes = {
  children: PropTypes.node,
};

export default ApplicationLoading;
