import React from 'react';

import Header from '../../components/Shared/Header/Header';
import Card from '../../components/Shared/Card/Card';

import { useTranslation } from 'react-i18next';

import * as S from './style';
import ChallengeTabs from '../../components/Shared/ChallengeTabs/ChallengeTabs';
import { GET_MYCHALLENGES_V2 } from '../../queries/challenge';
import { useEnums } from '../../hooks/useEnum';

const MyChallenges = () => {
  const { t } = useTranslation();
  const { myResultStyle } = useEnums();

  const playingHighlight = myResultStyle('PendingBet').background;
  const surviveHighlight = myResultStyle('Survive').background;
  const eliminatedHighlight = myResultStyle('Eliminated').background;

  return (
    <S.Wrapper>
      <Header fixed>
        <S.Title>{t('myChallenges.header.title')}</S.Title>
      </Header>
      <div style={{ marginTop: 45 }} />
      <ChallengeTabs
        query={GET_MYCHALLENGES_V2}
        keyName={'appMyChallengesV2'}
        tabs={[t('myChallenges.tabs.actives'), t('myChallenges.tabs.finished')]}
        empty={[
          t('myChallenges.no.active.challenges'),
          t('myChallenges.no.finished.challenges'),
        ]}
        filter={['InProgress', 'Finished']}
        status={[
          ['Sale', 'InPlay'],
          ['Finished', 'FinishedForced', 'Canceled', 'Interrupted'],
        ]}
        renderItem={({ currentTab, challenge, status }) => {
          return (
            <Card
              status={status.label}
              statusStyle={status.style}
              key={challenge.id}
              challenge={challenge}
              playingHighlight={currentTab === 0 ? playingHighlight : ''}
              surviveHighlight={currentTab === 0 ? surviveHighlight : ''}
              eliminatedHighlight={currentTab === 0 ? eliminatedHighlight : ''}
            />
          );
        }}
      />
    </S.Wrapper>
  );
};

export default MyChallenges;
