import styled from 'styled-components';
import { animated } from 'react-spring';

export const Button = styled.div`
  padding: 5px 0px;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  padding: 10px;
`;

export const LineGradientHeader = styled.div`
  margin: 10px 0px;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
`;

export const Content = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  color: white;
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  align-content: center;
`;

export const Description = styled(animated.div)`
  width: 100%;
  max-height: 0;
  display: flex;
  border: none;
  overflow: none;
  cursor: pointer;
  overflow: auto;
`;

export const Title = styled.h1`
  font-size: 15px;

  color: white;
  display: block;
  font-family: Gorga Grotesque Regular;
  line-height: 23px;

  &:hover {
    color: #a6aebc;
  }
`;

export const DescriptionContent = styled.div`
  line-height: 20px;
  padding: 10px 0px;
  font-size: 10px;
  cursor: initial;

  width: 100%;

  color: #c3d0dd;

  font-family: Gorga Grotesque light;
`;

export const Icon = styled.div`
  color: white;

  transform: ${({ active, index }) =>
    active === index ? 'rotate(90deg)' : 'rotate(-90deg)'};
  transition: transform 400ms ease;

  &:hover {
    color: #a6aebc;
  }
`;

export const LineGradient = styled.div`
  margin-top: 5px;
  height: 0.8px;

  width: 100%;

  background: #a6aebc;
`;
