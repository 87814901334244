import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper, Header, Label } from './style';
import ChallengeTabs from '../../components/Shared/ChallengeTabs/ChallengeTabs';
import Card from '../../components/Shared/Card/Card';
import { GET_CHALLENGES_V2 } from '../../queries/challenge';
import { useEnums } from '../../hooks/useEnum';

const Home = () => {
  const { t } = useTranslation();
  const { myResultStyle } = useEnums();

  const playingHighlight = myResultStyle('PendingBet').background;
  const surviveHighlight = myResultStyle('Survive').background;
  const eliminatedHighlight = myResultStyle('Eliminated').background;

  return (
    <Wrapper>
      <Header>
        <Label>{t('home.title')}</Label>
      </Header>

      <ChallengeTabs
        query={GET_CHALLENGES_V2}
        keyName={'appChallengesV2'}
        tabs={[
          t('home.tabs.inSale'),
          t('home.tabs.inProgress'),
          t('home.tabs.finished'),
        ]}
        empty={[
          t('home.no.sale.challenges'),
          t('home.no.inPlay.challenges'),
          t('home.no.finished.challenges'),
        ]}
        filter={['Sale', 'InProgress', 'Finished']}
        status={[
          ['Sale'],
          ['InPlay'],
          ['Finished', 'FinishedForced', 'Canceled', 'Interrupted'],
        ]}
        fallbackEmptyTabWhenLoad={1}
        renderItem={({ currentTab, challenge, status }) => {
          return (
            <Card
              status={status.label}
              statusStyle={status.style}
              key={challenge.id}
              challenge={challenge}
              playingHighlight={currentTab === 1 ? playingHighlight : ''}
              surviveHighlight={currentTab === 1 ? surviveHighlight : ''}
              eliminatedHighlight={currentTab === 1 ? eliminatedHighlight : ''}
            />
          );
        }}
      />
    </Wrapper>
  );
};

export default Home;
