import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation, Trans } from 'react-i18next';
import { useNumberFormat } from '../../../hooks/numberFormat';
import { icons } from '../../../config/images';

import Badge from '../Badge/Badge';
import BadgeInfo from '../BadgeInfo/BadgeInfo';

import * as S from './style';
import { useChallenge } from '../../../pages/ChallengeDetails/Context';

const BannerDetails = () => {
  const { currencySymbol, moneyIntl, separator } = useNumberFormat();
  const { challenge } = useChallenge();

  const isVip = challenge.forGuests || challenge.allowVip;

  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.SubWrapper>
        <S.LeftGroup>
          <S.TextWrapper>
            <S.Title>{challenge.name}</S.Title>

            <S.Group>
              <S.IdentifyText>
                {t('bannerDetails.identification', {
                  var: challenge.idNumber,
                })}
              </S.IdentifyText>
            </S.Group>
          </S.TextWrapper>
          <S.Group>
            <Badge
              prefix={currencySymbol()}
              value={moneyIntl(challenge.buyin.float)}
            />

            {isVip && (
              <BadgeInfo
                bgColor={'#fbc531'}
                showIcon
                text={t('card.badgeInfo.vip', { var: '*' })}
                marginLeft={5}
              />
            )}

            <S.IconType
              src={icons[challenge.type.toLowerCase()]}
              width={18}
              height={18}
            />
          </S.Group>
          {challenge.countPlayers.total < challenge.expectedParticipants && (
            <S.MinMessage>
              {t('banner.min')}
              <Trans
                i18nKey="banner.player"
                count={challenge.expectedParticipants}
              >
                <b>{{ var: separator(challenge.expectedParticipants) }}</b>
              </Trans>
            </S.MinMessage>
          )}
        </S.LeftGroup>

        <S.RightGroup>
          <S.PrizeGroup>
            <S.PrizeText>{t('bannerDetails.prizeText.toWin')}</S.PrizeText>

            {challenge.typePrize === 'Other' ? (
              <S.Prize>{`${separator(challenge.prizePool)} ${
                challenge.prizePool === 1
                  ? challenge.otherPrizeDescriptionSingular
                  : challenge.otherPrizeDescriptionPlural
              }`}</S.Prize>
            ) : (
              <>
                <S.Prize>{moneyIntl(challenge.prize.float)}</S.Prize>
                <S.PrizeSymbol>{currencySymbol()}</S.PrizeSymbol>
              </>
            )}
          </S.PrizeGroup>

          <S.WinAmount>
            <S.IconCup />
            <S.WinCount>{separator(challenge.prizePool)}</S.WinCount>
            <Trans
              i18nKey="bannerDetails.WinAmount.winner"
              count={challenge.prizePool}
            />
          </S.WinAmount>
        </S.RightGroup>
      </S.SubWrapper>

      <S.Border />
    </S.Wrapper>
  );
};

export default BannerDetails;
