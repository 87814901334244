import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 500px;
`;

export const WrapperChallenge = styled.div`
  padding: 0px 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 320px) {
    flex-direction: column;
  }

  width: 100%;
  padding: 15px;
`;

export const Label = styled.h1`
  font-family: Gorga Grotesque Light;
  font-size: 16px;
  padding-top: 2px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
