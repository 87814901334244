import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 500px;
`;

export const Title = styled.h1`
  padding-top: 5px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-family: Gorga Grotesque Light;
`;
