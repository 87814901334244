import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import ReactCountdown from 'react-countdown';

import {
  Wrapper,
  SubWrapper,
  RendererWrapper,
  Group,
  Text,
  Item,
  SubGroup,
  Info,
  Separator,
  Message,
  Title,
  Icon,
  Clock,
  CountDownWrapper,
} from './style';

const remainingMinutes = (total) => total / 1000 / 60;
const shouldShow = (item, key) => (key === 0 && parseInt(item)) || key > 0;

const abbrev = {
  days: 'DAYS',
  hours: 'HRS',
  minutes: 'MINS',
  seconds: 'SECS',
};

const CountDown = ({
  milliseconds,
  message,
  hideIcon,
  color,
  hideLabels,
  size,
  children,
  onComplete,
  hideWhenDone,
}) => {
  const [timestamp, setTimestamp] = useState(Date.now() + milliseconds);

  useEffect(() => {
    setTimestamp(Date.now() + milliseconds);
  }, [milliseconds]);

  const renderer = ({ formatted, total }) => (
    <>
      <RendererWrapper
        style={{
          '--color': color || (remainingMinutes(total) < 4 && '#EF5D5F'),
        }}
      >
        {!hideIcon && (
          <Icon>
            <Clock icon="clock" />
          </Icon>
        )}
        <CountDownWrapper>
          <Group>
            {Object.values(formatted).map((item, key, arr) => {
              return (
                shouldShow(item, key) && (
                  <Item key={key} size={size}>
                    <SubGroup>
                      <Info>{item}</Info>
                      <Separator>:</Separator>
                    </SubGroup>
                    {!hideLabels && (
                      <Text>{abbrev[Object.keys(formatted)[key]]}</Text>
                    )}
                  </Item>
                )
              );
            })}
          </Group>
        </CountDownWrapper>
      </RendererWrapper>

      {message && timestamp > 0 && (
        <Message backgroundColor={remainingMinutes(total) <= 4}>
          <Title>{message}</Title>
        </Message>
      )}
    </>
  );

  if (!hideWhenDone || timestamp > 0) {
    return (
      <Wrapper>
        {children}
        <SubWrapper>
          <ReactCountdown
            key={timestamp}
            zeroPadDays={1}
            date={timestamp}
            renderer={renderer}
            onComplete={() => {
              onComplete && onComplete();
            }}
          />
        </SubWrapper>
      </Wrapper>
    );
  }
  return null;
};

CountDown.propTypes = {
  milliseconds: PropTypes.number,
  message: PropTypes.string,
  hideIcon: PropTypes.bool,
  hideLabels: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.object,
  onComplete: PropTypes.func,
  hideWhenDone: PropTypes.bool,
};

CountDown.defaultProps = {
  hideIcon: false,
  color: null,
  hideLabels: false,
  hideWhenDone: false,
  size: `0.5em`,
  onComplete: () => {},
};

export default CountDown;
