import React, { useEffect, memo } from 'react';
import { useReactiveVar } from '@apollo/client';

import PropTypes from 'prop-types';

import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import en from '../../config/i18n/en.json';
import es from '../../config/i18n/es.json';
import ptBR from '../../config/i18n/pt-BR.json';
import { partnerInfoVar } from '../Apollo/vars';

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  pt: {
    translation: ptBR,
  },
};

const I18n = memo(({ children }) => {
  // caso usarmos redux rota -> state.environment.locale.language

  const partnerInfo = useReactiveVar(partnerInfoVar);

  // eslint-disable-next-line no-undef
  const locale = navigator && navigator.language;
  const language = locale.substring(0, 2);

  i18n.use(initReactI18next).init({
    resources,
    lng: language,
    keySeparator: true,
    debug: false,
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false,
    },
  });

  useEffect(() => {
    i18n.changeLanguage(
      (partnerInfo.language || 'en').substring(0, 2).toLowerCase()
    );
  }, [partnerInfo.language]);

  return <>{children}</>;
});

I18n.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

I18n.defaultProps = {
  children: null,
};

I18n.displayName = 'I18n';

export default I18n;
