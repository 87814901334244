import React from 'react';

const InternationalBetting = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28.826}
      height={23.778}
      viewBox="0 0 28.826 23.778"
      {...props}
    >
      <g opacity={0.494} fillRule="evenodd">
        <path
          data-name="Caminho 22"
          d="M18.505 18.416V9.855l-3.69-1.6.126 11.656z"
          fill="#a2a2a2"
        />
        <path
          data-name="Caminho 23"
          d="M10.155 7.221V5.155h8.75V0h6.751v5.155h3.17v3.574h-3.17v6.63a1.857 1.857 0 002 2.065h1.166v4.378h-5.56c-3.447 0-4.351-1.24-4.351-3.406V8.673h-3.963v11.24q-.593 2.565-4.39 3.4a12.555 12.555 0 01-8.176-.506Q.12 21.775.306 18.62l5.157.5q.963 2.175 3.48 1.121 1.107-.818.8-4.309a4.924 4.924 0 01-4.281 1.884 4.965 4.965 0 01-4.47-2.456 6.576 6.576 0 01-.993-3.968 8.366 8.366 0 01.994-4.171 5.031 5.031 0 014.469-2.31 5.6 5.6 0 014.692 2.31zm-2.653 7.4c1.815 0 2.434-1.323 2.434-2.954s-.58-2.95-2.434-2.95-2.433 1.322-2.433 2.95.619 2.954 2.433 2.954z"
          fill="#999"
        />
      </g>
    </svg>
  );
};

export default InternationalBetting;
