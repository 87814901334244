import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Icon = styled.h1`
  padding: 10px;
  width: 100%;
  max-width: 300px;

  color: #fafafa;

  display: flex;
`;

export const Title = styled.h1`
  font-size: 15px;

  color: #fafafa;

  font-family: Gorga Grotesque Bold;
`;
