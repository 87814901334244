import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Price, Prefix, GroupFree, Group } from './style';
import { useTranslation } from 'react-i18next';

const Badge = ({ prefix, value, marginLeft, loss }) => {
  const { t } = useTranslation();

  return (
    <Wrapper marginLeft={marginLeft} loss={loss}>
      {value.toString().trim() === '0' || value === 0 ? (
        <GroupFree>
          <Price>{t('banner.free')}</Price>
        </GroupFree>
      ) : (
        <Group>
          <Prefix>{prefix}</Prefix>
          <Price>{value}</Price>
        </Group>
      )}
    </Wrapper>
  );
};

Badge.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.number,
  loss: PropTypes.bool,
};

Badge.defaultProps = {
  prefix: '',
  value: 0,
  marginLeft: 0,
};

export default Badge;
