import { useEffect } from 'react';

import { globalErrorMessageVar } from '../../../Apollo/vars';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSurviveAuth } from '../../../SurviveAuth';

import { toast } from 'react-toastify';

export const Errors = () => {
  const { login } = useSurviveAuth();

  const errorMessage = useReactiveVar(globalErrorMessageVar);
  const history = useHistory();

  const errorsToLogout = ['E_UNAUTHORIZED', 'EMPTY_TOKEN', 'INVALID_TOKEN'];
  const tooltipErrors = ['WALLET_INSUFFICIENT_FUNDS'];

  useEffect(() => {
    if (isEmpty(errorMessage)) return;

    if (errorsToLogout.includes(errorMessage.codeError)) {
      login();
    } else {
      if (errorMessage.codeError.includes(tooltipErrors)) {
        toast.error(errorMessage.message);
      } else {
        history.push({
          pathname: '/brokenpage',
          state: { error: errorMessage.message },
        });
      }
    }
  }, [errorMessage]);

  return null;
};
