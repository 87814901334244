/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';

import { useChallenge } from '../../../../pages/ChallengeDetails/Context';

import * as S from './style';

const lineHeight = 15;

const PlayerListGroup = ({ title, group, showLimit }) => {
  const [countVisibles, setCountVisibility] = useState(() =>
    showLimit >= group.length ? group.length : showLimit
  );
  const groupData = group || [];

  const seeMore = useCallback(() => {
    if (countVisibles < groupData.length)
      setCountVisibility(() => {
        if (countVisibles + showLimit > groupData.length)
          return groupData.length;
        return countVisibles + showLimit;
      });
  }, [showLimit, countVisibles]);

  if (!groupData.length) {
    return null;
  }

  return (
    <S.WrapColumn>
      <S.Title>{title}</S.Title>

      <S.List
        className="players-list"
        animate={{ height: countVisibles * lineHeight }}
        transition={{ duration: 0.5 }}
      >
        {groupData.map(({ name }) => (
          <S.ListItem key={name}>{name}</S.ListItem>
        ))}
      </S.List>

      <S.ButtonShow hide={countVisibles == groupData.length} onClick={seeMore}>
        <Trans i18nKey="general.see_more" />
      </S.ButtonShow>
    </S.WrapColumn>
  );
};

const PlayersList = ({ titleCollapse }) => {
  const { challenge } = useChallenge();
  const [open, setOpen] = useState(true);

  return (
    <S.WrapperCollapse>
      <S.TitleGroup onClick={() => setOpen(!open)}>
        <S.ButtonCollapse isOpen={open} />
        <S.Title>
          <Trans i18nKey="general.surviveAndAwarded" />
        </S.Title>
      </S.TitleGroup>

      <S.Group
        animate={{ height: open ? '100%' : '0' }}
        transition={{ duration: 0.5 }}
      >
        <PlayerListGroup
          title={
            <Trans
              i18nKey="general.survive"
              count={challenge.winners?.survivors.length}
            />
          }
          group={challenge.winners?.survivors || []}
          showLimit={10}
        />
        <PlayerListGroup
          title={
            <Trans
              i18nKey="general.awarded"
              count={challenge.winners?.awarded.length}
            />
          }
          group={challenge.winners?.awarded || []}
          showLimit={10}
        />
      </S.Group>
    </S.WrapperCollapse>
  );
};

export default PlayersList;
