import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const ListBar = ({ children }) => {
  return <S.WrapperList>{children}</S.WrapperList>;
};

ListBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default ListBar;
