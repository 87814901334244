import styled from 'styled-components';

import { X } from 'react-feather';

export const Wrapper = styled.div``;

export const Floating = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;

  z-index: ${({ theme }) => theme.zIndexScale.zIndexModalFloating};

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  visibility: ${({ isShowing }) => (isShowing ? 'visible' : 'hidden')};
  opacity: ${({ isShowing }) => (isShowing ? 1 : 0)};
`;

export const Overlay = styled.div`
  position: fixed;

  z-index: ${({ theme }) => theme.zIndexScale.zIndexModalOverlay};

  background: black;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: opacity 1s ease-out;
  visibility: ${({ isShowing }) => (isShowing ? 'visible' : 'hidden')};
  opacity: ${({ isShowing }) => (isShowing ? 0.5 : 0)};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  background: #1b212a;
  padding: 10px;
`;

export const Title = styled.h1`
  font-family: Gorga Grotesque Bold;
  color: #ffff;
  width: 100%;
  max-width: 600px;
  min-width: 200px;
  font-size: 14px;
`;

export const IconX = styled(X)`
  color: #ffff;

  position: fixed;
  right: 10px;
  cursor: pointer;
`;

export const Content = styled.div`
  background: #222a36;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  border-radius: 3px;
`;

export const Description = styled.div`
  font-family: Gorga Grotesque Light;
  color: #ffff;

  font-size: 13px;
`;
