import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.nav`
  display: flex;
  display: ${({ hidden }) => hidden && 'none'};
  align-items: center;
  align-content: center;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndexScale.ZIndexNavbarWrapper};
  top: 0;
  width: 100%;
  height: 50px;
  background: #14181f;
  padding: 0;
  justify-content: center;
`;

export const OnClick = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;

  opacity: ${({ hideDesktop }) => (hideDesktop ? 0 : 1)};
  cursor: pointer;
  user-select: none;

  @media (max-width: 600px) {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0px;
  }
`;

export const Group = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 10px;
  height: 50px;

  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const WrapperLogo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  text-decoration: none;
`;
