import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  position: relative;
`;

export const WrapperTab = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  width: 140px;
  height: 40px;
`;

export const TabLink = styled.h1`
  font-family: Gorga Grotesque Regular;
  display: flex;
  align-items: center;
  align-content: center;
  text-decoration: none;
  cursor: pointer;

  font-size: 14px;
  width: 140px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fafafa;

  transition: all 1s ease;
`;

export const ActiveBar = styled.div`
  position: absolute;

  width: 140px;
  bottom: -3px;
  height: 2px;

  transition: left 0.3s ease;

  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
`;

export const Body = styled.div`
  padding: 10px;
`;

export const LineGradientHeader = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
`;
