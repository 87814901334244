import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: Gorga Grotesque Bold;
  font-size: 14px;
`;

export const ButtonNotification = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 101px;
  height: 24px;
  cursor: pointer;

  text-align: center;
  font-family: Gorga Grotesque Regular;
  font-size: 10px;
  color: #00000090;
  background: #9ff191;
  border-radius: 30px;
  margin-top: 10px;
  border-width: 0;
`;
