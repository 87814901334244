import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 2px;
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft}px;

  color: #272f3c;
`;

export const Group = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text};

  height: 15px;
  padding: 3px 5px 1px 5px;
  border-radius: 2px;
`;

export const GroupFree = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;

  height: 15px;
  padding: 3px 5px 1px 5px;
  border-radius: 2px;

  background: #7feaac;
`;

export const Price = styled.h1`
  font-family: Gorga Grotesque Regular;

  margin: 0;
  padding: 0;

  font-size: 11px;
`;

export const Prefix = styled.span`
  font-family: Gorga Grotesque Light;

  margin-top: 1px;
  margin-right: 2px;
  font-size: 8px;
`;
