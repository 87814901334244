import styled, { css } from 'styled-components';
import Cup from '../../Svg/Cup';

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;

  width: 100%;

  box-shadow: 0px 4px 5px 1px #191e27;
  border: ${({ highlightedColor }) =>
    highlightedColor ? `1px solid ${highlightedColor}` : `1px solid #2c3543`};

  margin-bottom: 10px;
  height: 95px;
  border-radius: 3px;

  ${({ playingHighlight, surviveHighlight, eliminatedHighlight }) => css`
    ${IconUserGroup} {
      svg {
        color: ${playingHighlight};
        fill: ${playingHighlight};
      }
    }

    ${GroupIcons} {
      > div:first-of-type svg {
        fill: ${eliminatedHighlight};
      }

      > div:last-of-type g {
        fill: ${surviveHighlight};
      }
    }
  `}
`;

export const LeftGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  background: linear-gradient(to right, #272f3c 0%, #242b38 100%);

  padding: 8px 4px 4px 8px;
  padding-left: ${({ hasStatus }) => hasStatus && '30px'};

  flex: 1;
  border-radius: 3px 0 0 3px;
`;

export const StatusLabel = styled.div`
  font-family: Gorga Grotesque Regular;
  text-transform: uppercase;
  font-size: 0.6em;
  padding: 3px 0 0 0;
  border-radius: 2px;

  background: ${({ background }) => background || 'transparent'};
  color: ${({ color }) => color || 'white'};

  > div {
    display: flex;
    justify-content: end;
    font-size: 1.5em;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.3);
    font-family: Gorga Grotesque Light;
  }
`;

export const StatusChallenge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-family: Gorga Grotesque Regular;

  font-size: 8px;
  top: 36px;
  height: 21px;
  left: -36px;
  width: 93px;
  padding: 3px 0 2px;
  border-radius: 1px 1px 0 0;

  transform: rotate(-90deg);

  background: ${({ background }) => background || 'transparent'};
  color: ${({ color }) => color || 'white'};
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;

  width: 100%;
`;

export const Title = styled.div`
  font-family: Gorga Grotesque Light;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;

  color: #878ea0;
  font-size: 12px;

  -webkit-line-clamp: 2;

  @media (min-width: 600px) {
    font-size: 14px;
  }
`;

export const Id = styled.h1`
  font-family: Gorga Grotesque Regular;

  margin-left: 2px;
  height: 15px;
  font-size: 10px;

  color: #878ea0;
`;

export const InfoPrize = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  flex: 1;
`;

export const WrapperItemsBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 2px;
  margin-bottom: 2px;
`;

export const WrapperBadge = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  max-height: 35px;
`;

export const IconType = styled.img`
  margin-left: 2px;
`;

export const IconUserGroup = styled.div`
  font-weight: bold;
  display: flex;

  max-height: 20px;

  @media (min-width: 600px) {
    margin-right: 20px;
  }
`;

export const RightGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;

  background: linear-gradient(#272f3c 30%, #2a3240 50%, #2c3543 100%);
  border-left: 1px solid #181e27;

  min-width: 135px;
  padding: 8px 8px 4px 4px;

  @media (min-width: 600px) {
    min-width: 160px;
  }
  border-radius: 0 2px 2px 0;
`;

export const SubGroup = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  font-size: 12px;

  width: 100%;

  flex: 1;
`;

export const WinAmount = styled.span`
  font-family: Gorga Grotesque Light;
  display: flex;

  margin-right: 2px;
  font-size: 10px;
  margin-bottom: 2px;

  color: #878ea0;
`;

export const IconCup = styled(Cup)`
  margin-right: 3px;
  width: 12px;
  height: 12px;
`;

export const WinCount = styled.b`
  font-family: Gorga Grotesque Regular;

  margin-right: 2px;

  color: #fafafa;
`;

export const GroupIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  font-weight: bold;

  max-height: 20px;

  width: 100%;
`;

export const Prize = styled.h1`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  font-family: Gorga Grotesque Regular;
  word-break: normal;
  flex-direction: column-reverse;
  align-items: end;

  padding-top: 2px;
  font-size: 16px;

  color: #ffffff;
`;

export const Currency = styled.span`
  font-family: Gorga Grotesque Light;

  margin-left: 2px;
  font-size: 8px;

  color: #878ea0;
`;
