import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;

  cursor: pointer;
`;

export const Text = styled.h1`
  font-family: Gorga Grotesque Light;
  color: #ffff;
  display: inline-block;
  vertical-align: middle;

  font-size: 12px;
`;

export const Aling = styled.span`
  vertical-align: middle;
  cursor: pointer;
`;
