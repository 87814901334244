import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import CountDown from '../CountDown/CountDown';

import * as S from './style';
import { useEnums } from '../../../hooks/useEnum';

const CountDownMyResult = (props) => {
  const { timestamps, myResult, myRole, hideLabel, status, ...countDownProps } =
    props;

  const { t } = useTranslation();
  const { myResultLabel, challengeLabel, myResultStyle, challengeStyle } =
    useEnums();

  const [countdownEnds, setCountDownEnds] = useState(false);

  useEffect(() => {
    setCountDownEnds(false);
  }, [myResult, status, JSON.stringify(timestamps)]);

  const myResultColor = myResultStyle(myResult).background;
  const myResultText = myResultLabel(myResult, 'countdown');
  const myResultTextDefault = myResultLabel(myResult);

  const challengeColor = challengeStyle(status).background;
  const challengeText = challengeLabel(status, 'countdown');

  const {
    lastRoundEndDate,
    lastEventEndDate,
    nextRoundStartDate,
    saleStartDate,
  } = timestamps;

  const isGuest = myRole === 'Guest';
  const isInSale = status === 'Sale';

  const isWaitingNewRound = myResult === 'WaitingNewRound';
  const isWaitingResult = myResult === 'WaitingResult';
  const isPendingBet = myResult === 'PendingBet';

  const showMyResultText = (color = myResultColor, text = myResultText) => {
    return !hideLabel && <S.Text color={color}>{text}</S.Text>;
  };

  const showLabel = (color = myResultColor, text = myResultTextDefault) => {
    return (
      !countDownProps.hideWhenDone && (
        <S.StatusLabel color={color}>{text}</S.StatusLabel>
      )
    );
  };

  if (isGuest && isInSale) {
    return (
      <CountDown
        milliseconds={saleStartDate?.timestamp || 0}
        {...countDownProps}
      >
        {showMyResultText(challengeColor, challengeText)}
      </CountDown>
    );
  } else if (isWaitingNewRound) {
    if (
      !nextRoundStartDate?.timestamp ||
      nextRoundStartDate.timestamp <= 0 ||
      countdownEnds
    ) {
      return showLabel();
    }

    return (
      <CountDown
        milliseconds={nextRoundStartDate?.timestamp || 0}
        {...countDownProps}
        color={countDownProps.color || '#C7F86C'}
        onComplete={() => {
          setCountDownEnds(true);
        }}
      >
        {showMyResultText()}
      </CountDown>
    );
  } else if (isWaitingResult) {
    if (
      !lastEventEndDate?.timestamp ||
      lastEventEndDate.timestamp <= 0 ||
      countdownEnds
    ) {
      return showLabel();
    }

    return (
      <CountDown
        milliseconds={lastEventEndDate?.timestamp || 0}
        {...countDownProps}
        message={countDownProps.message && t('countdown.message.title.game')}
        onComplete={() => {
          setCountDownEnds(true);
        }}
      >
        {showMyResultText()}
      </CountDown>
    );
  } else if (isPendingBet) {
    return (
      <CountDown
        milliseconds={lastRoundEndDate?.timestamp || 0}
        {...countDownProps}
        message={countDownProps.message && t('countdown.message.title.bet')}
      >
        {showMyResultText()}
      </CountDown>
    );
  }

  return null;
};

CountDownMyResult.propTypes = {
  timestamps: PropTypes.object,
  myRole: PropTypes.string,
  myResult: PropTypes.string,
  hideIcon: PropTypes.bool,
  color: PropTypes.string,
  message: PropTypes.bool,
  hideLabel: PropTypes.bool,
  hideWhenDone: PropTypes.bool,
  status: PropTypes.string,
};

CountDownMyResult.defaultProps = {
  hideLabel: false,
  hideWhenDone: false,
};

export default CountDownMyResult;
