import React from 'react';
import PropTypes from 'prop-types';

import { useSpring } from 'react-spring';

import { LineVertical } from './style';

const configUseSpring = {
  delay: 0,
  // config: config.molasses,
  duration: 100,
};

const VerticalLine = ({ size }) => {
  const animatedBox = useSpring({
    to: { height: `${size ? size : 50}px`, opacity: 1 },
    from: { height: '0px', opacity: 0 },

    ...configUseSpring,
  });

  return (
    <>
      <LineVertical style={animatedBox} />
    </>
  );
};

VerticalLine.propTypes = {
  size: PropTypes.number,
};

VerticalLine.defaultProps = {
  size: 50,
};

export default VerticalLine;
