import React, { useState } from 'react';
import AcordionItem from './AcordionItem';
import { Wrapper, LineGradientHeader } from './style';
import PropTypes from 'prop-types';

const Acordion = ({ data }) => {
  const [active, setActive] = useState(null);

  return (
    <Wrapper>
      <LineGradientHeader />
      {data.map((item, index) => (
        <AcordionItem
          key={index}
          index={index}
          item={item}
          active={active}
          setActive={setActive}
        />
      ))}
    </Wrapper>
  );
};

Acordion.propTypes = {
  item: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  data: PropTypes.array,
};

export default Acordion;
