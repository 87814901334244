import React from 'react';

import { useChallenge } from '../../../../pages/ChallengeDetails/Context';

import * as S from './style';
import Extra from './Extra';
import Congratulation from './Congratulation';
import Description from './Description';
import PlayersList from './PlayersList';
import Header from './Header';

const FinalMessage = () => {
  const {
    challenge: { status, rounds, myResult, typeWinner, myRole, typePrize },
  } = useChallenge();

  const notSuitable = ['Finished', 'FinishedForced'];

  if (!notSuitable.includes(status)) {
    return null;
  }

  const eliminatedAtLastRound = rounds[0].myResult === 'Eliminated';
  const eliminatedBefore = myResult === 'Eliminated' && !eliminatedAtLastRound;

  return (
    <>
      <S.Wrapper>
        <Header
          typeWinner={typeWinner}
          typePrize={typePrize}
          myResult={myResult}
        />

        <PlayersList />

        <Description
          typeWinner={typeWinner}
          typePrize={typePrize}
          myResult={myResult}
        />

        <Congratulation
          typeWinner={typeWinner}
          typePrize={typePrize}
          isGuest={myRole === 'Guest'}
          myResult={myResult}
          eliminatedBefore={eliminatedBefore}
        />

        <Extra
          typeWinner={typeWinner}
          typePrize={typePrize}
          myResult={myResult}
        />
      </S.Wrapper>

      <S.FlagWrapper>
        <S.EndFlag />
      </S.FlagWrapper>
    </>
  );
};

export default FinalMessage;
