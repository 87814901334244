import React from 'react';

import { Wrapper, Title, Group, Content, Description } from './style';

import { useTranslation } from 'react-i18next';

import Header from '../../components/Shared/Header/Header';

const About = () => {
  const { t } = useTranslation();

  const about = t('about', { returnObjects: true });

  return (
    <Wrapper>
      <Header fixed>
        <Title>{about.title}</Title>
      </Header>
      <Group>
        <Content>
          <Description
            dangerouslySetInnerHTML={{ __html: about.description }}
          ></Description>
        </Content>
      </Group>
    </Wrapper>
  );
};

export default About;
