import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import { ChevronLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from '../../../hooks/numberFormat';

import {
  Wrapper,
  Details,
  Wallet,
  Values,
  Amount,
  Description,
  Date,
  Operation,
  Balance,
  Header,
  Label,
  Button,
  Dropdown,
  Icon,
} from './style';

const timeAnimation = 400;

const WalletCard = ({ data }) => {
  const [show, setShow] = useState(true);

  const { currency } = useNumberFormat();

  const { t } = useTranslation();

  const showTransition = useSpring({
    reset: true,
    from: { maxHeight: '0px', display: 'none', opacity: 1 },
    to: {
      maxHeight: show ? '500px' : '0px',
      display: show ? 'flex' : 'none',
      opacity: show ? 1 : 0,
    },
    config: { duration: timeAnimation },
  });

  return (
    <Wrapper>
      <Header>
        <Dropdown>
          <Label>{t('walletcard.dropdown.label')}</Label>

          <Button onClick={() => setShow(!show)}>
            <Icon show={show}>
              <ChevronLeft />
            </Icon>
          </Button>
        </Dropdown>
      </Header>

      {data.map((item, index) => {
        return (
          <Wallet key={index} show={show} style={showTransition}>
            <Details>
              <Description>{item.description}</Description>

              <Operation>{item.operation}</Operation>

              <Date>{item.createdAt.format}</Date>
            </Details>

            <Values>
              <Amount status={item.type}>{` ${
                item.type === 'Out' ? '-' : '+'
              } ${currency(item.amount.int, { short: false })}`}</Amount>
              <Balance>${currency(item.balance.int, { short: false })}</Balance>
            </Values>
          </Wallet>
        );
      })}
    </Wrapper>
  );
};

WalletCard.propTypes = {
  data: PropTypes.array,
};

export default WalletCard;
