import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const Icon = styled.div`
  position: relative;
  margin-right: 3px;
  margin-left: 3px;
`;

export const Inside = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
  transform: translateY(-1px);
  color: #ffff;

  font-family: Gorga Grotesque Regular;
  vertical-align: middle;
`;

export const Bar = styled.span`
  margin-left: 5px;
  font-size: 12px;

  color: #ffff;

  vertical-align: middle;
`;
