/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from '../../hooks/numberFormat';
import { useChallenge } from './Context';
import * as S from './style';

const ProgressRow = ({ item, isLast }) => {
  const { t } = useTranslation();
  const { currencySymbol, moneyIntl, separator } = useNumberFormat();

  let { batch, buyin, endDate, qtdTickets } = item;
  buyin = `${currencySymbol()} ${moneyIntl(buyin)}`;

  let key = 'general.batchUntilDate';

  if (qtdTickets) {
    key = 'general.batchUntilTicket';
  }

  if (isLast) {
    key = 'general.batch';
  }

  return (
    <S.ProgressionLine>
      {t(key, {
        replace: {
          batch,
          tickets: separator(qtdTickets || 0),
          date: endDate?.date,
          time: endDate?.hour,
          buyin,
        },
      })}
    </S.ProgressionLine>
  );
};

const Progression = () => {
  const { challenge } = useChallenge();

  return (
    <S.WrapperProgression>
      {challenge.progressionValues.map((item, idx, arr) => (
        <ProgressRow item={item} key={idx} isLast={arr.length - 1 === idx} />
      ))}
    </S.WrapperProgression>
  );
};

export default Progression;
