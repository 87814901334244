/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
import Compact from './components/App/Compact';

import * as serviceWorker from './serviceWorkerRegistration';

const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

ReactDOM.render(
  <React.StrictMode>{compactMode ? <Compact /> : <App />}</React.StrictMode>,
  document.getElementById('root')
);

if (compactMode) {
  const postMessage = () => {
    const main = document.querySelector(`main`);

    if (!main) {
      return;
    }

    const { height, top } = main.getBoundingClientRect();

    window.top.postMessage(
      {
        target: 'survive',
        content: {
          name: 'clientHeight',
          value: height + top,
        },
      },
      '*'
    );
  };

  const observer = new MutationObserver(function (mutations) {
    mutations.forEach((mutation) => {
      if (['g', 'path'].includes(mutation.target.tagName)) return false;

      if (mutation.target.classList.contains('players-list')) {
        setTimeout(postMessage, 200);
      } else if (mutation.target.id.includes('accordion-')) {
        setTimeout(postMessage, 600);
      } else {
        postMessage();
      }
    });
  });

  const nodeInsertedHandler = () => {
    if (document.querySelector(`main`)) {
      observer.observe(document.querySelector(`main`), {
        attributes: true,
        childList: true,
        subtree: true,
      });
      document.removeEventListener(`DOMNodeInserted`, nodeInsertedHandler);
    }
  };
  document.addEventListener(`DOMNodeInserted`, nodeInsertedHandler);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
