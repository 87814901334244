import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import Lottie from 'lottie-react';
import networkOffline from '../../../assets/networkOffline.json';
import { Title, Icon, SubTitle, Group } from './style';
import { useTranslation } from 'react-i18next';

const NetworkStatus = () => {
  const [onlineStatus, isOnlineStatus] = useState(navigator.onLine);
  const { t } = useTranslation();

  useEffect(() => {
    const unSubscribe = window.addEventListener('offline', function () {
      isOnlineStatus(false);
    });

    const subscribe = window.addEventListener('online', function () {
      isOnlineStatus(true);
    });

    return () => {
      unSubscribe && unSubscribe();
      subscribe && subscribe();
    };
  }, []);

  return (
    <Modal showingStyle isShowing={!onlineStatus} title="Modal">
      <>
        <Icon>
          <Lottie
            animationData={networkOffline}
            style={{
              width: '100%',
              backgroundColor: 'transparent',
            }}
          />
        </Icon>

        <Group>
          <Title>{t('networkStatus.title')}</Title>

          <SubTitle>{t('networkStatus.subtitle')}</SubTitle>
        </Group>
      </>
    </Modal>
  );
};

export default NetworkStatus;
