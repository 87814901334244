import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Icon, Vacancies } from './style';

const UsersCount = ({ total, marginBottom, fontSize }) => {
  return (
    <Wrapper marginBottom={marginBottom}>
      <Icon icon="users" size={12} />
      <Vacancies fontSize={fontSize}>{total}</Vacancies>
    </Wrapper>
  );
};

UsersCount.propTypes = {
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.number,
  fontSize: PropTypes.number,
};

export default UsersCount;
