/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import Modal from '../../Shared/Modal/Modal';
import ToPoint from '../../Svg/ToPoint';

import { app } from '../../../config/app';
import firebase, { messaging } from '../../../config/firebase';

import { Wrapper, ButtonNotification } from './style';
import { isSuspense } from 'react-is';

const FirebaseNotification = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [token, setToken] = useState(null);

  const requestToken = () => {
    if (!messaging) return;

    return messaging
      .getToken({
        vapidKey: app.firebase.vapidKey,
      })
      .then((currentToken) => {
        if (currentToken) {
          // console.log('TOKEN FIREBASE 🔥: ', { currentToken });
          setToken(currentToken);

          setIsShowing(false);
        } else {
          // console.log(
          //   'No registration token available. Request permission to generate one.'
          // );
          setIsShowing(true);
          setToken(null);
        }
      })
      .catch((err) => {
        setIsShowing(true);
        setToken(null);
      });
  };

  useEffect(() => {
    requestToken();
  }, []);
  // useEffect(() => {
  //   if (!token && firebase.messaging.isSupported()) return setIsShowing(true);
  // }, [firebase, isShowing]);

  // console.log(isShowing);
  return <></>;

  // return (
  //   <Modal isShowing={isShowing} title="Activate notifications">
  //     <Wrapper>
  //       <ToPoint />

  //       <ButtonNotification onClick={() => requestToken()}>
  //         Activate
  //       </ButtonNotification>
  //     </Wrapper>
  //   </Modal>
  // );
};

export default FirebaseNotification;
