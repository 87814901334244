/* eslint-disable no-undef */
import packageJson from '../../package.json';

const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
const locale = navigator && navigator.language;
const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';
const devMode = ['dev.survive', 'web.app', 'localhost', '192', 'ngrok'].some(
  (el) => window.location.host.includes(el)
);

const stgMode = [
  'stg.survive.bet',
  'stg-mambo.iframe.survive.bet',
  'stg-betboss.iframe.survive.bet',
].some((el) => window.location.host.includes(el));

const keycloack = {
  clientId: 'app-front',
  realm: devMode ? 'survive-dev' : 'survive',
  url: 'https://account.survive.bet/auth/',
  scheme: 'survive',
};

if (stgMode) {
  keycloack.realm = 'survive-stg';
}

const firebase = {
  apiKey: 'AIzaSyBLiWQE2tC58sFvEnrRxYuNpdVWzzdChKU',
  authDomain: 'survivetest-e7328.firebaseapp.com',
  projectId: 'survivetest-e7328',
  storageBucket: 'survivetest-e7328.appspot.com',
  messagingSenderId: '176199188974',
  appId: '1:176199188974:web:f0015c8f431537a5028eff',
  measurementId: 'G-F334TMHBZ5',
  vapidKey:
    'BDTyH9csnwR5shJ1KaxbkzFn7AUN3s_rt1iBj77WrpVH1tMYuyCGDKqmykKs1DUtAVeSYEp_fuimCGxPjuYrsDc',
};

let xHost = window.location.host;

if (stgMode && !compactMode) {
  xHost = 'stg.survive.bet';
} else if (devMode) {
  xHost = 'survive.bet';
}

const apollo = {
  httpURI:
    process.env.REACT_APP_GRAPHQL_URI || 'https://api.survive.bet/graphql',
  wsURI: process.env.REACT_APP_GRAPHQL_WS || 'wss://api.survive.bet/graphql',
  credentials: 'same-origin',
  headers: {
    'App-Client-Name': 'survive-client',
    'App-Client-Version': 0.1,
    'Time-Zone': `${timezoneOffset}`,
    'Accept-Language': locale,
    'X-Host': xHost,
  },
};

const authPartnerProvider = {
  enable: process.env.REACT_APP_PARTNER_ENABLE === 'true',
  endpoint:
    process.env.REACT_APP_PARTNER_URI ||
    'https://api.survive.bet/partner-provider/token',
};

export const app = {
  keycloack: { ...keycloack },
  apollo: { ...apollo },
  firebase: { ...firebase },
  authPartner: authPartnerProvider,
  version: packageJson.version,
  telegramBot: window.location.host.includes('mambo')
    ? 2059340288
    : devMode
    ? 2006505850
    : 2055209475,
};

export const isBlankPageRender = (page) =>
  ['/404', '/expiredsession'].some((e) => page.indexOf(e) > -1);
