import styled from 'styled-components';

export const Text = styled.div`
  font-family: Gorga Grotesque Light;

  font-size: 10px;
  margin-bottom: 4px;
  border-bottom: 1px solid;

  color: #878ea0;

  border-color: ${({ color }) => color || '#14181f'};
`;

export const StatusLabel = styled.div`
  font-family: Gorga Grotesque Regular;
  text-transform: uppercase;
  font-size: 0.6em;
  padding: 3px 0 0 0;
  border-radius: 2px;

  background: ${({ background }) => background || 'transparent'};
  color: ${({ color }) => color || 'white'};
`;
