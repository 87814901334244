import styled, { css } from 'styled-components';
// import ReactMarkdown from 'react-markdown';
import { AlertTriangle } from 'react-feather';

export const Wrapper = styled.div`
  font-family: Gorga Grotesque Light;
  user-select: none;

  margin-bottom: 10px;
  min-height: 500px;
`;

export const WrapperCounters = styled.div`
  display: flex;
  align-items: flex-start;

  background: rgba(0, 0, 0, 0.15);
`;

export const WrapperList = styled.div`
  display: flex;
  align-items: center;

  padding: 10px;
  font-size: 12px;
  line-height: 12px;

  border-bottom: 1px solid #242b39;

  color: #878ea0;
`;

export const WrapperTag = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const WrapperProgression = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
`;

export const ProgressionLine = styled.div``;

export const GroupHeaderStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 5px 0;

  @media (max-width: 600px) {
    transform: translateX(-25px);
  }
`;

export const GroupStatus = styled.div`
  display: flex;

  width: 100%;

  @media (max-width: 600px) {
    transform: translateX(-25px);
  }
`;

export const GroupTag = styled.div`
  margin: 3px 0px;
`;

export const RightGroupCounters = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: start;

  flex-wrap: wrap;

  width: 50%;

  padding: 10px 5px;
  max-width: 300px;
  min-height: 45px;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;

export const LeftGroupCounters = styled.div`
  display: flex;
  flex-direction: column;

  width: 50%;

  padding: 10px;

  flex: 1;
`;

export const StatusHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextStatus = styled.span`
  text-transform: uppercase;

  border-bottom: 1px solid #7feaac;

  font-size: 10px;
  margin-bottom: 2px;
  margin-top: 2px;

  color: #878ea0;
`;

export const Status = styled.label`
  font-family: Gorga Grotesque Bold;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;

  font-size: 0.6em;

  line-height: 0%;
  padding: 8px 9px 5px;
  border-radius: 3px;

  ${({ centered }) => {
    if (!centered) {
      return css`
        position: absolute;
        right: 10px;
      `;
    }
  }}

  background: ${({ background }) => background || 'transparent'};
  color: ${({ color }) => color || 'white'};
`;

export const InterruptedDetails = styled.div`
  text-align: left;

  font-size: 10px;
  margin-top: 5px;

  color: #a6b2ca;

  @media (max-width: 600px) {
    transform: translateX(-25px);
  }
`;

export const TextCounters = styled.div`
  font-family: Gorga Grotesque Light;
  word-break: normal;

  font-size: 10px;
  line-height: 12px;

  color: #878ea0;

  :before {
    content: '* ';
    transform: translateY(3px);
    display: inline-block;
    width: 5px;
  }
`;

export const TextDescription = styled.span`
  font-size: 10px;

  color: rgb(214 215 217);
`;

export const TextTag = styled.span`
  font-size: 10px;

  color: #878ea0;
`;

export const ChallengeMessageInfo = styled.div`
  display: flex;
  font-family: Gorga Grotesque Regular;
  /* justify-content: center; */
  text-align: center;

  padding: 13px 20px 10px 20px;
  margin: 12px 12px 8px 12px;
  border-radius: 4px;
  font-size: 12px;

  border: 2px solid #b5a55b;

  background: #ffed98;
  color: #756724;

  box-shadow: 0px 3px 10px rgb(22 26 35 / 60%);
`;

export const TextInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gorga Grotesque Regular;
  flex: 1;
  font-size: 12px;
  padding: 0px 30px;
`;

export const AlertIcon = styled(AlertTriangle)`
  min-width: 22px;
  min-height: 22px;
`;

export const TextBold = styled.span`
  font-family: Gorga Grotesque Regular;
  display: inline;
`;
