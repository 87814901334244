/* eslint-disable react/prop-types */
import React from 'react';
import { Trans } from 'react-i18next';
import { useChallenge } from '../../../../../pages/ChallengeDetails/Context';
import { useNumberFormat } from '../../../../../hooks/numberFormat';

const SurviveWithWrong = ({ typePrize, myResult, components }) => {
  const { currencySymbol, moneyIntl } = useNumberFormat();

  const {
    challenge: {
      resultPrize,
      prizePool,
      otherPrizeDescriptionSingular,
      expectedParticipants,
    },
  } = useChallenge();

  const { winners, wrongs, totalPrize, unitPrizeWrong, unitPrizeSurvive } =
    resultPrize;

  const values = {
    currency: currencySymbol(),
    totalPrize: moneyIntl(totalPrize.float),
    resultPrizeWinners: winners,
    resultPrizeWrongs: wrongs,
    unitPrizeWrong: moneyIntl(unitPrizeWrong.float),
    unitPrizeSurvive: moneyIntl(unitPrizeSurvive.float),
    countPlayer: winners + wrongs,
    resultPrize: resultPrize.wrongs,
    otherPrizeDescriptionSingular,
    prizePool,
    expectedParticipants,
  };

  if (typePrize === 'Other') {
    values.countPlayer = wrongs;
  }

  const options = {
    components,
    count: winners === 1 && wrongs > 1 ? 1 : 2,
    values,
  };

  if (myResult === 'Survive') {
    options.count = winners;
  }

  const i18nKey =
    typePrize === 'Other' ? 'endGame.withWrong.other' : 'endGame.withWrong';

  return <Trans {...{ i18nKey, ...options }} />;
};

export default SurviveWithWrong;
