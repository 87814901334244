const fonts = {
  light: 'montserrat-light',
  regular: 'montserrat-regular',
  medium: 'montserrat-medium',
  bold: 'montserrat-bold',
};

const zIndexScale = {
  zIndexModalFloating: '1500',
  zIndexModalOverlay: '1400',
  zIndexNavbarAvatarUserLayer: '1300',
  ZIndexNavbarWrapper: '1200',
  zIndexDrawerMobileWrapper: '1100',
  zIndexDrawerOverlay: '1000',
};

const theme = {
  mambo: {
    dark: {
      primary: '#f7941d',
      secondary: '#EE5A24',
      name: 'dark',
      text: '#fff',
      default: '#fff',
      neutral: '#353b48',
      backgroundLinear: ['#353b48', '#353b48'],
      zIndexScale: { ...zIndexScale },
      font: fonts,
    },
    light: {
      primary: '#f7941d',
      secondary: '#EE5A24',
      name: 'light',
      text: '#002342',
      default: '#002342',
      neutral: '#f1f2f6',
      backgroundLinear: ['#f1f2f6', '#f1f2f6'],
      font: fonts,
      zIndexScale: { ...zIndexScale },
    },
  },
  apostala: {
    dark: {
      primary: '#d22d33',
      secondary: '#b33939',
      name: 'dark',
      text: '#fff',
      default: '#fff',
      neutral: '#353b48',
      backgroundLinear: ['#353b48', '#353b48'],
      font: fonts,
      zIndexScale: { ...zIndexScale },
    },
    light: {
      primary: '#d22d33',
      secondary: '#b33939',
      name: 'light',
      text: '#fff',
      default: '#002342',
      neutral: '#f1f2f6',
      backgroundLinear: ['#f1f2f6', '#f1f2f6'],
      font: fonts,
      zIndexScale: { ...zIndexScale },
    },
  },

  default: {
    dark: {
      primary: '#c1f773',
      secondary: '#6de7bc',
      name: 'dark',
      text: '#fff',
      default: '#fff',
      neutral: '#353b48',
      backgroundLinear: ['#353b48', '#353b48'],
      font: fonts,
      zIndexScale: { ...zIndexScale },
    },
    light: {
      primary: '#c1f773',
      secondary: '#6de7bc',
      name: 'light',
      text: '#002342',
      default: '#002342',
      neutral: '#f1f2f6',
      backgroundLinear: ['#f1f2f6', '#f1f2f6'],
      font: fonts,
      zIndexScale: { ...zIndexScale },
    },
  },
};

theme['dev-apostala.iframe.survive.bet'] = theme.apostala;
theme['apostala.survive.bet'] = theme.apostala;
theme['mambo.survive.bet'] = theme.mambo;
theme['dev-mambo.iframe.survive.bet'] = theme.mambo;
theme['pesa.iframe.survive.bet'] = theme.mambo;

export default theme;
