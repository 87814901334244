import React from 'react';

import { Wrapper, IconShare, ContainerPopOver } from './style';
import { useLayer, Arrow } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';

const debounceTime = 1000;

const ShareButton = () => {
  const [isOpen, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const debounce = useDebouncedCallback((fn) => fn(), debounceTime);

  const url = window.location.href;
  const shareData = {
    title: 'Survive.bet',
    text: t('shareButton.popOver.mobile.text'),
    url: `${url}`,
  };

  const handleClickOpen = (e) => {
    e.preventDefault();

    if (navigator.share) {
      navigator.share(shareData); //.catch(console.error);
    } else {
      if (!navigator.clipboard) {
        return;
      }
      navigator.clipboard.writeText(url);
      setOpen(!isOpen);
      debounce(() => setOpen(false));
    }
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,

    onOutsideClick: close,
    onDisappear: close,
    overflowContainer: false,
    auto: true,
    placement: 'left-center',
    triggerOffset: 10,
    containerOffset: 0,
    arrowOffset: 3,
  });

  return (
    <Wrapper>
      <IconShare
        {...triggerProps}
        onClick={(e) => {
          handleClickOpen(e);
        }}
      />

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.ul {...layerProps}>
              <ContainerPopOver>{t('shareButton.popOver')}</ContainerPopOver>
              <Arrow {...arrowProps} backgroundColor="#c1f773" size={3} />
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </Wrapper>
  );
};

export default ShareButton;
