import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.h1`
  padding: 0 50px;
  width: 70%;
  max-width: 250px;

  color: #fafafa;

  display: flex;
`;

export const Title = styled.h1`
  font-size: 25px;

  color: #fafafa;

  font-family: Gorga Grotesque Bold;
`;

export const SubTitle = styled.h1`
  padding: 10px 0px;
  font-size: 10px;

  color: #fafafa;

  font-family: Gorga Grotesque Light;
`;

export const Button = styled.button`
  padding: 10px 10px;
  border-radius: 30px;
  margin-top: 5px;
  font-size: 15px;

  box-shadow: 0 4px #999;
  color: #313a4a;

  border: none;
  font-family: Gorga Grotesque Light;
  text-align: center;
  cursor: pointer;
  outline: none;
  background: linear-gradient(to right, #cbf96a 0%, #6de7bc 100%);

  &:hover {
    transform: translateY(4px);
  }

  &:active {
    transform: translateY(8px);
  }
`;
