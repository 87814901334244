/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSpring } from 'react-spring';
import { useLocation } from 'react-router-dom';
import { items } from './items';
import { useTranslation } from 'react-i18next';

import CurrentSchedule from '../CurrentSchedule/CurrentSchedule';
import { app, isBlankPageRender } from '../../../config/app';

import {
  Wrapper,
  DrawerMobileWrapper,
  Overlay,
  Header,
  Title,
  List,
  Item,
  ItemText,
  Version,
  LinkItem,
} from './style';
import { useSurviveAuth } from '../../SurviveAuth';
import { useReactiveVar } from '@apollo/client';
import { challengeTabsState } from '../../Apollo/vars';

const DrawerMobile = ({ open, handleOpenDrawer }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { isAuthenticated } = useSurviveAuth();
  const hidden = isBlankPageRender(location.pathname);

  const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

  const tabState = useReactiveVar(challengeTabsState);

  const styleOpen = useSpring({
    transform: open ? 'translate(0px, 0px)' : 'translate(-500px, 0px)',
  });

  const styleOpacity = useSpring({
    opacity: open ? 0.5 : 0,
  });

  useEffect(() => {
    if (open) return (document.body.style.overflow = 'hidden');

    if (!open) return (document.body.style.overflow = 'unset');

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Wrapper hidden={hidden}>
      {open && <Overlay style={styleOpacity} onClick={handleOpenDrawer} />}

      <DrawerMobileWrapper style={styleOpen}>
        <CurrentSchedule size={10} width={12} />
        <Header>
          <Title>{t('drawer.title')}</Title>
        </Header>

        <List>
          {items.map(({ title, path, compactModeRoute, isPrivate }) => {
            if (compactMode && !compactModeRoute) return;
            if (isPrivate && !isAuthenticated()) return;

            return (
              <LinkItem
                to={path}
                key={title}
                onClick={() => {
                  if (path === '/') {
                    delete tabState.appChallengesV2;
                    challengeTabsState({ ...tabState });
                  } else if (path === '/my-challenges') {
                    delete tabState.appMyChallengesV2;
                    challengeTabsState({ ...tabState });
                  }
                }}
              >
                <Item
                  onClick={() => {
                    handleOpenDrawer();
                  }}
                >
                  <ItemText>{t(title)}</ItemText>
                </Item>
              </LinkItem>
            );
          })}
        </List>
        <Version>
          {t('version.version')}:{app.version}
        </Version>
      </DrawerMobileWrapper>
    </Wrapper>
  );
};

DrawerMobile.propTypes = {
  open: PropTypes.bool,
  handleOpenDrawer: PropTypes.func,
};

export default DrawerMobile;
