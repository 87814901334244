import { gql } from '@apollo/client';

export const CHALLENGE_FIELDS = gql`
  fragment Challenge on AppChallenge {
    __typename
    id
    idNumber
    name
    slug
    description
    thumb
    highlighted
    allowGuest
    expectedParticipants
    status
    interruptedType
    typeWinner
    prizePool
    allowVip
    allowManualJoin
    promotions
    myResult
    myRole
    lastRoundStatus
    tags
    type
    forGuests
    typePrize
    typeBuyin
    progressionValues {
      batch
      qtdTickets
      startDate {
        timestamp
        date: format(format: $formatDate)
        hour: format(format: $formatHour)
      }
      endDate {
        timestamp
        date: format(format: $formatDate)
        hour: format(format: $formatHour)
      }
      buyin
    }
    otherPrizeDescriptionSingular
    otherPrizeDescriptionPlural
    isUserVip
    myTypeWinner
    countPlayers {
      total
      eliminated
      survive
      vips
    }
    buyin {
      format
      float
    }
    prize {
      format
      float
    }
    startDate {
      timestamp
      format(format: "DD/MM/YYYY")
    }
    saleStartDate {
      timestamp
    }
    lastRoundEndDate {
      timestamp
      date: format(format: $formatDate)
      hour: format(format: $formatHour)
    }
    lastEventEndDate {
      timestamp
      date: format(format: $formatDate)
      hour: format(format: $formatHour)
    }
    nextRoundStartDate {
      timestamp
      date: format(format: $formatDate)
      hour: format(format: $formatHour)
    }
    finishedDate {
      timestamp
      format(format: "DD/MM/YYYY")
    }
    resultPrize {
      winners
      wrongs
      totalPrize {
        float
      }
      unitPrizeSurvive {
        float
      }
      unitPrizeWrong {
        float
      }
    }
    winners {
      awarded {
        name
      }
      survivors {
        name
      }
    }
  }
`;

export const ROUND_FIELDS = gql`
  fragment Round on RoundGame {
    id
    level
    myResult
    endGame
    status
    endDateEventRemaining {
      timestamp
    }
    endDateRemaining {
      timestamp
    }
    endDate {
      timestamp
      string
    }
    endDateEvent {
      timestamp
      string
    }
    countPlayers {
      eliminated
      survive
      percent
      total
      voided
    }
  }
`;

export const EVENT_FIELDS = gql`
  fragment Event on EventGame {
    id
    name
    tips
    status
    startDate {
      date: format(format: $formatDate)
      hour: format(format: $formatHour)
    }
  }
`;

export const LINE_FIELDS = gql`
  fragment Lines on EventLineGame {
    ticket
    myResult
    outcomeName
    description
    countPlayer {
      percent
      total
    }
    result {
      right
      score
    }
  }
`;

export const GET_CHALLENGES = gql`
  ${CHALLENGE_FIELDS}
  query GetAppChallenges(
    $appChallengesParams: AppChallengeSearchInput
    $formatDate: String!
    $formatHour: String!
  ) {
    appChallenges(params: $appChallengesParams) {
      edges {
        ...Challenge
      }
    }
  }
`;

export const GET_CHALLENGES_V2 = gql`
  ${CHALLENGE_FIELDS}
  query GetAppChallengesV2(
    $appChallengeFilter: AppChallengeFilterEnum!
    $formatDate: String!
    $formatHour: String!
  ) {
    appChallengesV2(filter: $appChallengeFilter) {
      ...Challenge
    }
  }
`;

export const SUB_CHALLENGES = gql`
  ${CHALLENGE_FIELDS}
  ${ROUND_FIELDS}
  ${EVENT_FIELDS}
  ${LINE_FIELDS}

  subscription SubChallenges(
    $appChallengeOnChangeParams: AppChallengeSearchInput
    $formatDate: String!
    $formatHour: String!
  ) {
    appChallengeOnChange(params: $appChallengeOnChangeParams) {
      action
      payload {
        ...Challenge
        rounds {
          ...Round
          events {
            ...Event
            lines {
              ...Lines
            }
          }
        }
      }
    }
  }
`;

export const GET_MYCHALLENGES = gql`
  ${CHALLENGE_FIELDS}

  query GetMyChallenges(
    $appChallengesParams: AppChallengeSearchInput
    $formatDate: String!
    $formatHour: String!
  ) {
    appMyChallenges(params: $appChallengesParams) {
      edges {
        ...Challenge
      }
    }
  }
`;

export const GET_MYCHALLENGES_V2 = gql`
  ${CHALLENGE_FIELDS}
  query GetMyAppChallengesV2(
    $appChallengeFilter: AppChallengeFilterEnum!
    $formatDate: String!
    $formatHour: String!
  ) {
    appMyChallengesV2(filter: $appChallengeFilter) {
      ...Challenge
    }
  }
`;

export const MUT_JOIN = gql`
  mutation JoinChallenge($joinChallengeId: ID!) {
    appJoinChallenge(id: $joinChallengeId) {
      message
      challenge {
        __typename
        id
        slug
      }
    }
  }
`;

export const GET_CHALLENGEBYSLUG = gql`
  ${CHALLENGE_FIELDS}
  ${ROUND_FIELDS}
  ${EVENT_FIELDS}
  ${LINE_FIELDS}

  query GetChallengeBySlug(
    $slug: String!
    $formatDate: String!
    $formatHour: String!
  ) {
    appChallengeBySlug(slug: $slug) {
      ...Challenge
      rounds {
        ...Round

        events {
          ...Event
          lines {
            ...Lines
          }
        }
      }
    }
  }
`;

export const MUT_BET = gql`
  mutation AppBetMutation(
    $challengeId: ID!
    $roundId: ID!
    $tickets: [String]!
  ) {
    appBet(challenge: $challengeId, round: $roundId, tickets: $tickets) {
      message
      receipt
    }
  }
`;
