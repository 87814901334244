import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import UsersCount from '../../components/Shared/UsersCount/UsersCount';
import EliminatedCount from '../../components/Shared/EliminatedCount/EliminatedCount';
import SurviveCount from '../../components/Shared/SurviveCount/SurviveCount';

import { useNumberFormat } from '../../hooks/numberFormat';
import { useTranslation } from 'react-i18next';

import * as S from './style';
import { useChallenge } from './Context';
import VipCount from '../../components/Shared/VipCount/VipCount';

const CountersBar = () => {
  const { t } = useTranslation();

  const { separator } = useNumberFormat();
  const { challenge } = useChallenge();

  const renderPromotions = useCallback(() => {
    if (challenge.forGuests) {
      return (
        <S.TextCounters>
          {t('challenge.countersBar.textCounters.forGuests')}
        </S.TextCounters>
      );
    } else if (challenge.allowVip && challenge.promotions.length) {
      return challenge.promotions.map((tag) => (
        <S.TextCounters key={tag}>{tag}</S.TextCounters>
      ));
    }
  }, [challenge.allowVip, challenge.forGuests, challenge.promotions.length]);

  return (
    <S.WrapperCounters>
      <S.LeftGroupCounters>{renderPromotions()}</S.LeftGroupCounters>

      <S.RightGroupCounters>
        <div>
          <UsersCount total={separator(challenge.countPlayers.total || 0)} />
          {challenge.allowVip && (
            <VipCount total={separator(challenge.countPlayers.vips || 0)} />
          )}
        </div>

        <EliminatedCount
          eliminated={separator(challenge.countPlayers.eliminated || 0)}
        />

        <SurviveCount
          survive={separator(challenge.countPlayers.survive || 0)}
        />
      </S.RightGroupCounters>
    </S.WrapperCounters>
  );
};

CountersBar.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CountersBar;
