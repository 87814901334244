import * as React from 'react';

const EndFlag = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="256"
      height="256"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
      {...props}
    >
      <g transform="translate(128 128) scale(0.87 0.87)">
        <g
          style={{
            stroke: 'none',
            strokeWidth: '0',
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: '10',
            fill: 'none',
            fillRule: 'nonzero',
            opacity: '1',
          }}
          transform="translate(-144.89999999999998 -144.90000000000003) scale(3.22 3.22)"
        >
          <path
            d="M 89.967 53.066 c -0.121 -0.648 -0.553 -1.195 -1.155 -1.463 c -8.188 -3.636 -14.183 -9.631 -17.819 -17.819 c -3.657 -8.236 -9.464 -14.473 -17.271 -18.59 l 2.229 -2.229 c 0.781 -0.781 0.781 -2.047 0 -2.828 c -0.781 -0.781 -2.047 -0.781 -2.828 0 l -4.208 4.208 c 0 0 0 0 0 0 l -3.825 3.825 l -8.636 8.636 c -0.001 0.001 -0.002 0.002 -0.003 0.003 L 0.586 62.675 c -0.781 0.781 -0.781 2.047 0 2.828 C 0.977 65.894 1.488 66.089 2 66.089 s 1.024 -0.195 1.414 -0.586 l 22.297 -22.297 c 7.558 3.679 13.158 9.448 16.609 17.218 c 4.051 9.121 10.729 15.801 19.852 19.853 c 0.261 0.115 0.537 0.172 0.811 0.172 c 0.521 0 1.032 -0.203 1.415 -0.586 l 25.017 -25.017 C 89.88 54.38 90.087 53.714 89.967 53.066 z M 66.737 34.195 l -9.515 9.515 c -3.674 -6.966 -9.042 -12.334 -16.007 -16.008 l 8.159 -8.16 l 1.38 -1.38 C 57.905 21.645 63.256 27.044 66.737 34.195 z M 62.559 76.045 c -7.15 -3.482 -12.502 -8.881 -15.984 -16.031 l 9.516 -9.516 c 3.674 6.965 9.041 12.333 16.008 16.007 L 62.559 76.045 z"
            style={{
              stroke: 'none',
              strokeWidth: '1',
              strokeDasharray: 'none',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              fill: 'rgb(0,0,0)',
              fillRule: 'nonzero',
              opacity: '1',
            }}
            transform="matrix(1 0 0 1 0 0) "
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default EndFlag;
