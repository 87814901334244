import React from 'react';

const MinYear = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name={18} opacity={0.494} fill="#9a9a9a">
        <path
          data-name="Caminho 16"
          d="M12 22.13A10.13 10.13 0 101.87 12 10.13 10.13 0 0012 22.13zM12 24a12 12 0 1112-12 12 12 0 01-12 12z"
        />
        <path
          data-name="Caminho 17"
          d="M10.994 11.757A1.642 1.642 0 0110 10.21a2.025 2.025 0 012.185-1.978 1.987 1.987 0 012.133 1.988 1.6 1.6 0 01-1.02 1.537 1.771 1.771 0 011.236 1.736 2.169 2.169 0 01-2.36 2.115 2.2 2.2 0 01-2.41-2.094 1.9 1.9 0 01.515-1.326 2.037 2.037 0 01.711-.431zm1.164-2.526a.985.985 0 00.01 1.968.965.965 0 00.948-.979.975.975 0 00-.958-.989zm-.01 3.094a1.1 1.1 0 00.01 2.2 1.086 1.086 0 001.04-1.105 1.065 1.065 0 00-1.05-1.094zm-5.717 3.283v-5.126h-1.84V9.371a3.131 3.131 0 001.466-.319 1.379 1.379 0 00.746-.82H7.9v7.376zm10.056-4.428l-1.469.006v1.469h1.47v1.466h1.467v-1.466h1.466v-1.47h-1.466V9.72h-1.468z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default MinYear;
