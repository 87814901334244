import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const BadgeInfo = ({ text, marginLeft, showIcon, bgColor, fontSize }) => {
  return (
    <S.Wrapper marginLeft={marginLeft} bgColor={bgColor}>
      {showIcon && <S.Icon />}

      <S.Text fontSize={fontSize}>{text}</S.Text>
    </S.Wrapper>
  );
};

BadgeInfo.propTypes = {
  text: PropTypes.string,
  marginLeft: PropTypes.number,
  showIcon: PropTypes.bool,
  bgColor: PropTypes.string,
  fontSize: PropTypes.number,
};

export default BadgeInfo;
