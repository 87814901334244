import React from 'react';
import PropTypes from 'prop-types';

import Event from './Event/Event';
import CountDownMyResult from '../CountDownMyResult/CountDownMyResult';

import { useChallenge } from '../../../pages/ChallengeDetails/Context';

const invalidRoundStatus = ['EndBet', 'Canceled'];

const RoundItem = ({ round, variant }) => {
  const { challenge, myResultResolver } = useChallenge();

  return (
    <>
      {round.events.map((event, idx) => {
        return (
          <div key={idx}>
            {idx === 0 &&
              challenge.result !== 'WaitingNewRound' &&
              variant === 'default' &&
              !invalidRoundStatus.includes(round.status) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CountDownMyResult
                    message
                    myRole={challenge.myRole}
                    myResult={myResultResolver}
                    hideLabel
                    hideWhenDone
                    timestamps={{
                      lastRoundEndDate: round.endDateRemaining,
                      lastEventEndDate: round.endDateEventRemaining,
                    }}
                  />
                </div>
              )}

            <Event
              event={event}
              isLastEvent={round.events.length - 1 === idx}
              idx={idx}
              round={round}
            />
          </div>
        );
      })}
    </>
  );
};

RoundItem.propTypes = {
  round: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

RoundItem.defaultProps = {
  variant: 'default',
};

export default RoundItem;
