import styled from 'styled-components';
import { Clock as ClockC } from 'react-feather';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RendererWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.div`
  display: flex;
  align-content: center;
  ${RendererWrapper} & {
    color: var(--color, #c7f86c);
  }
`;

export const Clock = styled(ClockC).attrs(({ size, icon }) => {
  return {
    icon: icon,
    size: size,
  };
})`
  ${({ width }) => width && `width: 20px;`}
  height: 10px;
  width: 13px;
  height: 13px;
  margin-right: 3px;
`;

export const CountDownWrapper = styled.h1`
  display: inline-block;
  position: relative;
  top: 1px;

  font-family: Gorga Grotesque Light;

  ${RendererWrapper} & {
    color: var(--color, #c7f86c);
  }
`;

export const Group = styled.div`
  font-family: Gorga Grotesque Light;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubGroup = styled.div`
  display: flex;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${({ size }) => size};
`;

export const Info = styled.p`
  text-align: center;
  min-width: 18px;
`;

export const Text = styled.p`
  font-size: 5px;

  text-align: center;
  flex: 5;
  width: 100%;
  text-transform: uppercase;
  font-family: Gorga Grotesque Regular;
`;

export const Separator = styled.p`
  ${Item}:last-of-type & {
    display: none;
  }
`;

export const Message = styled.div`
  background: ${({ theme, backgroundColor }) =>
    backgroundColor
      ? '#EF5D5F'
      : `linear-gradient(to right, ${theme.primary} 0%, ${theme.secondary} 100%)`};

  color: #272f3c;

  text-align: center;
  padding: 3px 7px 1px;
  min-height: 12px;
  border-radius: 10px;
  min-width: 50px;
  margin-left: 5px;
`;

export const Title = styled.h1`
  font-family: Gorga Grotesque Regular;
  font-size: 8px;
  text-transform: uppercase;
`;
