import React, { useEffect } from 'react';

import Loading from '../../components/Shared/Loading/Loading';
import Join from '../Join/Join';
import Challenge from '../../components/Shared/Challenge/Challenge';
import Header from '../../components/Shared/Header/Header';
import CountDown from '../../components/Shared/CountDown/CountDown';
import BannerDetails from '../../components/Shared/BannerDetails/BannerDetails';
import ListBar from './ListBar';
import CountersBar from './CountersBar';
import BadgeInfo from '../../components/Shared/BadgeInfo/BadgeInfo';
import TelegramNotification from '../../components/Shared/TelegramNotification/TelegramNotification';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GET_CHALLENGEBYSLUG } from '../../queries/challenge';
import { ChallengeProvider } from './Context';

import * as S from './style';
import { meVar, partnerInfoVar } from '../../components/Apollo/vars';
import { useEnums } from '../../hooks/useEnum';
import Progression from './Progression';

const ChallengeDetails = () => {
  const history = useHistory();

  const { slug } = useParams();
  const partnerInfo = useReactiveVar(partnerInfoVar);

  const { t } = useTranslation();

  const meInfo = useReactiveVar(meVar);

  const { challengeLabel, challengeStyle } = useEnums();

  const {
    data: { appChallengeBySlug: challenge } = { appChallengeBySlug: undefined },
    loading: loading,
    subscribeToMore,
    refetch,
  } = useQuery(GET_CHALLENGEBYSLUG, {
    fetchPolicy: 'network-only',
    variables: {
      slug,
      formatDate: 'DD/MM',
      formatHour: 'HH:mm',
    },
  });

  const interruptedType = {
    InsufficientPlayers: t('challenge.interruptedType.insufficientPlayers'),
    Admin: t('challenge.interruptedType.Admin'),
  };

  useEffect(() => {
    if (!loading && !challenge) {
      history.push('/404');
    }
  }, [challenge]);

  const hasSaleDate = !!challenge?.saleStartDate;

  if (loading) {
    return (
      <S.Wrapper>
        <Loading />
      </S.Wrapper>
    );
  }

  if (!challenge) {
    return <></>;
  }

  const statusColor = challengeStyle(challenge.status);
  const statusLabel = challengeLabel(challenge.status, 'detail');

  return (
    <S.Wrapper>
      <ChallengeProvider
        challenge={challenge}
        subscriptionHandler={subscribeToMore}
        refetch={refetch}
      >
        <Header name={challenge.name} goBackPath={'/'} fixed>
          <S.StatusHeader>
            {hasSaleDate && challenge.status === 'Sale' && (
              <>
                <S.GroupHeaderStatus>
                  <S.TextStatus>
                    {t('challenge.header.status.startsIn')}
                  </S.TextStatus>
                  <CountDown
                    hideLabels
                    size="0.6em"
                    hideIcon
                    milliseconds={challenge?.saleStartDate?.timestamp}
                  />
                </S.GroupHeaderStatus>
                <S.Status {...statusColor}>{statusLabel}</S.Status>
              </>
            )}
            {challenge.status !== 'Sale' && (
              <S.GroupStatus>
                <S.Status
                  centered={challenge.status !== 'Sale'}
                  {...statusColor}
                >
                  {statusLabel}
                </S.Status>
              </S.GroupStatus>
            )}
          </S.StatusHeader>

          {challenge.status === 'Interrupted' && (
            <S.InterruptedDetails>
              {interruptedType[challenge.interruptedType]}
            </S.InterruptedDetails>
          )}
        </Header>
        <BannerDetails />
        <CountersBar />
        <ListBar>
          <S.TextDescription>{challenge.description}</S.TextDescription>
        </ListBar>

        {challenge.progressionValues.length > 1 && (
          <ListBar>
            <Progression />
          </ListBar>
        )}

        {challenge.tags.length ? (
          <ListBar>
            <S.WrapperTag>
              {challenge.tags.map((item, idx) => {
                return (
                  <S.GroupTag key={idx}>
                    <BadgeInfo
                      bgColor="rgb(233 229 109)"
                      marginLeft={5}
                      text={item}
                    />
                  </S.GroupTag>
                );
              })}
            </S.WrapperTag>
          </ListBar>
        ) : null}
        {challenge.status === 'Interrupted' && (
          <S.ChallengeMessageInfo>
            <S.AlertIcon />
            <S.TextInfo>{t('challengeDetail.interruptedMessage')}</S.TextInfo>
          </S.ChallengeMessageInfo>
        )}
        {(challenge.status !== 'Sale' || challenge.myRole === 'Player') && (
          <Challenge />
        )}
        {challenge.myRole === 'Player' &&
          JSON.parse(
            // eslint-disable-next-line no-undef
            localStorage.getItem('survive@hideMessageTelegram') || `false`
          ) === false &&
          !meInfo.hasTelegram &&
          partnerInfo.isTelegramEnabled && (
            <ListBar>
              <TelegramNotification
                isUpdateMode={meInfo?.hasTelegram || false}
              />
            </ListBar>
          )}
        <Join
          onJoined={refetch}
          status={challenge.status}
          isFree={challenge.buyin.float <= 0}
          isUserVip={challenge.isUserVip}
          id={challenge.id}
          isVip={challenge.allowVip}
          allowManualJoin={challenge.allowManualJoin}
          myRole={challenge.myRole}
        />
      </ChallengeProvider>
    </S.Wrapper>
  );
};

export default ChallengeDetails;
