import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

import loading from '../../../assets/loading.json';

import * as S from './style';

const Loading = ({ global }) => {
  if (global)
    return (
      <S.Wrapper>
        <S.LoadingSysgaming />
      </S.Wrapper>
    );

  return (
    <S.Group>
      <Lottie animationData={loading} style={{ width: 200, height: 200 }} />
    </S.Group>
  );
};

Loading.propTypes = {
  global: PropTypes.bool,
};

export default Loading;
