import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Home,
  Layers,
  Bell,
  Bookmark,
  LogIn,
  Info,
  HelpCircle,
} from 'react-feather';

import { Wrapper, Group, Title } from './style';
import { useSurviveAuth } from '../../SurviveAuth';
import { isBlankPageRender } from '../../../config/app';
import { useReactiveVar } from '@apollo/client';
import { challengeTabsState } from '../../Apollo/vars';

const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

const BottomBar = () => {
  const history = useHistory();
  const location = useLocation();
  const { getToken } = useSurviveAuth();

  const hidden = isBlankPageRender(location.pathname);
  const tabState = useReactiveVar(challengeTabsState);

  if (hidden) {
    return <></>;
  }

  const handleOnClick = (path) => {
    if (typeof path !== 'string') return path();

    if (path === '/') {
      delete tabState.appChallengesV2;
      challengeTabsState({ ...tabState });
      delete tabState.appMyChallengesV2;
    } else if (path === '/my-challenges') {
      challengeTabsState({ ...tabState });
    }

    history.push(path);

    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
    // eslint-disable-next-line no-undef
    document.location.href = '#top';

    if (compactMode) {
      // eslint-disable-next-line no-undef
      window.top.postMessage(
        {
          target: 'survive',
          content: {
            name: 'scrollTo',
          },
        },
        '*'
      );
    }
  };

  return (
    <Wrapper>
      {!getToken() && (
        <BottomBarPublic onClick={handleOnClick} compact={compactMode} />
      )}
      {getToken() && (
        <BottomBarPrivate onClick={handleOnClick} compact={compactMode} />
      )}
    </Wrapper>
  );
};

const itemsCompact = [
  {
    id: 'home.title',
    title: 'bottomBar.home.title',
    path: '/',
    icon: <Home width={20} />,
    showInPrivate: true,
    showInPublic: true,
  },
  {
    id: 'myChallenges.title',
    title: 'bottomBar.myChallenges.title',
    path: '/my-challenges',
    icon: <Bookmark width={20} />,
    showInPrivate: true,
    showInPublic: false,
  },
  {
    id: 'about.title',
    title: 'bottomBar.about.title',
    path: '/about',
    icon: <Info width={20} />,
    showInPrivate: true,
    showInPublic: true,
  },
  {
    id: 'faq.title',
    title: 'bottomBar.faq.title',
    path: '/faq',
    icon: <HelpCircle width={20} />,
    showInPrivate: true,
    showInPublic: true,
  },
];

const itemsStandalone = [
  {
    id: 'home.title',
    title: 'bottomBar.home.title',
    path: '/',
    icon: <Home width={20} />,
    showInPrivate: true,
    showInPublic: true,
  },
  {
    id: 'challenges.title',
    title: 'bottomBar.challenges.title',
    path: '/challenges',
    icon: <Layers width={20} />,
    showInPrivate: false,
    showInPublic: true,
  },
  {
    id: 'myChallenges.title',
    title: 'bottomBar.myChallenges.title',
    path: '/my-challenges',
    icon: <Bookmark width={20} />,
    showInPrivate: true,
    showInPublic: false,
  },
  {
    id: 'join.title',
    title: 'bottomBar.join.title',
    icon: <LogIn />,
    isLoginAction: true,
    showInPrivate: false,
    showInPublic: true,
  },
  {
    id: 'notifications.title',
    title: 'bottomBar.notifications.title',
    path: '/notifications',
    icon: <Bell width={20} />,
    showInPrivate: true,
    showInPublic: false,
  },
];

const debounceTime = 150;

const BottomBarPublic = ({ compact, onClick }) => {
  const { login } = useSurviveAuth();

  const [touched, setTouched] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();
  const debounce = useDebouncedCallback((fn) => fn(), debounceTime);

  return (compact ? itemsCompact : itemsStandalone)
    .filter((item) => item.showInPublic)
    .map(({ title, path, icon, id, isLoginAction }) => {
      return (
        <Group
          key={id}
          touched={touched === id}
          activeRoute={location.pathname === path}
          onMouseDown={() => {
            return setTouched(id);
          }}
          onClick={() => (isLoginAction ? login() : onClick(path))}
          onMouseUp={() => {
            return debounce(() => setTouched(null));
          }}
        >
          {icon}
          <Title>{t(title)}</Title>
        </Group>
      );
    });
};

const BottomBarPrivate = ({ compact, onClick }) => {
  const [touched, setTouched] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();
  const debounce = useDebouncedCallback((fn) => fn(), debounceTime);

  return (compact ? itemsCompact : itemsStandalone)
    .filter((item) => item.showInPrivate)
    .map(({ title, path, icon, id }) => {
      return (
        <Group
          key={id}
          touched={touched === id}
          activeRoute={location.pathname === path}
          onMouseDown={() => {
            return setTouched(id);
          }}
          onClick={() => onClick(path)}
          onMouseUp={() => {
            return debounce(() => setTouched(null));
          }}
        >
          {icon}
          <Title>{t(title)}</Title>
        </Group>
      );
    });
};

export default BottomBar;
