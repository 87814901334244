import styled from 'styled-components';

import { animated } from 'react-spring';

export const LineVertical = styled(animated.div)`
  border-left: 1px dashed #7e889a;
  position: relative;
`;

export const IconBg = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #272e38;
  border-radius: 50px;

  border-style: solid;
  border-color: #1e242e;
  border-width: 5px;

  ${({ size }) =>
    size &&
    `
    height: ${size}px;
    width: ${size}px;`};

  div {
    background: ${({ theme }) => theme.primary};
  }
`;
