import React from 'react';

const SysGaming = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={25.827}
      viewBox="0 0 26 25.827"
      {...props}
    >
      <g opacity={0.498} fillRule="evenodd">
        <path
          data-name="Caminho 24"
          d="M4.94 9.88a4.94 4.94 0 114.94-4.94 4.94 4.94 0 01-4.94 4.94"
          fill="#c6cfd6"
        />
        <path
          data-name="Caminho 25"
          d="M13 9.88a4.94 4.94 0 114.94-4.94A4.94 4.94 0 0113 9.88"
          fill="#c6cfd6"
        />
        <path
          data-name="Caminho 26"
          d="M21.06 9.88A4.94 4.94 0 1126 4.94a4.94 4.94 0 01-4.94 4.94M4.94 17.767a4.94 4.94 0 114.94-4.94 4.94 4.94 0 01-4.94 4.94"
          fill="#c6cfd6"
        />
        <path
          data-name="Caminho 27"
          d="M13 17.767a4.94 4.94 0 114.94-4.94 4.94 4.94 0 01-4.94 4.94"
          fill="#c6cfd6"
        />
        <path
          data-name="Caminho 28"
          d="M21.06 17.767a4.94 4.94 0 114.94-4.94 4.94 4.94 0 01-4.94 4.94m-16.12 8.06a4.94 4.94 0 114.94-4.94 4.94 4.94 0 01-4.94 4.94"
          fill="#c6cfd6"
        />
        <path
          data-name="Caminho 29"
          d="M13 25.827a4.94 4.94 0 114.94-4.94 4.94 4.94 0 01-4.94 4.94"
          fill="#c6cfd6"
        />
        <path
          data-name="Caminho 30"
          d="M21.06 25.827a4.94 4.94 0 114.94-4.94 4.94 4.94 0 01-4.94 4.94"
          fill="#c6cfd6"
        />
        <path
          data-name="Caminho 31"
          d="M6.153 5.631c0-.6-.042-.737-.885-.932-.541-.127-.551-.136-.551-.482 0-.264.071-.346.471-.346a4.251 4.251 0 01.838.091l.066-.482a4.42 4.42 0 00-.913-.1c-.8 0-1.054.273-1.054.823 0 .641.1.782.861.946.565.118.574.146.574.482 0 .291-.071.377-.5.377a3.05 3.05 0 01-.885-.141l-.1.45a2.716 2.716 0 00.979.182c.927 0 1.1-.3 1.1-.869m6.847-.765l-.628-1.485h-.585l.938 2.093v.854h.55v-.854l.937-2.093h-.585zm8.927.765c0-.6-.042-.737-.885-.932-.541-.127-.551-.136-.551-.482 0-.264.071-.346.471-.346a4.251 4.251 0 01.838.091l.066-.482a4.42 4.42 0 00-.913-.1c-.8 0-1.054.273-1.054.823 0 .641.1.782.861.946.565.118.574.146.574.482 0 .291-.071.377-.5.377a3.05 3.05 0 01-.885-.141l-.1.45a2.718 2.718 0 00.979.182c.927 0 1.1-.3 1.1-.869M4.638 12.08c0-.309.13-.473.524-.473a3.909 3.909 0 01.838.082l.063-.5a3.59 3.59 0 00-.856-.1c-.775 0-1.134.291-1.134.914v1.292c0 .6.35.914 1.08.914a3.781 3.781 0 001-.137v-1.328H5.62v.919a3.153 3.153 0 01-.462.036c-.395 0-.52-.173-.52-.468V12.08zm9.173 2.475h.576l-.914-2.942h-.768l-.916 2.947h.576l.183-.611h1.075l.188.611zm-.724-2.486l.4 1.394h-.8zm8.9.58l.05 1.907h.587l-.136-2.947h-.641l-.782 2.093-.792-2.089h-.641l-.136 2.947h.587l.05-1.907.559 1.526h.728zm-16.7 6.674h-.694v2.947h.693zm8.177 2.947h.749v-2.943h-.549v2.239l-1.129-2.239h-.749v2.947h.549v-2.235zm6.948-1.955c0-.309.13-.473.525-.473a3.916 3.916 0 01.838.082l.063-.5a3.591 3.591 0 00-.856-.1c-.775 0-1.134.291-1.134.914v1.292c0 .6.35.914 1.08.914a3.78 3.78 0 001-.137V20.98h-.535v.919a3.151 3.151 0 01-.462.036c-.395 0-.52-.173-.52-.468z"
          fill="#fffffe"
        />
      </g>
    </svg>
  );
};

export default SysGaming;
