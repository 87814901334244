/* eslint-disable react/prop-types */
import React from 'react';
import { Trans } from 'react-i18next';
import { useChallenge } from '../../../../../pages/ChallengeDetails/Context';

const SurviveWithWrong = ({ myResult, isGuest, components }) => {
  const {
    challenge: { resultPrize },
  } = useChallenge();

  const { winners, wrongs } = resultPrize;

  const options = {
    components,
    count: winners === 1 && wrongs > 1 ? 1 : 2,
  };

  const optionsSurvive = { components, count: resultPrize.winners };

  if (myResult === 'Eliminated' || isGuest)
    return <Trans i18nKey="congratulation.guest.withWrong" {...options} />;

  if (myResult === 'Awarded')
    return <Trans i18nKey="congratulation.withWrong" {...options} />;

  if (myResult === 'Survive')
    return (
      <Trans i18nKey="congratulation.withWrong.survive" {...optionsSurvive} />
    );
};

export default SurviveWithWrong;
