/* eslint-disable react/prop-types */
import React from 'react';

import * as S from '../style';

import Survive from './Survive';
import SurviveWithWrong from './SurviveWithWrong';
import AllPlayersWrong from './AllPlayersWrong';

const components = {
  bold: <S.TextBold />,
  br: <br />,
};

const congratulations = {
  Survive,
  SurviveWithWrong,
  AllPlayersWrong,
};

const Congratulation = ({ typeWinner, ...props }) => {
  const Component = congratulations[typeWinner];

  return (
    <S.SubtitleMessageBold>
      <Component {...props} components={components} />
    </S.SubtitleMessageBold>
  );
};

export default Congratulation;
