import styled, { css } from 'styled-components';

import LottieGlobal from 'lottie-react';
import ButtonDefault from '../Button/Button';

import { animated } from 'react-spring';
import { ChevronLeft } from 'react-feather';

export const Wrapper = styled.div``;

export const Away = styled.h1`
  font-family: Gorga Grotesque Regular;
  text-align: left;

  font-size: 12px;

  color: #ffffff;
`;

export const Title = styled.div`
  font-family: Gorga Grotesque Regular;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: ${({ size }) => (size ? size : 14)}px;
  margin: 5px 0px;

  color: #ffffff;
`;

export const ChallengeStart = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 10px;
  font-family: Gorga Grotesque Light;

  text-align: center;
  text-transform: uppercase;

  padding: 30px 5px;
  line-height: 14px;
`;

export const Status = styled.div`
  display: inline-flex;
  text-transform: uppercase;
  font-family: Gorga Grotesque Regular;
  text-align: center;

  background: ${({ background }) => background || 'transparent'};
  color: ${({ color }) => color || 'white'};

  padding: 3px 5px 1px;
  margin-left: 5px;
  font-size: 8px;
  border-radius: 20px;
  /* color: #222a36; */

  @media (max-width: 480px) {
    max-width: 200px;
  }
`;

export const Description = styled.h1`
  font-family: Gorga Grotesque light;
  text-align: center;

  font-size: 10px;
  margin-top: 10px;
  color: #7e889a;
`;

export const MessageWin = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleCurrency = styled.h1`
  font-family: Gorga Grotesque Regular;

  margin-top: 5px;
  font-size: 25px;

  color: #fafafa;
`;

export const DescriptionWin = styled.h1`
  font-family: Gorga Grotesque Light;
  text-align: right;

  font-size: 10px;
  margin-bottom: 10px;

  color: #c4cbd8;
`;

export const Winners = styled.span`
  font-family: Gorga Grotesque Light;
  text-align: right;

  color: ${({ theme }) => theme.primary};

  font-size: 12px;
  margin: 0px 4px;
`;

export const Lottie = styled.div`
  position: absolute;
  pointer-events: none;

  bottom: 0px;
  right: 0px;
  left: 0px;
  margin-bottom: 50px;
`;

export const LottieFestive = styled.div`
  width: 80px;

  top: 0;
`;

export const LottieLoad = styled(LottieGlobal)``;

export const Button = styled(ButtonDefault)`
  margin-top: 10px;

  ${({ type }) => {
    switch (type) {
      case 'error':
        return css`
          opacity: 1 !important;
          > div {
            padding-top: 4px;
            background: linear-gradient(#731819b5, #5d0a0fa6);
          }

          h1 {
            color: #ef5d5f !important;
            font-family: Gorga Grotesque Light !important;
            font-size: 15px;
            text-shadow: none;
            text-transform: uppercase;
          }
        `;
      case 'success':
        return css`
          opacity: 1 !important;
          > div {
            padding-top: 4px;
            background: linear-gradient(#314a41, #243c28);
          }

          h1 {
            color: #cbf96a !important;
            font-family: Gorga Grotesque Light !important;
            font-size: 15px;
            text-shadow: none;
            text-transform: uppercase;
          }
        `;
      default:
        return '';
    }
  }}
`;

export const AcordionButton = styled(ChevronLeft)`
  color: #ffffff;

  transform: ${({ isopen }) => (!isopen ? 'rotate(90deg)' : 'rotate(-90deg)')};
  transition: transform 0.5s;

  &:hover {
    color: #a6aebc;
  }

  margin-right: 10px;
`;

export const Acordion = styled.div`
  max-height: ${({ isopen }) => (!isopen ? 1500 : 0.1)}px;
  transition: max-height 0.5s;

  overflow: hidden;
`;

export const Group = styled.div`
  background: ${({ isopen }) =>
    isopen
      ? 'linear-gradient(to right, #272f3c 0%, #2a3240 30%, #212733 100%)'
      : 'inherit'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  border-radius: 4px;
  margin-top: 10px;
  padding: 15px;
`;

export const SubGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const BadgeEnd = styled.div`
  font-family: Gorga Grotesque Bold;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;

  border-radius: 3px;
  padding: 8px 9px 5px;
  margin-top: 5px;

  font-size: 0.6em;

  line-height: 0%;

  color: #222a36;
  background: #878ea0;
`;

export const TextBold = styled.h2`
  display: inline;
  font-family: Gorga Grotesque Regular;

  font-size: 14px;
`;

export const CircleIcon = styled.div`
  background: ${({ theme }) => theme.primary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;
