import React from 'react';
import PropTypes from 'prop-types';

import Survive from '../../Svg/Survive';

import { Wrapper, Icon, Inside } from './style';

const SurviveCount = ({ survive, fontSize }) => {
  return (
    <Wrapper>
      <Icon>
        <Survive width={7} height={13} />
      </Icon>

      <Inside fontSize={fontSize}>{survive}</Inside>
    </Wrapper>
  );
};

SurviveCount.propTypes = {
  survive: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.number,
};

export default SurviveCount;
