import * as React from 'react';

const Trophy = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 13.714 12"
      {...props}
    >
      <path
        data-name="Icon metro-trophy"
        d="M11.143 1.714V0H2.571v1.714H0v1.715a2.573 2.573 0 003.343 2.453A4.288 4.288 0 006 7.628v2.657h-.857A1.714 1.714 0 003.429 12h6.857a1.714 1.714 0 00-1.714-1.714h-.857V7.629a4.288 4.288 0 002.657-1.746 2.573 2.573 0 003.343-2.454V1.714zM2.571 4.982a1.555 1.555 0 01-1.553-1.553v-.858h1.553v.857a4.275 4.275 0 00.281 1.528 1.556 1.556 0 01-.281.026zm10.125-1.553a1.552 1.552 0 01-1.834 1.528 4.275 4.275 0 00.281-1.528v-.858h1.554z"
        fill="#fae21a"
      />
    </svg>
  );
};

export default Trophy;
