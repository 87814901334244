import styled from 'styled-components';

import { Info as InfoFeather } from 'react-feather';

export const LineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ selected, winned, bet, loss, right }) => {
    if (selected) return `linear-gradient(#14181f,#181d27)`;
    if (winned || right) return `linear-gradient(#314a41,#243c28)`;
    if (loss) return `linear-gradient(#731819b5,#5d0a0fa6);`;
    if (bet) return `linear-gradient(#4b5669,#45536b)`;

    return `linear-gradient(#313a4a, #242d3c)`;
  }};
  width: 100%;
  min-height: 42px;
  margin: 5px 0px;
  border: ${({ selected, winned, bet }) => {
    if (selected) return `1px solid #181d27`;
    if (winned) return `1px solid #181d27`;
    if (bet) return `1px solid #607cab`;
  }};
  border-radius: 3px;
  font-family: ${({ selected, winned }) => {
    if (selected) return `Gorga Grotesque Regular`;
    if (winned) return `Gorga Grotesque Regular`;

    return `Gorga Grotesque Light`;
  }};
  font-size: 13px;
  color: ${({ selected, winned, right, isDisabled, bet, loss }) => {
    if (isDisabled && winned) return `#cbf96a`;
    if (isDisabled && right) return `rgba(255,255,255,0.6)`;
    if (isDisabled && loss) return `#ef5d5f`;
    if (isDisabled && bet) return `white`;
    if (selected) return `white`;
    if (isDisabled) return `#7c879c`;
    if (winned) return `#a4dab9`;

    return `#fff`;
  }};
  transition: all 0.3s ease;
  box-shadow: ${({ selected, winned, isDisabled }) => {
    if (isDisabled) return '';
    if (selected) return `inset 3px 4px 6px 2px rgb(0 0 0 / 10%)`;
    if (winned) return `inset 3px 4px 6px 2px rgb(0 0 0 / 10%)`;

    return `0px 4px 5px 1px rgba(0, 0, 0, 0.1)`;
  }};
  cursor: ${({ isDisabled }) => {
    if (isDisabled) return `not-allowed`;

    return `pointer`;
  }};
`;

export const Score = styled.p`
  font-size: 9px;
`;

export const Bets = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  justify-content: flex-end;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const Group = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GroupUsers = styled.div`
  min-width: 85px;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  align-items: center;
`;

export const Percent = styled.div`
  font-family: Gorga Grotesque Light;
  color: ${({ color }) => color};

  text-align: center;
  font-size: 10px;
  margin-left: 2px;
`;

export const Total = styled.div`
  font-family: Gorga Grotesque Light;
  text-align: center;

  color: ${({ color }) => color};

  font-size: 10px;
  margin-left: 2px;
`;

export const InfoIcon = styled(InfoFeather)`
  min-width: 40px;
  padding: 4px;
  margin-left: 10px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #384355;
  }
`;

export const Bar = styled.span`
  margin-left: 2px;

  font-size: 14px;
  color: ${({ color }) => color};

  vertical-align: middle;
`;
