import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Icon, Inside } from './style';
import Skull from '../../Svg/Skull';

const EliminatedCount = ({ eliminated, fontSize }) => {
  return (
    <Wrapper>
      <Icon>
        <Skull width={13} />
      </Icon>

      <Inside fontSize={fontSize}>{eliminated}</Inside>
    </Wrapper>
  );
};

EliminatedCount.propTypes = {
  eliminated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.number,
};

export default EliminatedCount;
