/* eslint-disable no-unused-vars */
/* eslint-disWrapperDescriptionle no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Check } from 'react-feather';
import { useSpring } from 'react-spring';
import { useTranslation } from 'react-i18next';

import Loading from '../Loading/Loading';

import { useSurviveAuth } from '../../SurviveAuth';

import {
  Wrapper,
  Background,
  Title,
  WrapperDescription,
  Description,
} from './style';

const timeAnimation = 900;

const bakgroundSettings = (variant, layoutConfirm) => {
  if (variant === 'vip') {
    return 'linear-gradient(#ffd04f, #de9a00)';
  }

  return 'linear-gradient(#cbf96a, #cbf96a)';
};

const Button = ({
  title,
  loading,
  description,
  onPress,
  border,
  bg,
  confirm,
  descriptionConfirm,
  width,
  style,
  disabled,
  feedbackMessage,
  className,
  variant,
  icon,
}) => {
  const [layoutConfirm, setLayoutConfirm] = useState(false);
  const [flip, setFlip] = useState(false);

  const { isAuthenticated } = useSurviveAuth();
  const { t } = useTranslation();

  const bgTransition = useSpring({
    reset: true,
    background: !loading && bakgroundSettings(variant, layoutConfirm),
    opacity: flip ? 0.6 : 1,
    config: { duration: timeAnimation },
    onRest: () => setFlip(!flip),
  });

  const handleOnClick = () => {
    if (disabled || loading) return;
    if (!isAuthenticated()) return onPress();

    if (confirm && !layoutConfirm) {
      setFlip(!flip);
      return setLayoutConfirm(true);
    }

    onPress();
  };

  useEffect(() => {
    setLayoutConfirm(() => false);
  }, [feedbackMessage]);

  if (feedbackMessage.message) {
    return (
      <Wrapper
        style={style}
        disabled={true}
        feedbackMessage={true}
        className={className}
      >
        <Background border={border} width={width}>
          <Title>{feedbackMessage.message}</Title>
        </Background>
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper
        variant={variant}
        style={style}
        loading={loading.toString()}
        className={className}
      >
        <Background>
          <Loading />
        </Background>
      </Wrapper>
    );
  }

  if (!layoutConfirm) {
    return (
      <Wrapper
        variant={variant}
        onClick={handleOnClick}
        style={style}
        disabled={disabled}
        className={className}
      >
        <Background border={border} bg={bg} width={width}>
          <Title>{title.toUpperCase()}</Title>

          {description && (
            <WrapperDescription>
              {icon}
              <Description>{t(description)}</Description>
            </WrapperDescription>
          )}
        </Background>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      variant={variant}
      onClick={handleOnClick}
      style={style}
      disabled={disabled}
      className={className}
    >
      <Background border={border} bg={bg} style={bgTransition} width={width}>
        <Title confirm="true">{t('button.confirm.title').toUpperCase()}</Title>

        {descriptionConfirm ? (
          <WrapperDescription>
            <Description confirm="true">{t(descriptionConfirm)}</Description>
          </WrapperDescription>
        ) : null}
      </Background>
    </Wrapper>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  border: PropTypes.number,
  bg: PropTypes.string,
  description: PropTypes.string,
  confirm: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  descriptionConfirm: PropTypes.string,
  width: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  feedbackMessage: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.node,
};

Button.defaultProps = {
  title: 'no title',
  className: '',
  onPress: () => {},
  confirm: false,
  loading: false,
  style: {},
  disabled: false,
  feedbackMessage: {},
  variant: 'default',
  icon: <Check color="#C1F773" size={12} />,
};

export default Button;
