/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

import telegramLogo from '../../../assets/img/telegram-logo.png';
import { useTranslation } from 'react-i18next';
import loading from '../../../assets/loading.json';
import { useMutation, useReactiveVar } from '@apollo/client';
import { MUT_NOTIFICATION_TELEGRAM } from '../../../queries/user';
import { app } from '../../../config/app';
import Modal from '../Modal/Modal';
import { meVar } from '../../Apollo/vars';

const TelegramNotification = ({ variant, isUpdateMode }) => {
  const { t } = useTranslation();

  const meInfo = useReactiveVar(meVar);

  const [assignTelegram, { loading: loadingAssign }] = useMutation(
    MUT_NOTIFICATION_TELEGRAM
  );

  const [isHidden, setHidden] = useState(false);
  const [checked, setChecked] = useState(() => {
    // eslint-disable-next-line no-undef
    return JSON.parse(localStorage.getItem('survive@hideMessageTelegram'));
  });

  const [feedbackMessage, setFeedbackMessage] = useState({
    text: '',
    type: '',
  });

  const toggleChecked = useCallback(() => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('survive@hideMessageTelegram', !checked);
    setChecked(!checked);
  }, [checked]);

  const joinTelegram = () => {
    // eslint-disable-next-line no-undef
    window.Telegram.Login.auth(
      {
        bot_id: app.telegramBot,
        request_access: 'write',
      },
      (data) => {
        if (!data) {
          setFeedbackMessage({
            text: t('telegramNotification.telegramError'),
            type: 'error',
          });
          return;
        }
        assignTelegram({
          variables: {
            chat_id: data.id.toString(),
          },
        }).then(({ data: { createUserNotificationTelegram } }) => {
          if (createUserNotificationTelegram === true) {
            setFeedbackMessage({
              text: t('telegramNotification.apiSuccess'),
              type: 'success',
            });

            meVar({ ...meInfo, hasTelegram: true });
          } else {
            setFeedbackMessage({
              text: t('telegramNotification.apiError'),
              type: 'error',
            });
          }
        });
      }
    );
  };

  if (isHidden) {
    return <></>;
  }

  const labels = !isUpdateMode
    ? {
        title: t('telegramNotification.title'),
        description: t('telegramNotification.description'),
        join: t('telegramNotification.joinButton'),
      }
    : {
        title: t('telegramNotification.titleUpdate'),
        description: t('telegramNotification.descriptionUpdate'),
        join: t('telegramNotification.joinButtonUpdate'),
      };

  return (
    <S.Wrapper variant={variant}>
      <S.Header>
        <img src={telegramLogo} />
        <div>
          <h1>{labels.title}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: labels.description,
            }}
          ></p>
        </div>
      </S.Header>

      <S.Body>
        {variant === 'default' && (
          <div>
            <S.Checkbox checked={checked} onChange={toggleChecked} />
            <label onClick={toggleChecked}>
              {t('telegramNotification.stopShowCard')}
            </label>
          </div>
        )}

        <div>
          {loadingAssign && (
            <S.Loading
              animationData={loading}
              style={{ width: 120, height: 50 }}
            />
          )}
          <S.Button
            data-error-message={t('telegramNotification.errorTryAgain')}
            disabled={loadingAssign}
            className={feedbackMessage.type}
            onClick={() =>
              feedbackMessage.type === 'error'
                ? setFeedbackMessage({ text: '', type: '' })
                : joinTelegram()
            }
          >
            {feedbackMessage.text.length ? feedbackMessage.text : labels.join}
          </S.Button>
        </div>

        {variant === 'default' && (
          <a
            href="#"
            disabled={loadingAssign}
            onClick={() => (loadingAssign ? null : setHidden(true))}
          >
            {t('telegramNotification.closeThisMessage')}
          </a>
        )}
      </S.Body>
    </S.Wrapper>
  );
};

TelegramNotification.propTypes = {
  variant: PropTypes.string,
  isUpdateMode: PropTypes.boolean,
};

TelegramNotification.defaultProps = {
  variant: 'default',
  isUpdateMode: false,
};

export const TelegramNotificationModal = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const clickHandler = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const meInfo = useReactiveVar(meVar);

  return (
    <>
      <S.Bell onClick={clickHandler} />

      {isOpen ? (
        <Modal
          top={'260px'}
          isShowing={true}
          handleClose={() => clickHandler()}
          title={t('telegramNotification.modalTitle')}
        >
          <div style={{ minWidth: 300 }}>
            <TelegramNotification
              variant="modal"
              isUpdateMode={meInfo?.hasTelegram || false}
            />
          </div>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default TelegramNotification;
