import { useSurviveAuth as partnerAuth } from './partner/useSurviveAuth';
import { useSurviveAuth as keycloakAuth } from './keyCloak/useSurviveAuth';

let useSurviveAuth = keycloakAuth;

if (process.env.REACT_APP_PARTNER_ENABLE == `true`) {
  useSurviveAuth = partnerAuth;
}

export { useSurviveAuth };
