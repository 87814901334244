/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../config/theme';

import { partnerInfoVar } from '../Apollo/vars';
import { useReactiveVar } from '@apollo/client';
import { ThemeProvider } from 'styled-components';

const Theme = ({ children }) => {
  const partnerInfo = useReactiveVar(partnerInfoVar);

  const themeColor = 'light';

  return (
    <ThemeProvider
      theme={
        theme[partnerInfo.host]
          ? theme[partnerInfo.host][themeColor]
          : theme.default.light
      }
    >
      {children}
    </ThemeProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Theme;
