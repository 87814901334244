import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import DrawerDesk from '../Drawer/DrawerDesk';
import DrawerMobile from '../Drawer/DrawerMobile';
import BottomBar from '../BottomBar/BottomBar';
import FirebaseNotification from '../FirebaseNotification/FirebaseNotification';

import { Wrapper, Content, Main } from './style';
import { useReactiveVar } from '@apollo/client';
import { partnerInfoVar } from '../../Apollo/vars';
import InitialInfo from '../InitialInfo/InitialInfo';

const showModal = ['apostala.survive.bet', 'dev-mambo.iframe.survive.bet'];

const Layout = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const partnerInfo = useReactiveVar(partnerInfoVar);

  const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

  const handleOpenDrawer = (event) => {
    if (event) event.stopPropagation();
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      {!compactMode && (
        <DrawerMobile open={openDrawer} handleOpenDrawer={handleOpenDrawer} />
      )}
      <FirebaseNotification />
      <Wrapper>
        <NavBar handleOpenDrawer={handleOpenDrawer} openDrawer={openDrawer} />

        <Main>
          {!compactMode && <DrawerDesk />}
          <Content>
            {children}
            {showModal.includes(partnerInfo.host) && <InitialInfo />}

            <BottomBar />
          </Content>
        </Main>
      </Wrapper>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default memo(Layout);
