import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

export const Background = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(#313a4a, #242d3c);
  ${({ bg }) => bg && `background: ${bg};`};
  width: 100%;
  width: ${({ width }) => width && width}px;
  height: 48px;
  padding-top: 4px;
  margin-bottom: 5px;
  ${({ border }) => border && `border-radius: ${border}px`}
  border-radius: 3px;
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
  font-family: Gorga Grotesque Bold;
  text-align: center;
  color: ${({ confirm }) => (confirm ? '#313a4a' : '#ffff')};
  font-size: 16px;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
`;

export const WrapperDescription = styled.div`
  display: flex;
  align-items: center;
`;

export const Description = styled.h1`
  font-family: Gorga Grotesque Light;
  color: ${({ confirm }) => (confirm ? '#313a4a' : ' #7e889a')};
  text-align: center;
  font-size: 9px;
  padding-top: 2px;
  padding-left: 2px;
`;

export const Wrapper = styled.div`
  cursor: pointer;

  ${({ disabled, loading }) => {
    if (disabled || loading) {
      return css`
        cursor: not-allowed;

        h1 {
          color: #6f757f;
        }

        > div {
          padding-top: 0;
        }
      `;
    }
  }}

  ${({ loading }) => {
    if (loading) {
      return css`
        opacity: 0.7;

        > div {
          padding-bottom: 6px;
        }
      `;
    }
  }}

${({ feedbackMessage }) => {
    if (feedbackMessage) {
      return css`
        > div {
          padding-top: 4px;
          background: linear-gradient(#731819b5, #5d0a0fa6);
        }

        opacity: 1;

        h1 {
          color: #ef5d5f !important;
          font-family: Gorga Grotesque Light !important;
          font-size: 15px;
          text-shadow: none;
          text-transform: uppercase;
        }
      `;
    }
  }}

${({ variant, confirm }) => {
    if (variant === 'vip') {
      return css`
        ${Background} {
          background: linear-gradient(#ffd04f, #de9a00);
        }

        ${Title} {
          color: ${confirm ? '#313a4a' : '#875800'};
        }

        ${Description} {
          color: #654200;
        }
      `;
    }
  }}
`;
