import { gql } from '@apollo/client';

export const GET_PARTNER_INFO = gql`
  query PartnerInfo {
    partnerInfo {
      name
      host
      currency
      language
      isTelegramEnabled
      urlApi
    }
  }
`;
