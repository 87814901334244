import { gql } from '@apollo/client';

export const GET_WALLET = gql`
  query GetWallet($formatFormat: String!) {
    appWallet {
      balance {
        format
        int
        float
      }
      transactions {
        balance {
          int
          float
        }
        amount {
          int
          float
        }
        operation
        type
        description
        id
        createdAt {
          format(format: $formatFormat)
        }
      }
    }
  }
`;

export const SUB_WALLET = gql`
  subscription SubWallet($formatFormat: String!) {
    appWalletOnChange {
      balance {
        format
        int
      }
      transactions {
        balance {
          int
        }
        amount {
          int
        }
        operation
        type
        description
        id
        createdAt {
          format(format: $formatFormat)
        }
      }
    }
  }
`;
