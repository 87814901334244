/* eslint-disable no-unused-vars */
import React from 'react';

import { useTranslation } from 'react-i18next';

import SysGaming from '../../../components/Svg/SysGaming';
import InternationalBettingLogo from '../../../components/Svg/InternationalBettingLogo';
import Gt from '../../../components/Svg/Gt';
import MinYear from '../../../components/Svg/MinYear';

import { Logos, Wrapper, Info, GroupLogos } from './style';
import { useLocation } from 'react-router-dom';
import { isBlankPageRender } from '../../../config/app';

const Footer = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const hidden = isBlankPageRender(location.pathname);

  if (hidden) {
    return <></>;
  }

  return (
    <Wrapper>
      <Logos>
        <GroupLogos>
          <SysGaming />
        </GroupLogos>

        <GroupLogos>
          <InternationalBettingLogo />
        </GroupLogos>

        <GroupLogos>
          <Gt />
          <MinYear />
        </GroupLogos>
      </Logos>
      {/* <Info>{t('footer.text')}</Info> */}
    </Wrapper>
  );
};

export default Footer;
