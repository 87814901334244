import styled from 'styled-components';

import { animated } from 'react-spring';

export const Wrapper = styled.div``;

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #272f3c 0%, #2a3240 30%, #212733 100%);
  box-shadow: 0px 4px 5px 1px #191e27;
  padding: 10px;
  margin-top: ${({ marginTop }) => (marginTop ? '20px' : '5px')};
  border-radius: 4px;
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  padding: 10px 0;
`;

export const DateStyle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  top: -5px;
  cursor: default;
`;

export const Day = styled.h1`
  font-family: Gorga Grotesque Regular;
  color: #7e889a;
  color: ${({ finalized }) => finalized && '#384355'};
  font-size: 18px;

  ${({ canceled }) => {
    if (canceled) return `text-decoration: line-through; opacity: 0.6;`;
  }}
`;

export const BadgeHours = styled.div`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
  background: ${({ finalized }) => finalized && '#384355'};
  padding: 2px 0 0;
  border-radius: 3px;
  width: 50px;
`;

export const Hours = styled.h1`
  font-family: Gorga Grotesque Regular;

  text-align: center;
  color: #212935;
  font-size: 12px;

  ${({ canceled }) => {
    if (canceled) return `text-decoration: line-through; opacity: 0.6;`;
  }}
`;

export const Teams = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 5px;
  cursor: default;
`;

export const Home = styled.h1`
  font-family: Gorga Grotesque Regular;

  text-align: left;
  color: #ffffff;
  font-size: 12px;
  padding-bottom: 2px;

  ${({ canceled }) => {
    if (canceled) return `text-decoration: line-through; opacity: 0.6;`;
  }}
`;

export const DescriptionEvent = styled.h1`
  font-family: Gorga Grotesque Light;
  margin-top: 2px;

  text-align: left;
  color: #7e889a;
  font-size: 10px;

  ${({ canceled }) => {
    if (canceled) return `text-decoration: line-through; opacity: 0.6;`;
  }}
`;

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2px 0px;
`;

export const MessageLoss = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const Title = styled.div`
  font-family: Gorga Grotesque Regular;
  color: #ffffff;

  font-size: ${({ size }) => (size ? size : 14)}px;
  margin: 5px 0px;
`;

export const Description = styled.h1`
  font-family: Gorga Grotesque light;
  color: #7e889a;

  font-size: 10px;
  text-align: center;
  margin-top: 10px;
`;

export const ButtonChallenge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  text-align: center;
  font-family: Gorga Grotesque Regular;
  font-size: 10px;
  color: #00000090;
  background: #9ff191;
  border-radius: 30px;
  margin-top: 10px;
  text-transform: uppercase;
  padding: 7px;
`;

export const MessageWin = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoSurvives = styled(animated.div)`
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  padding: 5px 0px;
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const SurvivesDescription = styled.h1`
  width: 150px;

  word-wrap: break-word;
  font-family: Gorga Grotesque Light;
  color: #c3d0dd;

  font-size: 11px;
  text-align: left;
  line-height: 16px;
  margin-top: 5px;
`;

export const CountUsers = styled.p`
  color: #ffff;
  display: inline-block;
  margin-right: 2px;
  font-size: 12px;
`;

export const SubTitle = styled.h2`
  font-family: Gorga Grotesque Light;
  font-size: 12px;
  text-align: center;
  color: #fafafa;
  line-height: 16px;
`;

export const TextBold = styled.h2`
  display: inline;
  font-family: Gorga Grotesque Regular;

  font-size: 14px;
`;

export const CircleIcon = styled.div`
  background: ${({ theme }) => theme.primary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;
