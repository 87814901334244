import styled from 'styled-components';

import { Star } from 'react-feather';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: ${({ bgColor }) => (bgColor ? bgColor : '#878ea0')};
  color: #272f3c;

  padding: 3px 5px 1px 5px;
  border-radius: 2px;
  max-height: 15px;
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft}px;
`;

export const Text = styled.h1`
  text-transform: uppercase;
  font-family: Gorga Grotesque Regular;

  font-size: ${({ fontSize }) => (fontSize ? fontSize : '10')}px;
`;

export const Icon = styled(Star)`
  margin-right: 2px;
  margin-bottom: 2px;
  width: 12px;
  height: 12px;
`;
