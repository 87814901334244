/* eslint-disable react/prop-types */
import React from 'react';
import { useChallenge } from '../../../../../pages/ChallengeDetails/Context';
import { useNumberFormat } from '../../../../../hooks/numberFormat';
import { Trans } from 'react-i18next';

const Survive = ({ typePrize, components }) => {
  const { currencySymbol, moneyIntl } = useNumberFormat();

  const {
    challenge: { resultPrize, otherPrizeDescriptionSingular, prizePool },
  } = useChallenge();

  const values = {
    currency: currencySymbol(),
    resultPrizeWinners: resultPrize.winners,
    otherPrizeDescriptionSingular,
    totalPrize: moneyIntl(resultPrize.totalPrize.float),
    unitPrizeSurvive: moneyIntl(resultPrize.unitPrizeSurvive.float),
    prizePool,
  };

  const options = { components, count: resultPrize.winners, values };

  const i18nKey =
    typePrize === 'Other' ? 'endGame.survive.other' : 'endGame.survive';

  return <Trans {...{ i18nKey, ...options }} />;
};

export default Survive;
