import React, { useState, useEffect } from 'react';

import Loading from '../../components/Shared/Loading/Loading';
import WalletCard from '../../components/Shared/WalletCard/WalletCard';

import { useQuery } from '@apollo/client';
import { USER_ME } from '../../queries/user';
import { GET_WALLET } from '../../queries/wallet';
import { Check, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from '../../hooks/numberFormat';

import {
  Wrapper,
  Header,
  Avatar,
  Content,
  Value,
  List,
  Data,
  LinearGradient,
  Balance,
  GroupActions,
  ButtonAction,
  ButtonText,
  TextData,
  TextValue,
  UserIcon,
} from './style';
import { useSurviveAuth } from '../../components/SurviveAuth';

const emailVerified = {
  true: 'Verificado',
  false: 'Não Verificado',
};

const Profile = () => {
  const { currency } = useNumberFormat();
  const [loading, setLoading] = useState(true);

  const { data: userMe, loading: loadingUserMe } = useQuery(USER_ME);

  const { data: wallet, loading: loadingWallet } = useQuery(GET_WALLET, {
    variables: {
      formatFormat: 'DD/MM/YYYY HH:SS',
    },
  });

  const { t } = useTranslation();
  const { realm, clientId, mode } = useSurviveAuth();

  useEffect(() => {
    if (loadingUserMe || loadingWallet) return;

    setLoading(false);
  }, [loadingUserMe, loading, loadingWallet]);

  return (
    <Wrapper>
      {loading && <Loading />}

      {!loading && (
        <>
          <Header>
            <Avatar>
              <UserIcon />
            </Avatar>

            <Balance>
              {wallet &&
                currency(wallet.appWallet.balance.int, { short: false })}
            </Balance>
          </Header>

          {mode === 'keycloak' && (
            <GroupActions>
              <ButtonAction
                onClick={() =>
                  // eslint-disable-next-line no-undef
                  (window.location = `https://account.${realm}.bet/auth/realms/${realm}/account?referrer=${clientId}`)
                }
              >
                <ButtonText>{t('profile.button')}</ButtonText>
              </ButtonAction>
            </GroupActions>
          )}

          <Content>
            {userMe?.userMe.name && (
              <List>
                <Value>
                  <TextValue>{t('profile.name')}</TextValue>
                </Value>
                <Data>
                  <TextData>{userMe.userMe.name}</TextData>
                </Data>
              </List>
            )}

            <List>
              <Value>
                <TextValue>{t('profile.email')}</TextValue>
              </Value>
              <Data>
                <TextData>
                  {userMe?.userMe.email_verified ? (
                    <Check color="#b7f96a" size={12} />
                  ) : (
                    <X color="#EF5D5F" size={12} />
                  )}

                  {emailVerified[userMe.userMe.email_verified]}
                </TextData>
              </Data>
            </List>

            <List>
              <Value>
                <TextValue>{t('profile.username')}</TextValue>
              </Value>
              <Data>
                {/* <div className='userme'>{userMe.userMe.username}</div> */}
                <TextData>{userMe.userMe.username}</TextData>
              </Data>
            </List>
          </Content>
          <LinearGradient />

          <WalletCard data={wallet.appWallet.transactions} />
        </>
      )}
    </Wrapper>
  );
};

export default Profile;
