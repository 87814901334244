import React from 'react';

import brokenPageError from './../../assets/brokenPageError.json';

import Lottie from 'lottie-react';

import { useLocation } from 'react-router-dom';
import { Wrapper, Icon, Content, Title } from './style';

const BrokenPage = () => {
  const location = useLocation();

  const errorMessage = location.state?.error;

  return (
    <Wrapper>
      <Content>
        <Title>{errorMessage}</Title>
      </Content>

      <Icon>
        <Lottie
          loop={true}
          animationData={brokenPageError}
          style={{ width: '100%' }}
        />
      </Icon>
    </Wrapper>
  );
};

export default BrokenPage;
