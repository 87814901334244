import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Text, Aling } from './style';

const TextLink = ({ value, icon }) => {
  return (
    <Wrapper>
      {icon && icon}

      <Aling>
        <Text>{value}</Text>
      </Aling>
    </Wrapper>
  );
};

TextLink.propTypes = {
  value: PropTypes.string,
  icon: PropTypes.node,
};

export default TextLink;
