import * as React from 'react';

const Skull = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
      fill="#6c7a92"
    >
      <path d="M296.606 364.393l-29.999-30c-5.857-5.858-15.355-5.858-21.213 0l-30 30C205.985 373.801 212.646 390 226 390h60c13.304 0 20.05-16.164 10.606-25.607zM165.999 179.997c-41.355 0-75.001 33.645-75.001 75.001 0 41.355 33.645 75.001 75.001 75.001S241 296.354 241 254.997c0-41.355-33.646-75-75.001-75zm0 120.201c-24.813 0-45.2-20.387-45.2-45.2s20.387-45.2 45.2-45.2 45.2 20.387 45.2 45.2-20.387 45.2-45.2 45.2zM346.001 179.997c-41.355 0-75.001 33.645-75.001 75.001 0 41.355 33.645 75.001 75.001 75.001 41.355 0 75.001-33.645 75.001-75.001s-33.646-75.001-75.001-75.001zm0 120.201c-24.813 0-45.2-20.387-45.2-45.2s20.387-45.2 45.2-45.2 45.201 20.387 45.201 45.2-20.388 45.2-45.201 45.2z" />
      <path d="M476.108 270.988c15.607-74.732-7.02-151.096-61.007-205.086v-.001C372.602 23.404 316.099 0 256 0 195.901 0 139.398 23.404 96.898 65.902c-53.869 53.87-76.716 130.182-61.007 205.091-6.143 17.917-6.485 37.065-.951 55.682 9.404 31.617 35.56 54.97 68.461 61.251 3.795 1.08 4.018.017 11.46 1.242 2.047.337 4.001.621 6.139.763v58.023c0 18.193 10.705 34.432 27.272 41.369 24.699 10.343 63.434 22.671 107.706 22.675l.023.001.023-.001c44.269-.004 83.006-12.333 107.709-22.676 16.565-6.938 27.269-23.176 27.269-41.367V389.86c2.077-.139 4.014-.403 6.046-.714 7.929-1.213 8.245-.09 12.239-1.437 32.914-6.607 58.868-30.138 68.004-61.833 5.293-18.356 4.871-37.23-1.183-54.888zm-27.643 46.58c-6.117 21.222-23.856 36.907-46.311 40.962-12.258.374-10.552 2.829-23.167.267-9.289-1.887-17.985 5.224-17.985 14.7v74.458c0 6.066-3.477 11.442-8.856 13.696-5.484 2.296-12.864 4.862-21.343 7.605v-32.457c0-8.284-6.516-14.8-14.8-14.8s-15 6.716-15 15v40.324c-9.389 1.89-19.669 3.176-30.2 3.93V436.8c0-8.284-6.516-14.8-14.8-14.8s-15 6.716-15 15v44.453c-10.531-.753-20.811-2.44-30.2-4.329V436.8c0-8.284-6.516-14.8-14.8-14.8s-15 6.716-15 15v32.457a271.4 271.4 0 01-21.141-7.805c-5.382-2.254-9.059-7.83-9.059-13.897l.002-74.45c0-9.462-8.482-16.39-17.784-14.501-13.377 2.716-10.435.035-23.659-.285-21.986-4.08-39.448-19.506-45.661-40.396-4.049-13.621-3.459-27.605 1.707-40.441a15.001 15.001 0 00.705-8.954c-15.458-67.379 5.15-134.755 52.005-181.612C154.945 50.284 203.914 29.8 256 29.8s101.055 20.483 137.887 57.314c47.708 47.711 67.151 115.603 52.006 181.611a15 15 0 00.705 8.954c5.095 12.66 5.74 26.453 1.867 39.889z" />
    </svg>
  );
};

export default Skull;
