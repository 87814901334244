import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

let statusChallenge = [
  {
    value: 'Sale',
    style: { default: { color: '#154e1f', background: '#6bff84' } },
    label: {
      default: 'status.challengeStatus.Sale',
      countdown: 'status.challengeStatus.Sale.countdown',
    },
  },
  {
    value: 'InPlay',
    style: { default: { color: '#1c5044', background: '#63e4c6' } },
    label: {
      default: 'status.challengeStatus.InPlay',
      detail: 'status.challengeStatus.InPlay.detail',
    },
  },
  {
    value: 'Finished',
    style: { default: { color: '#878ea0', background: '#444d60' } },
    label: {
      default: 'status.challengeStatus.Finished',
      detail: 'status.challengeStatus.Finished.detail',
    },
  },
  {
    value: 'FinishedForced',
    style: { default: { color: '#878ea0', background: '#444d60' } },
    label: { default: 'status.challengeStatus.FinishedForced' },
  },
  {
    value: 'Canceled',
    style: { default: { color: '#400b10', background: '#bb8900' } },
    label: {
      default: 'status.challengeStatus.Canceled',
      detail: 'status.challengeStatus.Canceled.detail',
    },
  },
  {
    value: 'Interrupted',
    style: { default: { color: '#400b10', background: '#bb8900' } },
    label: {
      default: 'status.challengeStatus.Interrupted',
      detail: 'status.challengeStatus.Interrupted.detail',
    },
  },
];

let myResultStatus = [
  {
    value: 'None',
    style: { default: { color: '#878ea0', background: '#444d60' } },
    label: { default: 'status.myResult.None' },
  },
  {
    value: 'WaitingNewRound',
    style: { default: { color: '#1e4a54', background: '#63cce4' } },
    label: {
      default: 'status.myResult.WaitingNewRound',
      countdown: 'status.myResult.WaitingNewRound.countdown',
      detail: 'status.myResult.WaitingNewRound.detail',
    },
  },
  {
    value: 'PendingBet',
    style: { default: { color: '#4e451b', background: '#ffe66d' } },
    label: {
      default: 'status.myResult.PendingBet',
      countdown: 'status.myResult.PendingBet.countdown',
    },
  },
  {
    value: 'WaitingResult',
    style: { default: { color: '#1c5044', background: '#63e4c6' } },
    label: {
      default: 'status.myResult.WaitingResult',
    },
  },
  {
    value: 'Eliminated',
    style: {
      default: { color: '#400b10', background: '#ff4757' },
      awarded: { color: '#154e1f', background: '#6bff84' },
      voidAwarded: { color: '#154e1f', background: '#6bff84' },
    },
    label: {
      default: 'status.myResult.Eliminated',
      detail: 'status.myResult.Eliminated.detail',
      awarded: 'status.myResult.Eliminated.awarded',
      voidAwarded: 'status.myResult.Eliminated.void.awarded',
      voided: 'status.myResult.Eliminated.voided',
    },
  },
  {
    value: 'Survive',
    style: { default: { color: '#154e1f', background: '#6bff84' } },
    label: {
      default: 'status.myResult.Survive',
      detail: 'status.myResult.Survive.detail',
    },
  },
  {
    value: 'Interrupted',
    style: { default: { color: '#400b10', background: '#bb8900' } },
    label: {
      default: 'status.myResult.Interrupted',
      detail: 'status.myResult.Survive.detail',
    },
  },
  {
    value: 'Voided',
    style: { default: { color: '#878ea0', background: '#444d60' } },
    label: {
      default: 'status.myResult.Voided',
    },
  },
  {
    value: 'Awarded',
    style: { default: { color: '#1c5044', background: '#a0ffe0' } },
    label: { default: 'status.myResult.Awarded' },
  },
  {
    value: 'Finished',
    style: { default: { color: '#878ea0', background: '#444d60' } },
    label: { default: 'status.myResult.None' },
  },
];

export const useEnums = () => {
  const { t } = useTranslation();
  const challengeEnum = (status, variant = 'default') => {
    if (!status)
      return { style: { color: null, background: null }, label: null };
    const en = statusChallenge.find((c) => c.value === status);
    return {
      style: en.style[variant] || en.style['default'],
      label: t(en.label[variant]) || t(en.label['default']),
    };
  };

  const challengeLabel = (status, variant = 'default') =>
    challengeEnum(status, variant).label;

  const challengeStyle = (status, variant = 'default') =>
    challengeEnum(status, variant).style;

  const myResultEnum = (status, variant = 'default') => {
    if (!status)
      return { style: { color: null, background: null }, label: null };

    const en = myResultStatus.find((m) => m.value === status);
    return {
      style: en.style[variant] || en.style['default'],
      label: t(en.label[variant]) || t(en.label['default']),
    };
  };

  const myResultLabel = (status, variant = 'default') =>
    myResultEnum(status, variant).label;
  const myResultStyle = (status, variant = 'default') =>
    myResultEnum(status, variant).style;

  return {
    myResultEnum,
    challengeEnum,
    challengeLabel,
    challengeStyle,
    myResultLabel,
    myResultStyle,
  };
};
