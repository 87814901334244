import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import * as S from './style';
import { useQuery, useReactiveVar } from '@apollo/client';
import Loading from '../Loading/Loading';
import { listenToChallenges } from '../../AppSub/AppSub';
import EmptyData from '../EmptyData/EmptyData';
import { useEnums } from '../../../hooks/useEnum';
import { challengeTabsState } from '../../Apollo/vars';

const ChallengeTabs = ({
  renderItem,
  tabs,
  filter,
  status,
  empty,
  query,
  keyName,
  fallbackEmptyTabWhenLoad,
}) => {
  const tabState = useReactiveVar(challengeTabsState);

  const [active, setActive] = useState(() => {
    return tabState[keyName] || 0;
  });
  const { challengeEnum, myResultEnum } = useEnums();

  const { data, loading, subscribeToMore } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      formatDate: 'DD',
      formatHour: 'HH:mm',
      appChallengeFilter: filter[active],
    },
  });

  useEffect(() => {
    const sub = listenToChallenges(subscribeToMore, keyName, active, status);
    return () => {
      sub && sub();
    };
  }, [keyName, active, status]);

  useEffect(() => {
    if (!loading && data && data[keyName]) {
      if (!data[keyName].length && !(keyName in tabState)) {
        changeTab(fallbackEmptyTabWhenLoad);
      }
    }
  }, [data, keyName, loading]);

  let challenges = [];

  const changeTab = useCallback((index) => {
    challengeTabsState({ ...tabState, [keyName]: index });
    setActive(index);
  }, []);

  if (data && keyName in data) {
    challenges = data[keyName];
  }

  const renderBody = () => {
    if (loading) {
      return <Loading />;
    }

    if (challenges.length === 0) {
      return <EmptyData title={empty[active]} />;
    }

    return (
      <>
        {challenges.map((challenge) =>
          renderItem({
            currentTab: active,
            challenge: { ...challenge },
            status: challengeEnum(challenge.status),
            myResult: myResultEnum(challenge.myResult),
          })
        )}
      </>
    );
  };

  return (
    <S.Wrapper>
      <S.Header>
        {tabs.map((tabName, index) => (
          <S.WrapperTab key={tabName}>
            <S.TabLink
              active={tabName === active}
              onClick={() => changeTab(index)}
            >
              {tabName}
            </S.TabLink>
          </S.WrapperTab>
        ))}
        <S.ActiveBar style={{ left: active * 140, bottom: 0 }} />
      </S.Header>
      <S.LineGradientHeader />
      <S.Body>{renderBody()}</S.Body>
    </S.Wrapper>
  );
};

ChallengeTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  empty: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderItem: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  keyName: PropTypes.string.isRequired,
  fallbackEmptyTabWhenLoad: PropTypes.number,
  filter: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

ChallengeTabs.defaultProps = {
  fallbackEmptyTabWhenLoad: 0,
  status: null,
  filter: null,
};

export default ChallengeTabs;
