import { createGlobalStyle } from 'styled-components';

import './loadFonts.css';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        /* background: linear-gradient(#222A36, #1E242E); */
        background: linear-gradient(#212831,#171c25);
        background-repeat: no-repeat;
        background-attachment: fixed;
    }
`;
