/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { Menu, X, LogIn } from 'react-feather';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Avatar from '../Avatar/Avatar';

import Logo from '../../Svg/LogoWhite';
import TextLink from '../TextLink/TextLink';

import * as S from './style';
import { useSurviveAuth } from '../../SurviveAuth';
import { isBlankPageRender } from '../../../config/app';
import { TelegramNotificationModal } from '../TelegramNotification/TelegramNotification';
import { partnerInfoVar } from '../../Apollo/vars';
import { useReactiveVar } from '@apollo/client';

const NavBar = ({ handleOpenDrawer, openDrawer }) => {
  const location = useLocation();

  const partnerInfo = useReactiveVar(partnerInfoVar);

  const hidden = isBlankPageRender(location.pathname);
  const { isAuthenticated, login, initialized } = useSurviveAuth();

  const { t } = useTranslation();

  const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

  return (
    <S.Wrapper hidden={hidden}>
      {!compactMode && (
        <S.OnClick onClick={handleOpenDrawer} hideDesktop>
          {!openDrawer ? <Menu color="#fff" size="20" /> : <X color="#fff" />}
        </S.OnClick>
      )}
      <S.WrapperLogo to="/">
        <Logo />
      </S.WrapperLogo>
      <S.Group
        onClick={() => {
          if (!isAuthenticated()) return login();
        }}
      >
        {isAuthenticated() &&
          initialized &&
          compactMode &&
          partnerInfo.isTelegramEnabled && (
            <div>
              <TelegramNotificationModal />
            </div>
          )}
        {isAuthenticated() && initialized && !compactMode && <Avatar />}

        {!isAuthenticated() && initialized && !compactMode && (
          <TextLink
            value={t('navbar.button.join')}
            icon={<LogIn color="#fff" size={13} style={{ marginRight: 5 }} />}
          />
        )}
      </S.Group>
    </S.Wrapper>
  );
};

NavBar.propTypes = {
  handleOpenDrawer: PropTypes.func,
  openDrawer: PropTypes.bool,
};

export default NavBar;
