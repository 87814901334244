import styled from 'styled-components';

import { User as UserProfile } from 'react-feather';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
  user-select: none;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  border: 1px solid #c0cce2;
  margin: 5px;
`;

export const UserIcon = styled(UserProfile)`
  width: 15px;
  height: 15px;
  margin: 2px;
  color: #c0cce2;
`;

export const Balance = styled.h1`
  font-family: Gorga Grotesque Regular;
  font-size: 10px;
  color: ${({ theme }) => theme.primary};
`;

export const ContainPopover = styled.div`
  background: #1b212a;
  width: 140px;
  border-radius: 5px;
`;

export const ContainItem = styled.div`
  border-bottom: 1px solid #262c39;
  cursor: pointer;

  :hover {
    background: rgba(44, 53, 65, 0.5);
  }
`;

export const ContainTitle = styled.h1`
  font-family: Gorga Grotesque Light;
  color: #ffff;

  font-size: 12px;
  padding: 14px 10px 11px;
`;
