import React from 'react';

const ToPoint = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={174.137}
      height={193.034}
      viewBox="0 0 174.137 193.034"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} />
        </linearGradient>
      </defs>
      <g data-name="Grupo 12" transform="translate(-153.895 -474.43)">
        <path
          data-name="Caminho 40"
          d="M604.624 370.922A77.149 77.149 0 01578 429.306q-.578.5-1.165.99a77.1 77.1 0 01-28.157 14.959q-.64.185-1.285.357a77.151 77.151 0 01-14.706 2.453q-.685.047-1.374.083-2 .1-4.019.1a77.214 77.214 0 01-24.3-3.9q-.676-.223-1.346-.458a77.571 77.571 0 01-44.495-40.375q-.282-.6-.551-1.215a76.945 76.945 0 01-6.614-29.534 70.29 70.29 0 01-.021-1.382v-.468a77.03 77.03 0 017.019-32.233q.28-.611.571-1.217a77.434 77.434 0 0114.977-21.145q.551-.558 1.119-1.1a77.058 77.058 0 0153.644-21.634h.556c.507 0 1.012.012 1.515.026a77.017 77.017 0 0125.736 5.123q.634.245 1.261.5a76.581 76.581 0 019.732 4.776q.578.333 1.15.68a77.629 77.629 0 0117.269 14.206q.449.494.889 1a77.275 77.275 0 0114.015 23.072c.162.417.319.836.474 1.257a77.123 77.123 0 014.706 24.753c.012.512.021 1.024.022 1.539.002.135.002.268.002.403z"
          transform="translate(-281.111 198.588)"
          opacity={0.02}
          fill="url(#prefix__a)"
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Caminho 41"
          d="M272.251 483.832q-.625 1-1.293 2.009a94.872 94.872 0 01-17.823 20.213 87.184 87.184 0 01-56.063 20.563q-2.679 0-5.367-.166a92.755 92.755 0 01-18.833-3.172q.28-.611.571-1.217a85.506 85.506 0 0051.113-1.356c18.642-6.348 33.878-18.326 45.289-35.6q.589-.892 1.144-1.773.634.244 1.262.499z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 42"
          d="M315.783 528.82a68.03 68.03 0 00-26.28 12.847c-23.461 18.882-32.5 53.014-24.935 88.177q-.641.185-1.285.357-.989-4.589-1.61-9.2a107.446 107.446 0 01.291-31.563 86.865 86.865 0 019.224-27.6 67.641 67.641 0 0144.12-34.278c.162.422.319.839.475 1.26z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 43"
          d="M248.581 632.655q-.685.047-1.374.083c4.9-24.131-2.876-49.335-21.633-64.555-16.254-13.19-38.681-17.021-59.694-10.823a70.305 70.305 0 01-.021-1.381 71.006 71.006 0 0125.992-2.558 63.429 63.429 0 0134.563 13.725c12.023 9.759 20.067 24.044 22.667 40.224a71.787 71.787 0 01-.5 25.285z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 44"
          d="M218.883 628.943q-.676-.223-1.346-.458a53.4 53.4 0 00-7.412-26.285c-5.284-8.631-12.686-14.278-20.84-15.9a28.912 28.912 0 00-16.243 1.808q-.282-.6-.551-1.216a30.247 30.247 0 0117.056-1.9 29.177 29.177 0 0112.059 5.6 39.648 39.648 0 019.659 10.908 54.888 54.888 0 017.618 27.443z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 45"
          d="M320.511 555.107a63.038 63.038 0 00-10.582 7.847 51.781 51.781 0 00-16.039 50.938q-.578.5-1.165.99a53.288 53.288 0 01-.637-23.27 52.249 52.249 0 016.851-17.506 57.9 57.9 0 0110.086-12.136 63.636 63.636 0 0111.464-8.4c.012.51.02 1.023.022 1.537z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 46"
          d="M301.291 504.49c-15.231 14.884-26.945 25.5-34.87 31.593a47.937 47.937 0 01-8.644 5.628 7.007 7.007 0 01-2.723.726 2.185 2.185 0 01-1.453-.474c-1.171-.981-1.8-3.466 3.749-14.064 4.632-8.844 12.9-22.035 24.632-39.292q.578.333 1.15.68c-11.724 17.247-19.985 30.419-24.6 39.23-5.35 10.213-4.471 12.09-4.073 12.423s2.341.858 11.149-5.914c7.893-6.07 19.58-16.663 34.795-31.532q.45.494.888.996z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 47"
          d="M245.255 478.209q-1.167 2.05-2.511 3.982a47.192 47.192 0 01-39.734 20.535 61.6 61.6 0 01-14.591-1.811q.551-.558 1.119-1.1c9.45 2.2 24.706 3.437 38.837-5.56a47.113 47.113 0 0015.365-16.077c.507.008 1.012.016 1.515.031z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 48"
          d="M321.93 667.159H153.895c1.937-10.724 6.069-14.706 9.217-16.429 3.441-1.882 6.952-1.744 10.69-.691 3.608 1.015 7.428 2.882 11.605 4.606a63.582 63.582 0 006.794 2.427 32.882 32.882 0 0015.717.876c13.043-2.549 12.485-10.873 25.392-12.2 14.891-1.529 18.342 9.271 35.1 8.96 13.923-.257 17.28-7.819 28.378-6.472 11.486 1.398 19.836 10.947 25.142 18.923z"
          fill="#dadef2"
          opacity={0.05}
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <g data-name="Grupo 11">
          <path
            data-name="Caminho 60"
            d="M276.397 660.333l-12.763 2.6a1.8 1.8 0 00-1.422 1.771 1.788 1.788 0 001.769 1.806h18.147l-.365-6.115z"
            fill="#494e6d"
          />
          <path
            data-name="Caminho 61"
            d="M276.211 655.993h5.365v4.484a2.683 2.683 0 01-2.683 2.683 2.683 2.683 0 01-2.682-2.683v-4.484z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 62"
            d="M236.813 659.587l-12.569 3.381a1.775 1.775 0 00-1.3 1.853 1.809 1.809 0 001.9 1.691l18.134-1.12-.8-6.069z"
            fill="#494e6d"
          />
          <path
            data-name="Caminho 63"
            d="M238.922 662.474a2.743 2.743 0 01-1.975-3.308l1.022-4.3 5.214 1.292-1.022 4.3a2.68 2.68 0 01-3.239 2.016z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 64"
            d="M216.612 536.849s30.251 3.127 28.779 11.292c-1.66 9.209-31.052-6.617-31.052-6.617s-1.206-2.257 2.273-4.675z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 65"
            d="M261.556 529.931s-6.136 2.863-12.443 6.268c-6.992 3.775-14.145 8.31-12.311 12.344 4.039 8.882 29.407-10.306 29.407-10.306 3.491-3.601-.316-9.063-4.653-8.306z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 66"
            d="M218.49 537.072a6.457 6.457 0 00-1.4-2.923c-1.067-1.1-1.923-1.709-2.357-3.431s-2.282-.968-1.837.509 1.5 3.719-.5 3.645-5.109-4.061-6.321-1.189c0 0-1.839-1.441-2.391-.58s1.107 2.177 1.107 2.177-2.663.856 2.157 3.46c0 0-.889.966-.023 1.59 1.386 1 5.137 1.811 8.181 1.6 4.954-.345 3.384-4.858 3.384-4.858z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 67"
            d="M250.904 534.004a37.861 37.861 0 006.192 12.724l13.033-10.2-5.006-8.674z"
            fill="#1c5276"
          />
          <path
            data-name="Caminho 68"
            d="M211.524 536.713l.1-.275c-4.532-1.742-5.423-2.833-5.432-2.844l-.233.179c.04.046.917 1.157 5.565 2.94z"
            fill="#cb9583"
          />
          <path
            data-name="Caminho 69"
            d="M210.574 538.277l.082-.283a27.872 27.872 0 01-5.78-2.836l-.16.247a28.191 28.191 0 005.858 2.872z"
            fill="#cb9583"
          />
          <path
            data-name="Caminho 70"
            d="M210.364 539.933l.079-.283c-1.516-.424-3.425-1.042-3.444-1.048l-.091.28c.02.006 1.938.626 3.456 1.051z"
            fill="#cb9583"
          />
          <path
            data-name="Caminho 71"
            d="M249.068 620.814a7.581 7.581 0 01-5.245-8.915c3.593-16.6 7.791-32.907 11.531-45.083h16.714c-4.327 13.851-9.989 34.008-13.834 48.665a7.4 7.4 0 01-9.166 5.333z"
            fill="#68a3c1"
          />
          <path
            data-name="Caminho 72"
            d="M252.915 606.757a7.379 7.379 0 00-9.209 5.667c-3.035 14.326-7.168 32.835-9.031 42.044l12.091 3.454c2.34-10.43 7.934-29.071 11.3-41.961a7.562 7.562 0 00-5.151-9.204z"
            fill="#68a3c1"
          />
          <path
            data-name="Caminho 73"
            d="M277.189 621.079a7.479 7.479 0 01-7.4-7.143c-.918-16.976-1.323-34.365-.933-47.121h16.225c-.513 14.529-.639 31.435-.475 46.607a7.5 7.5 0 01-7.417 7.657z"
            fill="#68a3c1"
          />
          <path
            data-name="Caminho 74"
            d="M277.184 606.484a7.476 7.476 0 00-7.374 7.991c.856 14.631 2.078 32.705 2.714 42.086h12.553c-.5-10.688-.347-29.291-.508-42.63a7.462 7.462 0 00-7.385-7.447z"
            fill="#68a3c1"
          />
          <path
            data-name="Caminho 75"
            d="M255.342 566.817s.377-33.627 8.634-38.414c4.733-2.744 14.53-2 19.525 1.792 5.042 3.826 1.481 36.621 1.481 36.621z"
            fill="#1c5276"
          />
          <path
            data-name="Caminho 76"
            d="M275.564 534.729s1.415 6.735 3.313 13.758c2.1 7.786 4.9 15.9 9.152 14.936 9.363-2.114-3.521-31.545-3.521-31.545-2.69-4.257-8.737-1.638-8.944 2.851z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 77"
            d="M277.164 546.227a29.539 29.539 0 0013.5-3.434l-4.49-9.826a6.5 6.5 0 00-7.965-3.53l-5.325 1.774z"
            fill="#1c5276"
          />
          <path
            data-name="Caminho 78"
            d="M257.314 546.117a43.01 43.01 0 013.635-12.711l-.527-.262a43.644 43.644 0 00-3.693 12.911z"
            fill="#251138"
          />
          <path
            data-name="Ret\xE2ngulo 33"
            fill="#cb9583"
            d="M239.906 541.864l.292-.034.48 4.175-.293.034z"
          />
          <path
            data-name="Ret\xE2ngulo 34"
            fill="#251138"
            d="M276.072 539.149l.568-.152 2.524 9.413-.568.153z"
          />
          <path
            data-name="Caminho 79"
            d="M271.177 534.691c3.171 0 5.834-2.2 7.933-6.573l-.53-.255c-2.284 4.754-5.226 6.807-8.755 6.1-3.817-.764-4.7-5.968-4.7-6.02l-.581.093c.037.231.958 5.659 5.17 6.5a7.468 7.468 0 001.463.155z"
            fill="#251138"
          />
          <path
            data-name="Caminho 80"
            d="M277.592 543.978c4.314-.245 11.292-3.864 11.587-4.017l-.272-.521c-.071.037-7.161 3.714-11.348 3.952z"
            fill="#251138"
          />
          <path
            data-name="Caminho 81"
            d="M257.348 543.048l.471-.353c-2.02-2.7-4.473-9.61-4.5-9.68l-.555.2c.105.283 2.51 7.063 4.584 9.833z"
            fill="#251138"
          />
          <path
            data-name="Caminho 82"
            d="M261.804 584.995l12.1.488-1.433-10.734s-4.619-.163-5.734-.163-4.933 10.409-4.933 10.409z"
            fill="#68a3c1"
          />
          <path
            data-name="Caminho 83"
            d="M270.164 579.911l.16-.566-4.367-1.23 1.711-10.8-.581-.092-1.793 11.318z"
            fill="#30354f"
          />
          <path
            data-name="Ret\xE2ngulo 35"
            fill="#30354f"
            d="M279.844 567.208l.55-.21 4.46 11.71-.55.21z"
          />
          <path
            data-name="Ret\xE2ngulo 36"
            fill="#30354f"
            d="M251.231 579.886l7.65-13.008.508.298-7.651 13.008z"
          />
          <path
            data-name="Caminho 84"
            d="M235.684 649.074l-1.009 5.394 12.091 3.454 1.182-4.457z"
            fill="#494e6d"
          />
          <path
            data-name="Caminho 85"
            d="M271.996 651.513l.527 5.048h12.555l-.182-5.048z"
            fill="#494e6d"
          />
          <path
            data-name="Caminho 86"
            d="M265.724 573.64s18.674-23.216 24.878-17.454c7 6.5-22.083 21.933-22.083 21.933s-2.565-.262-2.795-4.479z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 87"
            d="M266.907 572.215a6.672 6.672 0 00-3.237-.476c-1.5.268-2.479.635-4.18.034s-2.035 1.344-.536 1.8 3.971.838 2.853 2.441-6.175 1.953-4.36 4.553c0 0-2.2.715-1.76 1.65s2.447.3 2.447.3-.677 2.674 4.1.147c0 0 .355 1.272 1.347.905 1.587-.588 4.267-3.229 5.7-5.859 2.317-4.277-2.374-5.495-2.374-5.495z"
            fill="#e2b3a3"
          />
          <path
            data-name="Caminho 88"
            d="M262.912 577.762l-.171-.239c-3.886 2.768-5.29 2.9-5.3 2.9l.017.293c.055-.007 1.467-.114 5.454-2.954z"
            fill="#e26847"
          />
          <path
            data-name="Caminho 89"
            d="M263.748 579.415l-.191-.224a26.646 26.646 0 01-5.481 3.187l.116.27a26.877 26.877 0 005.556-3.233z"
            fill="#e26847"
          />
          <path
            data-name="Caminho 90"
            d="M265.053 580.512l-.193-.222a94.282 94.282 0 01-2.718 2.257l.184.23c.015-.013 1.555-1.247 2.727-2.265z"
            fill="#e26847"
          />
          <path
            data-name="Ret\xE2ngulo 37"
            fill="#cb9583"
            d="M281.256 557.825l.062-.287 3.612.78-.062.288z"
          />
          <g data-name="Grupo 10" transform="translate(264.539 504.676)">
            <path
              data-name="Caminho 91"
              d="M6.249 27.867c-3.435 0-5-4.014-4.079-4.936a3.255 3.255 0 00.648-1 6.765 6.765 0 00.467-1.743l6.351-5.665c-.343 3.607 1.2 6.139 1.761 7.943s-1.241 5.401-5.148 5.401z"
              fill="#e2b3a3"
            />
            <path
              data-name="Caminho 92"
              d="M8.527 18.783a6.063 6.063 0 01-5.706 3.145 6.765 6.765 0 00.467-1.743c.174-.102 6.946-4.669 5.239-1.402z"
              fill="#cb9583"
            />
            <path
              data-name="Caminho 93"
              d="M15.924 8.035s-.273 3.292-5.06 6.684c-5.019 3.556.694-7.373.694-7.373z"
              fill="#533d3c"
            />
            <path
              data-name="Caminho 94"
              d="M2.857 6.057s-.678 2.837-1.556 6.021c-1.256 4.551-1.363 8.233 3.274 8.233 7.788 0 7.269-12.923 7.269-12.923z"
              fill="#e2b3a3"
            />
            <path
              data-name="Caminho 95"
              d="M12.582 10.845c1.227.5.375 3.622-2.3 3.928-2.057.236.415-4.701 2.3-3.928z"
              fill="#e2b3a3"
            />
            <circle
              data-name="Elipse 2"
              cx={0.544}
              cy={0.544}
              transform="translate(6.311 11.296)"
              fill="#533d3c"
              r={0.544}
            />
            <circle
              data-name="Elipse 3"
              cx={0.544}
              cy={0.544}
              transform="translate(2.466 10.361)"
              fill="#533d3c"
              r={0.544}
            />
            <path
              data-name="Caminho 96"
              d="M3.76 15.453a.733.733 0 01-.668-.379.624.624 0 01.373-.833.579.579 0 00.317-.58l.613-.085a1.2 1.2 0 01-.647 1.215.457.457 0 00-.056.033.288.288 0 00.2-.022l.255.564a.949.949 0 01-.387.087z"
              fill="#cb9583"
            />
            <path
              data-name="Caminho 97"
              d="M2.395 16.513l2.629.4s-.438 1.713-1.633 1.513-.996-1.913-.996-1.913z"
              fill="#fff"
            />
            <path
              data-name="Caminho 98"
              d="M8.418 10.945a1.556 1.556 0 00-1.247-.933l.032-.618a2.172 2.172 0 011.779 1.3z"
              fill="#533d3c"
            />
            <path
              data-name="Caminho 99"
              d="M4.167 9.424a2.342 2.342 0 00-1.688-.27l-.143-.6a2.952 2.952 0 012.189.367z"
              fill="#533d3c"
            />
            <path
              data-name="Caminho 100"
              d="M7.198 14.311a2.293 2.293 0 112.293-2.293 2.293 2.293 0 01-2.293 2.293zm0-4.276a1.984 1.984 0 101.984 1.976 1.984 1.984 0 00-1.984-1.984z"
              fill="#533d3c"
            />
            <path
              data-name="Caminho 101"
              d="M2.293 13.229a2.293 2.293 0 112.293-2.293 2.293 2.293 0 01-2.293 2.293zm0-4.276a1.984 1.984 0 101.983 1.984 1.984 1.984 0 00-1.983-1.984z"
              fill="#533d3c"
            />
            <path
              data-name="Ret\xE2ngulo 38"
              fill="#533d3c"
              d="M4.263 11.538l.085-.297.743.212-.085.297z"
            />
            <path
              data-name="Caminho 102"
              d="M2.547 7.813a10.981 10.981 0 009.3-.425l4.081.647a8.642 8.642 0 00-4-4.649c-2.87-1.302-10.293-.912-9.381 4.427z"
              fill="#533d3c"
            />
            <circle
              data-name="Elipse 4"
              cx={5.469}
              cy={5.469}
              transform="translate(11.793)"
              fill="#533d3c"
              r={5.469}
            />
          </g>
        </g>
        <path
          data-name="Caminho 103"
          d="M193.491 667.159h-7.065a196.4 196.4 0 01-12.623-17.12c-4.184-6.627-4.688-9.569-3.654-10.712 1.879-2.073 9.246 1.321 13.548 4.711a25.248 25.248 0 018.505 13.034 32.818 32.818 0 011.289 10.087z"
          fill="#383d4f"
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Caminho 104"
          d="M190.15 667.426a.262.262 0 01-.258-.214 37.635 37.635 0 00-6.767-15.487 36.949 36.949 0 00-7.954-7.807.262.262 0 01.307-.425 37.474 37.474 0 018.067 7.918 38.163 38.163 0 016.862 15.7.262.262 0 01-.209.306.241.241 0 01-.048.009z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 105"
          d="M199.846 667.244c13.4-38.75 13.466-50.053 9.993-51.88-3.755-1.975-12.481 6.692-16.844 13.759-10.362 16.786-2.925 35.595-1.9 38.076z"
          fill="#464b5b"
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Caminho 106"
          d="M195.961 666.418a.262.262 0 01-.258-.216 57.69 57.69 0 011.471-26.625 56.6 56.6 0 017.351-15.437.262.262 0 11.434.294 56.092 56.092 0 00-7.283 15.294 57.15 57.15 0 00-1.456 26.382.262.262 0 01-.212.3.274.274 0 01-.047.008z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 107"
          d="M317.163 667.202h-3.532a98.219 98.219 0 01-6.312-8.56c-2.092-3.314-2.344-4.784-1.827-5.356.94-1.036 4.623.661 6.774 2.356a12.63 12.63 0 014.253 6.517 16.408 16.408 0 01.644 5.043z"
          fill="#383d4f"
          opacity={0.999}
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Caminho 108"
          d="M315.494 667.464a.262.262 0 01-.258-.214 18.684 18.684 0 00-3.36-7.689 18.328 18.328 0 00-3.949-3.876.262.262 0 01.307-.425 18.857 18.857 0 014.062 3.987 19.211 19.211 0 013.455 7.906.262.262 0 01-.209.306.234.234 0 01-.048.005z"
          fill="#30354f"
        />
        <path
          data-name="Caminho 109"
          d="M320.341 667.244c6.7-19.375 6.733-25.027 5-25.94-1.877-.987-6.24 3.346-8.422 6.88-5.181 8.393-1.463 17.8-.949 19.038z"
          fill="#464b5b"
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Caminho 110"
          d="M318.398 666.963a.262.262 0 01-.258-.216 28.974 28.974 0 01.739-13.373 28.423 28.423 0 013.692-7.754.262.262 0 01.434.294 27.909 27.909 0 00-3.624 7.611 28.447 28.447 0 00-.725 13.13.262.262 0 01-.212.3.244.244 0 01-.046 0z"
          fill="#30354f"
        />
        <circle
          data-name="Elipse 5"
          cx={6.383}
          cy={6.383}
          transform="translate(315.267 557.535)"
          fill="#73e8b7"
          r={6.383}
        />
        <circle
          data-name="Elipse 6"
          cx={6.383}
          cy={6.383}
          transform="translate(253.046 474.43)"
          fill="#9ff191"
          r={6.383}
        />
        <path
          data-name="Caminho 111"
          d="M306.35 628.523a2.247 2.247 0 01-1.717-.8l-4.521-5.3a2.254 2.254 0 01-.542-1.5q0-.066.006-.131a2.294 2.294 0 01.962-1.7c.05-.035.1-.069.159-.1a2.28 2.28 0 01.729-.267l6.854-1.263a2.3 2.3 0 01.581-.033c.044 0 .089.008.133.013a2.267 2.267 0 011.83 3l-2.333 6.567a2.254 2.254 0 01-.511.826l-.1.1a2.277 2.277 0 01-1.456.584.472.472 0 01-.074.004zm-5.867-7.58a1.322 1.322 0 00.324.889l4.521 5.3a1.321 1.321 0 001.066.474 1.362 1.362 0 00.872-.348l.058-.056a1.344 1.344 0 00.305-.494l2.333-6.567a1.322 1.322 0 00-.117-1.153 1.346 1.346 0 00-.972-.64l-.079-.008a1.4 1.4 0 00-.351.02l-6.854 1.263a1.367 1.367 0 00-.438.16q-.048.028-.092.059a1.373 1.373 0 00-.574 1.018l-.002.083z"
          fill="#c7f86d"
        />
      </g>
    </svg>
  );
};

export default ToPoint;
