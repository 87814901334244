import React from 'react';

import Routes from '../Routes/Routes';
import Theme from '../Theme/Theme';
import Apollo from '../Apollo/Apollo';
import Globalstyle from '../Theme/GlobalStyle';

import { BrowserRouter } from 'react-router-dom';
import { Slide } from 'react-toastify';

import I18n from '../I18n/I18n';
import Layout from '../Shared/Layout/Layout';
import ApplicationLoading from './ApplicationLoading';

import '../../config/firebase';
import NetworkStatus from '../../components/Shared/NetworkStatus/NetworkStatus';
import { Errors } from '../Shared/Errors';

import { Toast } from './style';

const Compact = () => {
  return (
    <>
      <Globalstyle />
      <ApplicationLoading>
        <Apollo>
          <BrowserRouter>
            <Errors />

            <I18n>
              <Theme>
                <NetworkStatus />

                <Toast
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  transition={Slide}
                />

                <Layout>
                  <Routes />
                </Layout>
              </Theme>
            </I18n>
          </BrowserRouter>
        </Apollo>
      </ApplicationLoading>
    </>
  );
};

export default Compact;
