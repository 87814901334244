import { useState, useEffect } from 'react';
import { app } from '../../../config/app';
import { useHistory } from 'react-router-dom';

export const getPartnerToken = async (session) => {
  if (!session) {
    throw new Error(`Session must be provided`);
  }

  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(`${app.authPartner.endpoint}/${session}`);
    const json = await response.json();

    if (!response.ok) {
      throw new Error(json.message);
    }

    const { access_token } = json;
    return access_token || null;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const useSurviveAuth = () => {
  const history = useHistory();

  const login = () => {
    /* eslint-disable */
    localStorage.setItem(
      'survive@oldSession',
      localStorage.getItem('survive@session') || '--'
    );
    localStorage.removeItem('survive@session');
    history.push({ pathname: '/expiredsession' });
  };
  /* eslint-enable */

  const redirectToLogin = () => {
    // eslint-disable-next-line no-undef
    window.parent.location.href = '/signin?redirectTo=/survive';
  };

  const logout = () => {};

  const updateToken = (newToken) => {
    if (newToken) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('survive@session', newToken);
      setAuth({
        ...auth,
        authenticated: true,
        initialized: true,
        token: newToken,
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    updateToken(localStorage.getItem('survive@session'));
  }, []);

  const [auth, setAuth] = useState({
    authenticated: false,
    initialized: true,
    token: null,
    mode: 'authPartner',
    login,
    logout,
    redirectToLogin,
  });

  // eslint-disable-next-line no-undef
  const getToken = () => auth.token || localStorage.getItem('survive@session');
  const isAuthenticated = () => auth.authenticated;

  return {
    ...auth,
    updateToken,
    getToken,
    isAuthenticated,
  };
};
