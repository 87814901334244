import React from 'react';
import PropTypes from 'prop-types';

import { replaceColor } from 'lottie-colorify';

import emptyData from '../../../assets/emptyData.json';

import { Title, Wrapper, Lottie } from './style';
import { useTheme } from 'styled-components';

const EmptyData = ({ title }) => {
  const theme = useTheme();

  return (
    <Wrapper>
      <Title>{title}</Title>

      <Lottie
        animationData={replaceColor('#000000', theme.primary, emptyData)}
        loop={false}
      />
    </Wrapper>
  );
};

EmptyData.propTypes = {
  title: PropTypes.string,
};

EmptyData.defaultProps = {
  title: null,
};

export default EmptyData;
