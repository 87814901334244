import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSurviveAuth } from '../SurviveAuth';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();

  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  const { initialized, authenticated } = useSurviveAuth();

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }));
  }, [location]);

  if (!initialized) return <></>;

  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          return authenticated ? (
            <Component {...props} />
          ) : initialized ? (
            <Redirect to={route.to} />
          ) : (
            <Redirect to={'/'} />
          );
        }}
      />
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;
