import styled from 'styled-components';

export const Wrapper = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, #1a1f29 0%, #14181f 100%);
  border-top: 2px solid #1b2029;
  box-shadow: 0px -5px 4px rgb(0 0 0 / 15%);

  overflow: hidden;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;

  width: 100px;

  align-items: center;
  opacity: ${({ touched }) => touched && 0.5};

  color: ${({ activeRoute }) => (activeRoute ? '#ffff' : '#99a2b5')};

  :hover {
    color: #ffff;
  }
`;

export const Title = styled.h1`
  font-size: 9px;
  padding-top: 5px;

  text-align: center;
  font-family: Gorga Grotesque Light;
`;
