import { useKeycloak } from '@react-keycloak/web';
import { useState, useEffect } from 'react';

export const useSurviveAuth = () => {
  const { keycloak, initialized: keycloakInitialized } = useKeycloak();

  const login = () => {
    keycloak.login();
  };
  const logout = () => {
    keycloak.logout();
  };
  const redirectToLogin = () => {
    keycloak.login();
  };

  const authKeyCloak = () => {
    setAuth({
      ...auth,
      authenticated: keycloak.authenticated,
      initialized: keycloakInitialized,
      token: keycloak.token,
      realm: keycloak.realm,
      clientId: keycloak.clientId,
    });
  };

  useEffect(() => {
    authKeyCloak();
  }, [keycloakInitialized]);

  const [auth, setAuth] = useState({
    authenticated: false,
    initialized: false,
    token: null,
    realm: null,
    clientId: null,
    mode: 'keycloak',
    login,
    logout,
    redirectToLogin,
  });

  const getToken = () => keycloak.token;
  const isAuthenticated = () => auth.authenticated;

  return {
    ...auth,
    getToken,
    isAuthenticated,
  };
};
