import styled from 'styled-components';
import LottieDefault from 'lottie-react';

export const Lottie = styled(LottieDefault)`
  height: 50px;
  margin: 10px;
`;

export const Wrapper = styled.div`
  margin: 20px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  padding: 10px;
  font-size: 14px;

  color: #fafafa;

  text-align: center;
  font-family: Gorga Grotesque Light;
`;
