import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
`;

export const Group = styled.div`
  padding: 0px 10px;

  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  padding-top: 5px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  font-family: Gorga Grotesque Light;
`;

export const Content = styled.div`
  margin-top: 10px;
  padding: 10px 10px;

  background-color: #252c39;

  width: 100%;

  @media (max-width: 600px) {
    margin-top: 55px;
  }
`;

export const LineGradientHeader = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
`;
