/* eslint-disable react/prop-types */
import React from 'react';
import { Trans } from 'react-i18next';

const AllPlayersWrong = ({ myResult, isGuest, components }) => {
  const options = {
    components,
  };

  if (myResult === 'Eliminated' || isGuest)
    return <Trans i18nKey="congratulation.guest.allWrong" {...options} />;

  if (myResult === 'Awarded')
    return <Trans i18nKey="congratulation.allWrong" {...options} />;
};

export default AllPlayersWrong;
