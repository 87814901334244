import React, { useState } from 'react';
import PropTypes from 'prop-types';

import placeHolderUser from '../../../assets/Icons/placeholder-user.png';

import { useLayer, Arrow } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_WALLET } from '../../../queries/wallet';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useNumberFormat } from '../../../hooks/numberFormat';

import {
  Wrapper,
  Overlay,
  Balance,
  ContainPopover,
  ContainItem,
  ContainTitle,
  UserIcon,
} from './style';
import { useSurviveAuth } from '../../SurviveAuth';

const Avatar = () => {
  const [isOpen, setOpen] = useState(false);
  const { currency } = useNumberFormat();

  const { t } = useTranslation();
  const { logout } = useSurviveAuth();

  const { data } = useQuery(GET_WALLET, {
    variables: {
      formatFormat: 'DD/MM/YYYY',
    },
  });

  const theme = useContext(ThemeContext);
  const history = useHistory();

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: false,
    auto: true,
    placement: 'bottom-end',
    triggerOffset: 9,
    containerOffset: 9,
    arrowOffset: 8,
  });

  const customLayerStyle = {
    ref: layerProps.ref,
    style: {
      ...layerProps.style,
      zIndex: theme.zIndexScale.zIndexNavbarAvatarUserLayer,
    },
  };

  return (
    <Wrapper
      avatar={placeHolderUser}
      {...triggerProps}
      onClick={() => setOpen(!isOpen)}
    >
      <Overlay>
        <UserIcon />
      </Overlay>
      <Balance>
        {data?.appWallet &&
          currency(data.appWallet.balance.int, { short: false })}
      </Balance>

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.ul {...customLayerStyle}>
              <ContainPopover>
                <ContainItem onClick={() => history.push('/my-challenges')}>
                  <ContainTitle>
                    {t('avatar.contain.mychallenges').toUpperCase()}
                  </ContainTitle>
                </ContainItem>

                <ContainItem>
                  <ContainTitle onClick={() => history.push('/profile')}>
                    {t('avatar.contain.wallet').toUpperCase()}
                  </ContainTitle>
                </ContainItem>

                <ContainItem>
                  <ContainTitle onClick={() => logout()}>
                    {t('avatar.contain.logout').toUpperCase()}
                  </ContainTitle>
                </ContainItem>
              </ContainPopover>

              <Arrow {...arrowProps} backgroundColor="#1b212a" />
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </Wrapper>
  );
};

Avatar.propTypes = {
  balance: PropTypes.string,
};

export default Avatar;
