import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useNumberFormat } from '../../../hooks/numberFormat';
import { Trans, useTranslation } from 'react-i18next';
import { icons } from '../../../config/images';
import { useHistory } from 'react-router-dom';

import Badge from '../Badge/Badge';
import UsersCount from '../UsersCount/UsersCount';
import EliminatedCount from '../EliminatedCount/EliminatedCount';
import SurviveCount from '../SurviveCount/SurviveCount';
import BadgeInfo from '../BadgeInfo/BadgeInfo';
import CountDownMyResult from '../../Shared/CountDownMyResult/CountDownMyResult';

import * as S from './style';
import { useEnums } from '../../../hooks/useEnum';

const finishedChallengeEnums = [
  'Finished',
  'FinishedForced',
  'Canceled',
  'Interrupted',
];

const Card = ({
  challenge,
  status,
  statusStyle,
  hideIcon,
  playingHighlight,
  surviveHighlight,
  eliminatedHighlight,
}) => {
  const history = useHistory();

  const { t } = useTranslation();
  const { myResultStyle, myResultLabel } = useEnums();

  const { currencySymbol, separator, moneyIntl } = useNumberFormat();

  const handleOnClick = (slug) => {
    history.push(`/challenge/${slug}`);
  };

  const isVip = challenge.forGuests || challenge.allowVip;

  const resolveMyResult = () => {
    const { myRole, status, myResult, lastRoundStatus } = challenge;

    if (status === `Interrupted`) {
      return ``;
    }

    const isFinished = finishedChallengeEnums.includes(status);
    const isGuest = myRole === 'Guest';
    const wereEliminated = myResult === 'Eliminated';

    if (!isFinished && (isGuest || wereEliminated)) {
      return lastRoundStatus;
    }

    return myResult;
  };

  const isChallengeFinished = () => {
    return finishedChallengeEnums.includes(challenge.status);
  };

  return (
    <S.Wrapper
      playingHighlight={challenge.myRole === 'Player' && playingHighlight}
      surviveHighlight={
        challenge.myRole === 'Player' &&
        challenge.myResult !== 'Eliminated' &&
        surviveHighlight
      }
      eliminatedHighlight={
        challenge.myRole === 'Player' &&
        challenge.myResult === 'Eliminated' &&
        eliminatedHighlight
      }
      key={challenge.id}
      onClick={() => handleOnClick(challenge.slug)}
      highlightedColor={challenge.highlighted ? statusStyle?.background : null}
    >
      <S.LeftGroup hasStatus={status?.length}>
        {status?.length && (
          <S.StatusChallenge
            color={statusStyle?.color}
            background={statusStyle?.background}
          >
            {status}
          </S.StatusChallenge>
        )}

        <S.Info>
          <S.Title>{challenge.name}</S.Title>
          <S.Id>#{challenge.idNumber}</S.Id>
        </S.Info>

        <S.InfoPrize>
          {challenge.typePrize === 'Other' ? (
            <S.Prize>{`${separator(challenge.prizePool)} ${
              challenge.prizePool === 1
                ? challenge.otherPrizeDescriptionSingular
                : challenge.otherPrizeDescriptionPlural
            }`}</S.Prize>
          ) : (
            <>
              <S.Prize>{moneyIntl(challenge.prize.float)}</S.Prize>
              <S.Currency>{currencySymbol()}</S.Currency>
            </>
          )}
        </S.InfoPrize>

        <S.WrapperItemsBottom>
          <S.WrapperBadge>
            <Badge
              prefix={currencySymbol()}
              value={moneyIntl(challenge.buyin.float)}
            />
            {isVip && (
              <BadgeInfo
                bgColor={'#fbc531'}
                showIcon
                text={t('card.badgeInfo.vip')}
                marginLeft={5}
              />
            )}

            {!hideIcon && (
              <S.IconType
                src={icons[challenge.type.toLowerCase()]}
                width={18}
                height={18}
              />
            )}
          </S.WrapperBadge>

          <S.IconUserGroup>
            <UsersCount
              fontSize={10}
              total={separator(challenge.countPlayers.total)}
            />
          </S.IconUserGroup>
        </S.WrapperItemsBottom>
      </S.LeftGroup>

      <S.RightGroup>
        <S.SubGroup>
          {isChallengeFinished() ? (
            challenge.myRole === 'Guest' ? (
              <S.StatusLabel color={'rgba(255,255,255,0.3)'}>
                {t('card.didntjoin')}
                <div>{challenge.finishedDate?.format}</div>
              </S.StatusLabel>
            ) : (
              <S.StatusLabel
                color={myResultStyle(resolveMyResult()).background}
              >
                {myResultLabel(resolveMyResult())}
                {<div>{challenge.finishedDate?.format}</div>}
              </S.StatusLabel>
            )
          ) : (
            <CountDownMyResult
              hideIcon
              showLabel
              myResult={resolveMyResult()}
              myRole={challenge.myRole}
              status={challenge.status}
              timestamps={{
                lastRoundEndDate: challenge.lastRoundEndDate,
                lastEventEndDate: challenge.lastEventEndDate,
                nextRoundStartDate: challenge.nextRoundStartDate,
                saleStartDate: challenge.saleStartDate,
              }}
            />
          )}
        </S.SubGroup>
        <S.WinAmount>
          <S.IconCup />
          <S.WinCount>{separator(challenge.prizePool)}</S.WinCount>
          <Trans i18nKey="card.WinAmount.winner" count={challenge.prizePool} />
        </S.WinAmount>

        <S.GroupIcons>
          <EliminatedCount
            fontSize={10}
            eliminated={separator(challenge.countPlayers.eliminated)}
          />
          <SurviveCount
            fontSize={10}
            survive={separator(challenge.countPlayers.survive)}
          />
        </S.GroupIcons>
      </S.RightGroup>
    </S.Wrapper>
  );
};

Card.propTypes = {
  challenge: PropTypes.object,
  statusStyle: PropTypes.object,
  status: PropTypes.string,
  hideIcon: PropTypes.bool,
  playingHighlight: PropTypes.string,
  surviveHighlight: PropTypes.string,
  eliminatedHighlight: PropTypes.string,
};

Card.defaultProps = {
  challenge: null,
  statusStyle: null,
  status: null,
  hideIcon: false,
  playingHighlight: '',
  surviveHighlight: '',
  eliminatedHighlight: '',
};

export default Card;
