import React, { useEffect, useState } from 'react';
import { Timer, Wrapper, Clock, Icon } from './style';
import PropTypes from 'prop-types';

const getCurrentTime = () => {
  const now = new Date();

  const time = [now.getHours(), now.getMinutes(), now.getSeconds()]
    .map((item) => item.toString().padStart(2, '0'))
    .join(':');

  return time;
};

const CurrentSchedule = ({ size, width }) => {
  const [dateTime, setDateTime] = useState(getCurrentTime());
  useEffect(() => {
    const timer = setInterval(() => setDateTime(getCurrentTime()), 1000);

    return () => clearInterval(timer);
  }, [dateTime]);

  return (
    <Wrapper>
      <Icon>
        <Clock icon="clock" size={size} width={width} />
      </Icon>

      <Timer>{dateTime}</Timer>
    </Wrapper>
  );
};

CurrentSchedule.propTypes = {
  size: PropTypes.number,
  width: PropTypes.number,
};

export default CurrentSchedule;
