import styled from 'styled-components';

export const Icon = styled.div`
  max-width: 400px;
  padding: 10px;

  width: 100%;
`;

export const Title = styled.h1`
  font-size: 20px;
  width: 100%;

  color: #fafafa;

  font-family: Gorga Grotesque Light;
  text-align: center;
`;

export const SubTitle = styled.h1`
  padding: 10px 0px;
  font-size: 10px;

  color: #fafafa;

  font-family: Gorga Grotesque Light;
`;

export const Group = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;
