import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useNumberFormat } from '../../../../hooks/numberFormat';

import { Users } from 'react-feather';

import {
  LineWrapper,
  Score,
  Bets,
  Group,
  GroupUsers,
  Percent,
  Total,
  Bar,
} from './style';
import { useChallenge } from '../../../../pages/ChallengeDetails/Context';

const Line = ({ idx, line, handleGetColor, event, round }) => {
  const { separator } = useNumberFormat();

  const { selectedEvents, toggleEvent, isRegisteringBet, isAvaliableToBet } =
    useChallenge();

  const selectedLine = selectedEvents[event.id];

  const isDisabled = !isAvaliableToBet(round) || isRegisteringBet;

  const handleClick = useCallback(
    (value) => {
      if (round.myResult !== 'PendingBet') return;
      return toggleEvent(event.id, value);
    },
    [round, selectedEvents]
  );

  return (
    <Bets key={idx}>
      <Group>
        <LineWrapper
          selected={selectedLine === idx}
          bet={isDisabled && line.myResult === 'Selected'}
          right={line.result && line.result.right}
          winned={line.myResult == 'Correct'}
          loss={line.myResult == 'Wrong'}
          onClick={() => {
            if (isDisabled) return;

            line.status !== 'Ended' && handleClick(idx);
          }}
          border={line.myResult === 'Selected'}
          isDisabled={isDisabled}
        >
          {line.outcomeName}
          {line.countPlayer && (
            <GroupUsers>
              <Users color={handleGetColor(line.myResult)} size={9} />
              <Total color={handleGetColor(line.myResult)}>
                {separator(line.countPlayer.total)}
              </Total>
              <Bar color={handleGetColor(line.myResult)}>/</Bar>
              <Percent color={handleGetColor(line.myResult)}>
                {line.countPlayer.percent}%
              </Percent>
            </GroupUsers>
          )}
          {line.result && <Score>{line.result.score}</Score>}
        </LineWrapper>
      </Group>
    </Bets>
  );
};

Line.propTypes = {
  idx: PropTypes.string.isRequired,
  line: PropTypes.object.isRequired,
  handleGetColor: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  round: PropTypes.object.isRequired,
};

export default Line;
