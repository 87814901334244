/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useChallenge } from '../../../../../pages/ChallengeDetails/Context';
import { useNumberFormat } from '../../../../../hooks/numberFormat';
import Trophy from '../../../../Svg/Trophy';

import * as S from '../style';

const AllPlayersWrong = ({ components, myResult, typePrize }) => {
  const { t } = useTranslation();

  const { currencySymbol, moneyIntl } = useNumberFormat();

  const {
    challenge: { resultPrize, otherPrizeDescriptionSingular, prizePool },
  } = useChallenge();

  if (myResult !== 'Awarded') {
    return <S.TitleColor>{t('round.button.endGame')}</S.TitleColor>;
  }

  return (
    <>
      <S.SubtitleMessageColor>
        {t('round.message.title.win')}
      </S.SubtitleMessageColor>

      <S.GroupCurrency>
        {typePrize === 'Other' ? (
          <S.CurrencyValue>
            {t('round.message.title.win.other', {
              otherPrizeDescriptionSingular: otherPrizeDescriptionSingular,
              prizePool,
            })}
          </S.CurrencyValue>
        ) : (
          <Fragment>
            <S.CurrencySymbol>{currencySymbol()}</S.CurrencySymbol>
            <S.CurrencyValue>
              {moneyIntl(resultPrize.unitPrizeWrong.float, {
                digits: 1,
              })}
            </S.CurrencyValue>
          </Fragment>
        )}
      </S.GroupCurrency>

      <S.IconBg size={100}>
        <Trophy width={65} height={65} />
      </S.IconBg>
    </>
  );
};

export default AllPlayersWrong;
