import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.h1`
  padding: 50px;
  width: 100%;
  max-width: 600px;

  color: #fafafa;

  display: flex;
`;

export const Title = styled.h1`
  font-size: 25px;

  color: #fafafa;

  font-family: Gorga Grotesque Bold;
`;

export const SubTitle = styled.h1`
  padding: 10px 0px;
  font-size: 10px;

  color: #fafafa;

  font-family: Gorga Grotesque Light;
`;

export const Button = styled.button`
  height: 40px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
  padding: 5px 13px 0;
  margin-top: 5px;
  border: none;
  border-radius: 3px;
  font-family: Gorga Grotesque Light;
  font-size: 15px;
  text-align: center;
  color: #181d26;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 5px 0px rgb(0 0 0 / 10%);

  &:hover {
    transform: translateY(4px);
  }

  &:active {
    transform: translateY(8px);
  }
`;
