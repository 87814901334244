import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { animated } from 'react-spring';

export const Wrapper = styled.div`
  @media (min-width: 600px) {
    visibility: hidden;
    position: fixed;

    bottom: 0;
  }

  display: ${({ hidden }) => hidden && 'none'};
`;

export const DrawerDeskWrapper = styled.div`
  background: #252c39;

  font-family: Gorga Grotesque Regular;

  @media (max-width: 600px) {
    display: none;
  }

  min-width: 200px;
  max-width: 200px;
  min-height: 500px;
  max-height: 500px;

  display: ${({ hidden }) => hidden && 'none'};
`;

export const DrawerMobileWrapper = styled(animated.div)`
  position: fixed;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  background: #252c39;

  z-index: ${({ theme }) => theme.zIndexScale.zIndexDrawerMobileWrapper};

  outline: 0;
  flex: 1 0 auto;
  bottom: 0;
  top: 0;

  width: 70%;
  height: 100%;

  margin-top: 50px;

  @media (max-width: 600px) {
    overflow: scroll;
  }

  box-shadow: 7px 0px 5px 1px rgb(0 0 0 / 10%);
`;

export const Overlay = styled(animated.div)`
  background: #1b212a;

  margin-top: 50px;

  width: 100%;
  height: 100%;

  opacity: 0.5;

  z-index: ${({ theme }) => theme.zIndexScale.zIndexDrawerOverlay};

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 10px 20px 10px;
`;

export const Title = styled.h1`
  font-family: Gorga Grotesque Light;
  font-size: 16px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const List = styled.div``;

export const Item = styled.div`
  padding: 15px 15px;
  border-bottom: 0.2px;
  border-bottom-style: solid;
  border-bottom-color: rgba(44, 53, 65, 1);
  background: ${({ active }) => active && 'rgba(44, 53, 65, 0.5)'};

  :hover {
    background: rgba(44, 53, 65, 0.5);
  }
  user-select: none;
`;

export const LinkItem = styled(Link)`
  cursor: pointer;

  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const ItemText = styled.h1`
  font-family: Gorga Grotesque Light;

  font-size: 12px;

  background: ${({ theme, active }) =>
    active
      ? `linear-gradient(to right, ${theme.primary} 0%, ${theme.secondary} 100%)`
      : '#fff'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &:hover {
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.primary} 0%,
      ${({ theme }) => theme.secondary} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Version = styled.h1`
  font-family: Gorga Grotesque Light;

  font-size: 10px;
  margin-top: 10px;

  color: #ffff;

  text-align: center;
`;
