/* eslint-disable react/prop-types */
import React from 'react';

import * as S from '../style';

import Survive from './Survive';
import SurviveWithWrong from './SurviveWithWrong';
import AllPlayersWrong from './AllPlayersWrong';

const components = {
  bold: <S.TextBold />,
  br: <br />,
};

const headers = {
  Survive,
  SurviveWithWrong,
  AllPlayersWrong,
};

const Header = ({ typeWinner, ...props }) => {
  const Component = headers[typeWinner];

  return <Component {...props} components={components} />;
};

export default Header;
