import styled from 'styled-components';
import FeatherIcon from 'feather-icons-react';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 1px;

  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;

export const Vacancies = styled.h1`
  font-family: Gorga Grotesque Regular;
  vertical-align: middle;

  color: #fff;

  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
`;

export const Icon = styled(FeatherIcon).attrs(({ size, icon }) => {
  return {
    icon: icon,
    size: size,
  };
})`
  color: #6c7a92;
  ${({ width }) => width && `width: 20px;`}

  vertical-align: middle;

  margin-right: 2px;
  margin-bottom: 3px;
`;

export const Aside = styled.div`
  display: flex;
`;
