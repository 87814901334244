import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import RoundItem from './RoundItem';

import * as S from './style';
import { useChallenge } from '../../../pages/ChallengeDetails/Context';
import { useEnums } from '../../../hooks/useEnum';

const Round = ({ variant }) => {
  const {
    challenge,
    isRegisteringBet,
    isAllEventsSelected,
    handleBet,
    feedbackMessage,
    isAvaliableToBet,
  } = useChallenge();

  const [heigthRounds, setHeigthRounds] = useState(0);
  const [roundsOpens, setRoundsOpens] = useState(() => {
    const lastRound = challenge.rounds[0] || null;

    return lastRound
      ? [
          ...challenge.rounds
            .filter((round) => round.id != lastRound.id)
            .map((round) => round.id),
        ]
      : [];
  });

  const { t } = useTranslation();
  const { myResultLabel, myResultStyle } = useEnums();

  const refMotion = useRef(null);

  const handleClickAcordion = (id) => {
    const isExistRoundOpens = roundsOpens.filter(
      (roundId) => roundId === id
    )[0];

    // Delete
    if (isExistRoundOpens) {
      return setRoundsOpens(roundsOpens.filter((roundId) => roundId !== id));
    }

    // Add
    setRoundsOpens([...roundsOpens, id]);
  };

  useEffect(() => {
    if (!refMotion.current) return;

    setHeigthRounds(refMotion.current.clientHeight);
  }, [heigthRounds]);

  const showMyResult = useCallback(
    (round) => {
      if (round.myResult === 'None') return <></>;

      let variantEnum = 'default';
      const isEliminated = round.myResult === 'Eliminated';
      const isAwarded = challenge.myResult === 'Awarded';

      let haveBet = round.events[0].lines.some(
        ({ myResult }) => myResult !== 'None'
      );

      if (isEliminated && !haveBet) {
        variantEnum = isAwarded ? 'voidAwarded' : 'voided';
      }

      if (isEliminated && haveBet && isAwarded) {
        variantEnum = 'awarded';
      }

      return (
        <S.Status {...myResultStyle(round.myResult, variantEnum)}>
          {myResultLabel(round.myResult, variantEnum)}
        </S.Status>
      );
    },
    [challenge.myResult]
  );

  return (
    <S.Wrapper>
      {challenge.rounds &&
        challenge.rounds.map((round, idx) => {
          const filterId = roundsOpens.filter(
            (roundId) => roundId === round.id
          )[0];

          return (
            <div key={idx}>
              <S.Group
                onClick={() => idx !== 0 && handleClickAcordion(round.id)}
                isopen={filterId}
              >
                <S.SubGroup>
                  {idx !== 0 && <S.AcordionButton isopen={filterId} />}

                  <S.Title size={12}>{t('round.text.level')}:</S.Title>

                  <S.Title
                    size={15}
                    style={{
                      marginLeft: '5px',
                    }}
                  >
                    {round.level}
                  </S.Title>
                  {showMyResult(round)}
                </S.SubGroup>
                {round.endGame && (
                  <S.SubGroup>
                    <S.BadgeEnd>{t('round.badgeEnd')}</S.BadgeEnd>
                  </S.SubGroup>
                )}
              </S.Group>

              <S.Acordion
                idx={idx}
                heigthRo={heigthRounds}
                ref={refMotion}
                isopen={filterId}
                id={`accordion-${idx}`}
              >
                <RoundItem round={round} variant={variant} />
              </S.Acordion>

              {isAvaliableToBet(round) && (
                <S.Button
                  type={feedbackMessage.type || ''}
                  title={
                    feedbackMessage.message ||
                    (!isAllEventsSelected()
                      ? t('challenge.button.confirm.unselected')
                      : t('challenge.button.confirm'))
                  }
                  onPress={() => handleBet(round.id)}
                  loading={isRegisteringBet}
                  disabled={feedbackMessage.message || !isAllEventsSelected()}
                />
              )}
            </div>
          );
        })}
      {challenge.status !== 'Sale' && (
        <S.ChallengeStart>{t('challenge.startChallenge')}</S.ChallengeStart>
      )}
    </S.Wrapper>
  );
};

Round.propTypes = {
  variant: PropTypes.string,
};

Round.defaultProps = {
  variant: 'default',
};

export default Round;
