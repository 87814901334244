import React from 'react';

const MinYear = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={216.011}
      height={197.353}
      viewBox="0 0 216.011 197.353"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.327}
          y1={0.144}
          x2={0.267}
          y2={0.781}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#2c343e" />
          <stop offset={1} stopColor="#1f252f" />
        </linearGradient>
      </defs>
      <g data-name="Grupo 42" transform="translate(-101.767 -643.881)">
        <path
          data-name="Caminho 123"
          d="M315.593 177.215l68.38-34.454a15.086 15.086 0 0117.3 2.638l54.655 53.022a15.088 15.088 0 01-.51 22.124l-59.492 52.6a15.088 15.088 0 01-21.248-1.255l-63.547-71.15a15.088 15.088 0 014.462-23.525z"
          transform="translate(-182.798 510.513)"
          fill="url(#prefix__a)"
        />
        <path
          data-name="Caminho 136"
          d="M106.895 840.989l186.628-.557s-20.55-12.219-28.881-8.331-3.89-27.755-26.108-33.876-31.66 23.329-31.66 23.329 1.671-12.224-10-11.665-16.123 11.11-16.123 11.11-17.2-32.777-35.53-25 0 30 0 30-14.441-10.553-16.109-1.665-13.33 3.887-13.33 9.442-8.887 7.213-8.887 7.213z"
          fill="#272d37"
        />
        <path
          data-name="Caminho 137"
          d="M239.601 814.313l-5.206 11.852a1.759 1.759 0 00.6 2.149 1.759 1.759 0 002.451-.431l10.354-14.784-5.086-3.125z"
          fill="#251138"
        />
        <path
          data-name="Caminho 138"
          d="M236.03 812.034l3.063-4.372 3.531 2.474a2.668 2.668 0 01.655 3.715 2.668 2.668 0 01-3.717.657l-3.532-2.474z"
          fill="#e2b3a3"
        />
        <path
          data-name="Caminho 139"
          d="M184.635 834.247l-12.7 2.536a1.759 1.759 0 00-1.413 1.724 1.759 1.759 0 001.759 1.759h18.049l-.361-5.954z"
          fill="#251138"
        />
        <path
          data-name="Caminho 140"
          d="M184.445 830.005h5.338v4.312a2.668 2.668 0 01-2.668 2.668 2.668 2.668 0 01-2.668-2.668v-4.312z"
          fill="#e2b3a3"
        />
        <path
          data-name="Caminho 141"
          d="M182.713 680.185a121.31 121.31 0 01-7.76 17.472 33.092 33.092 0 01-4.031 5.784c-2.143 2.325-4.306 3.354-6.247 1.861a3.2 3.2 0 01-.756-.812 3.682 3.682 0 01-.413-.879.07.07 0 010-.021 5.954 5.954 0 01-.243-2 17.952 17.952 0 011.466-5.825c3.4-8.5 11.533-19.055 11.533-19.055 4.141-.495 6.451 3.475 6.451 3.475z"
          fill="#ffaa40"
        />
        <path
          data-name="Caminho 142"
          d="M192.621 711.341s-13.646.267-22.338-2.685c-2.905-.988-5.256-2.331-6.364-4.166a5.085 5.085 0 01-.413-.879.07.07 0 010-.021 4.714 4.714 0 01-.243-2 6.164 6.164 0 01.088-.624c.363-1.812 2.1-2.791 4.585-3.225a29.124 29.124 0 017.036-.106 114.474 114.474 0 0118.961 3.767c3.862 2.038 3.575 9.247-1.312 9.939z"
          fill="#ffaa40"
        />
        <path
          data-name="Caminho 143"
          d="M230.849 700.98a8.031 8.031 0 01-.179.7 4.227 4.227 0 01-1.29 2.052 3.378 3.378 0 01-.715.449c-1.759.806-3.292.062-4.635-1.63a22.278 22.278 0 01-3.175-6.449c-2.932-8.5-4.265-20.4-4.265-20.4s3.134-3.357 7.068-1.961c0 0 5.936 12.808 7.173 21.9a16.261 16.261 0 01.018 5.339z"
          fill="#ffaa40"
        />
        <path
          data-name="Caminho 144"
          d="M230.848 700.983a4.789 4.789 0 01-.179.7 5.772 5.772 0 01-1.29 2.052 14.749 14.749 0 01-4.8 3.128c-8.361 3.708-22.021 4.69-22.021 4.69-4.934-.141-5.986-7.118-2.345-9.557 0 0 11.726-4.69 20.652-5.863 3.022-.4 5.731-.4 7.557.325a3.524 3.524 0 012.246 2.2 4.856 4.856 0 01.18 2.325z"
          fill="#ffaa40"
        />
        <path
          data-name="Caminho 145"
          d="M184.474 792.496a7.373 7.373 0 01-7.364-6.956c-.915-16.537-1.316-33.475-.929-45.9h16.141c-.51 14.153-.636 30.62-.472 45.4a7.376 7.376 0 01-7.376 7.455z"
          fill="#494e6d"
        />
        <path
          data-name="Caminho 146"
          d="M184.469 778.281a7.343 7.343 0 00-7.329 7.783c.85 14.25 2.052 35.081 2.7 44.219h12.488c-.492-10.4-.352-31.757-.513-44.749a7.343 7.343 0 00-7.346-7.253z"
          fill="#494e6d"
        />
        <path
          data-name="Caminho 147"
          d="M210.596 787.372a7.367 7.367 0 01-8.692-5.2c-4.471-15.944-8.528-32.4-10.847-44.609l15.757-3.494c2.562 13.928 6 30.033 9.36 44.427a7.376 7.376 0 01-5.579 8.877z"
          fill="#494e6d"
        />
        <path
          data-name="Caminho 148"
          d="M203.584 774.444a7.346 7.346 0 00-.179 10.694c10.225 9.967 25.149 24.543 31.769 30.872l9.234-8.408c-7.379-7.361-21.64-23.232-30.505-32.73a7.349 7.349 0 00-10.319-.428z"
          fill="#494e6d"
        />
        <path
          data-name="Caminho 149"
          d="M176.181 739.641s.41-32.754 9.445-37.418c5.18-2.674 15.895-1.947 21.359 1.759 5.517 3.726 1.621 35.671 1.621 35.671z"
          fill="#ffaa40"
        />
        <path
          data-name="Caminho 150"
          d="M217.588 677.029a6.437 6.437 0 01-2.272-2.251c-.674-1.343-.956-2.331-2.424-3.3s-.144-2.413 1.091-1.513 2.984 2.609 3.579.759-2.093-6.033 1-6.185c0 0-.733-2.166.258-2.383s1.668 1.741 1.668 1.741 1.691-2.155 2.518 3.128c0 0 1.2-.492 1.5.507.472 1.6-.021 5.309-1.237 8.026-1.981 4.418-5.681 1.471-5.681 1.471z"
          fill="#e2b3a3"
        />
        <path
          data-name="Caminho 151"
          d="M218.562 664.542s.75 1.129.879 5.995"
          fill="none"
          stroke="#c19181"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 152"
          d="M220.488 663.9a27.142 27.142 0 01.727 6.279"
          fill="none"
          stroke="#c19181"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 153"
          d="M223.006 667.028s-.059 1.958-.17 3.518"
          fill="none"
          stroke="#c19181"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 154"
          d="M182.038 680.529a6.793 6.793 0 002.1-2.345c.636-1.363.9-2.345 2.251-3.389s.152-2.4-.985-1.439-2.761 2.735-3.292.938 1.976-6.074-.862-6.062c0 0 .692-2.178-.214-2.345s-1.548 1.806-1.548 1.806-1.533-2.052-2.345 3.225c0 0-1.1-.425-1.378.586-.446 1.609-.026 5.247 1.067 7.871 1.785 4.254 5.206 1.154 5.206 1.154z"
          fill="#e2b3a3"
        />
        <path
          data-name="Caminho 155"
          d="M181.253 668.23s-.7 1.155-.85 5.971"
          fill="none"
          stroke="#c19181"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 156"
          d="M179.491 667.694a29.125 29.125 0 00-.721 6.247"
          fill="none"
          stroke="#c19181"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 157"
          d="M177.154 670.918s.035 1.935.126 3.459"
          fill="none"
          stroke="#c19181"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 158"
          d="M195.245 706.34c-3.415 0-4.984-3.993-4.054-4.91a3.18 3.18 0 00.642-1 6.744 6.744 0 00.466-1.735l6.317-5.634c-.34 3.585 1.193 6.1 1.759 7.9s-1.249 5.379-5.13 5.379z"
          fill="#e2b3a3"
        />
        <path
          data-name="Caminho 159"
          d="M197.51 697.304a6.039 6.039 0 01-5.675 3.128 6.743 6.743 0 00.466-1.735c.173-.1 6.907-4.641 5.209-1.393z"
          fill="#cb9583"
        />
        <path
          data-name="Caminho 160"
          d="M204.866 686.616s-.273 3.275-5.033 6.649c-4.984 3.535.692-7.329.692-7.329z"
          fill="#533d3c"
        />
        <path
          data-name="Caminho 161"
          d="M191.87 684.649s-.674 2.823-1.548 5.989c-1.249 4.526-1.354 8.188 3.257 8.188 7.745 0 7.229-12.852 7.229-12.852z"
          fill="#e2b3a3"
        />
        <path
          data-name="Caminho 162"
          d="M179.201 713.859s2.081-8.012 4.259-9.818"
          fill="none"
          stroke="#bf7a19"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Linha 4"
          fill="none"
          stroke="#bf7a19"
          strokeMiterlimit={10}
          strokeWidth={0.5}
          d="M174.894 697.756l-2.486 3.87"
        />
        <path
          data-name="Linha 5"
          fill="none"
          stroke="#bf7a19"
          strokeMiterlimit={10}
          strokeWidth={0.5}
          d="M220.852 696.132l3.831 1.381"
        />
        <path
          data-name="Caminho 163"
          d="M184.34 756.093l13.939-.633-4.435-13.942-6.654-.95z"
          fill="#494e6d"
        />
        <path
          data-name="Caminho 164"
          d="M190.2 739.776l-1.108 9.029 3.8.317"
          fill="none"
          stroke="#251138"
          strokeMiterlimit={10}
        />
        <circle
          data-name="Elipse 7"
          cx={2.594}
          cy={2.594}
          r={2.594}
          transform="translate(197.171 691.061)"
          fill="#e2b3a3"
        />
        <path
          data-name="Caminho 165"
          d="M178.5 739.627l-.621 12.57-1.776 1.571"
          fill="none"
          stroke="#251138"
          strokeMiterlimit={10}
        />
        <path
          data-name="Caminho 166"
          d="M204.828 739.723l3.169 12.989 2.638.844"
          fill="none"
          stroke="#251138"
          strokeMiterlimit={10}
        />
        <path
          data-name="Caminho 167"
          d="M179.833 830.283l-.407-4.01 12.359-.475.536 4.485z"
          fill="#251138"
        />
        <path
          data-name="Caminho 168"
          d="M231.707 812.648l3.471 3.374 9.234-8.408-3.515-3.362z"
          fill="#251138"
        />
        <path
          data-name="Caminho 169"
          d="M189.303 700.435s-.528 7.288 4.858 7.815 6.971-2.638 8.448-7.182"
          fill="none"
          stroke="#bf7a19"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 170"
          d="M200.709 687.948a2.372 2.372 0 00-2.852-1.691c-2.219.528-3.274 1.586-4.435 1.055s-1.551-2.662-1.551-2.662.821-5.215 7.475-3.313 5.277 5.974 5.277 5.974l-2.325 1.058z"
          fill="#533d3c"
        />
        <circle
          data-name="Elipse 8"
          cx={0.542}
          cy={0.542}
          r={0.542}
          transform="translate(195.558 690.269)"
          fill="#815f54"
        />
        <circle
          data-name="Elipse 9"
          cx={0.542}
          cy={0.542}
          r={0.542}
          transform="translate(192.184 689.524)"
          fill="#815f54"
        />
        <path
          data-name="Caminho 171"
          d="M194.254 690.718a1.987 1.987 0 01-1.067 1.988c-1.263.648-.22 1.774.923 1.258"
          fill="none"
          stroke="#cb9583"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 172"
          d="M191.666 695.458l2.615.4s-.437 1.7-1.624 1.507-.991-1.907-.991-1.907z"
          fill="#fff"
        />
        <path
          data-name="Caminho 173"
          d="M196.154 689.334a1.859 1.859 0 011.5 1.108"
          fill="none"
          stroke="#af7f6f"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 174"
          d="M191.877 688.422a2.644 2.644 0 011.926.317"
          fill="none"
          stroke="#af7f6f"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <path
          data-name="Caminho 175"
          d="M199.832 693.265s1.219.094 1.378 1.123"
          fill="none"
          stroke="#cb9583"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <circle
          data-name="Elipse 10"
          cx={4.092}
          cy={4.092}
          r={4.092}
          transform="translate(238.309 756.02)"
          fill="#73e8b7"
        />
        <circle
          data-name="Elipse 11"
          cx={4.166}
          cy={4.166}
          r={4.166}
          transform="translate(130.781 758.233)"
          fill="#73e8b7"
        />
        <circle
          data-name="Elipse 13"
          cx={3.025}
          cy={3.025}
          r={3.025}
          transform="translate(155.587 652.945)"
          fill="#9ff191"
        />
        <circle
          data-name="Elipse 14"
          cx={8.053}
          cy={8.053}
          r={8.053}
          transform="translate(249.645 682.509)"
          fill="#9ff191"
        />
        <path
          data-name="Caminho 176"
          d="M114.91 713.038l6.742 3.313a2.638 2.638 0 01.413 4.459l-5.4 4.048a2.638 2.638 0 01-4.16-1.627l-1.348-7.361a2.638 2.638 0 013.749-2.832z"
          fill="#73e8b7"
        />
        <path
          data-name="Caminho 177"
          d="M231.355 644.546l4.412 2.166a1.721 1.721 0 01.276 2.932l-3.518 2.638a1.721 1.721 0 01-2.723-1.064l-.879-4.814a1.721 1.721 0 012.433-1.859z"
          fill="#c7f86d"
        />
        <path
          data-name="Caminho 178"
          d="M282.099 745.258l6.742 3.31a2.638 2.638 0 01.422 4.476l-5.4 4.045a2.638 2.638 0 01-4.16-1.627l-1.351-7.358a2.638 2.638 0 013.744-2.846z"
          fill="#73e8b7"
        />
        <path
          data-name="Caminho 179"
          d="M110.378 791.28l-3.661 3.978a1.888 1.888 0 01-3.154-.627l-1.677-4.544a1.885 1.885 0 011.973-2.53l5.341.586a1.885 1.885 0 011.178 3.137z"
          fill="#c7f86d"
        />
        <rect
          data-name="Ret\xE2ngulo 53"
          width={19.254}
          height={1.48}
          rx={0.74}
          transform="translate(140.036 729.906)"
          fill="#c7f86d"
        />
        <rect
          data-name="Ret\xE2ngulo 54"
          width={23.329}
          height={1.853}
          rx={0.926}
          transform="translate(239.276 664.736)"
          fill="#c7f86d"
        />
        <rect
          data-name="Ret\xE2ngulo 55"
          width={24.809}
          height={1.853}
          rx={0.926}
          transform="translate(262.974 772.119)"
          fill="#c7f86d"
        />
        <rect
          data-name="Ret\xE2ngulo 56"
          width={11.374}
          height={1.853}
          rx={0.926}
          transform="translate(113.784 667.327)"
          fill="#c7f86d"
        />
        <rect
          data-name="Ret\xE2ngulo 57"
          width={11.374}
          height={1.853}
          rx={0.926}
          transform="translate(143.407 686.027)"
          fill="#c7f86d"
        />
        <rect
          data-name="Ret\xE2ngulo 58"
          width={11.374}
          height={1.853}
          rx={0.926}
          transform="translate(247.83 725.277)"
          fill="#c7f86d"
        />
        <path
          data-name="Caminho 180"
          d="M205.358 644.579l14.326 23.2s-18.09 9.214-25.941 2.052 11.615-25.252 11.615-25.252z"
          fill="#ffe200"
        />
        <path
          data-name="Caminho 181"
          d="M193.753 665.392l-25.258 15.7 1.483 1.908 25.481-14.88z"
          fill="#ffe200"
        />
        <path
          data-name="Caminho 182"
          d="M165.766 675.289l7.508 11.946-2.389 1.366-6.825-12.289z"
          fill="#ffe200"
        />
        <path
          data-name="Caminho 183"
          d="M199.765 670.083s2.155 9.316 9.486 7.49 7.118-10.017 7.118-10.017"
          fill="none"
          stroke="#fae21a"
          strokeMiterlimit={10}
          strokeWidth={5}
        />
        <path
          data-name="Caminho 184"
          d="M193.97 661.449s-7.475-5.963-2.662-11.79 12.11-2.1 12.11-2.1"
          fill="none"
          stroke="#fae21a"
          strokeMiterlimit={10}
          strokeWidth={5}
        />
        <circle
          data-name="Elipse 6"
          cx={6.383}
          cy={6.383}
          transform="translate(305.013 820.483)"
          fill="#9ff191"
          r={6.383}
        />
        <path
          data-name="Caminho 185"
          d="M142.303 840.972h-7.065a196.4 196.4 0 01-12.623-17.12c-4.184-6.627-4.688-9.569-3.654-10.712 1.879-2.073 9.246 1.321 13.548 4.711a25.248 25.248 0 018.505 13.034 32.818 32.818 0 011.289 10.087z"
          fill="#383d4f"
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Caminho 186"
          d="M138.961 841.239a.262.262 0 01-.258-.214 37.635 37.635 0 00-6.767-15.487 36.949 36.949 0 00-7.954-7.807.262.262 0 01.307-.425 37.474 37.474 0 018.067 7.918 38.163 38.163 0 016.862 15.7.262.262 0 01-.209.306.241.241 0 01-.048.009z"
          fill="#1e242e"
        />
        <path
          data-name="Caminho 187"
          d="M148.658 841.057c13.4-38.75 13.466-50.053 9.993-51.88-3.755-1.975-12.481 6.692-16.844 13.759-10.362 16.786-2.925 35.595-1.9 38.076z"
          fill="#464b5b"
          style={{
            mixBlendMode: 'lighten',
            isolation: 'isolate',
          }}
        />
        <path
          data-name="Caminho 188"
          d="M144.773 840.231a.262.262 0 01-.258-.216 57.69 57.69 0 011.471-26.625 56.6 56.6 0 017.351-15.437.262.262 0 11.434.294 56.092 56.092 0 00-7.283 15.294 57.15 57.15 0 00-1.456 26.382.262.262 0 01-.212.3.274.274 0 01-.047.008z"
          fill="#1e242e"
        />
      </g>
    </svg>
  );
};

export default MinYear;
