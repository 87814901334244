/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenge } from '../../../../../pages/ChallengeDetails/Context';
import { useNumberFormat } from '../../../../../hooks/numberFormat';
import Trophy from '../../../../Svg/Trophy';

import * as S from '../style';

const Survive = ({ typePrize, myResult }) => {
  const { t } = useTranslation();

  const { currencySymbol, moneyIntl } = useNumberFormat();

  const {
    challenge: { resultPrize, otherPrizeDescriptionSingular },
  } = useChallenge();

  if (myResult !== 'Survive') {
    return <S.TitleColor>{t('round.button.endGame')}</S.TitleColor>;
  }

  return (
    <>
      <S.SubtitleMessageColor>
        {t('round.message.title.win')}
      </S.SubtitleMessageColor>

      {typePrize === 'Other' ? (
        <S.GroupCurrency>
          <S.CurrencyValue>1 {otherPrizeDescriptionSingular}</S.CurrencyValue>
        </S.GroupCurrency>
      ) : (
        <S.GroupCurrency>
          <S.CurrencySymbol>{currencySymbol()}</S.CurrencySymbol>
          <S.CurrencyValue>
            {moneyIntl(resultPrize.unitPrizeSurvive.float, {
              digits: 1,
            })}
          </S.CurrencyValue>
        </S.GroupCurrency>
      )}
      <S.IconBg size={100}>
        <Trophy width={65} height={65} />
      </S.IconBg>
    </>
  );
};

export default Survive;
