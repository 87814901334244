import styled from 'styled-components';
import { animated } from 'react-spring';

export const Wrapper = styled.div`
  padding: 0px 10px;
`;

export const Header = styled.div`
  padding: 10px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.h1`
  font-size: 16px;
  padding-top: 2px;

  font-family: Gorga Grotesque Light;
  background: linear-gradient(to right, #c5f870 0%, #77e9b4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Wallet = styled(animated.div)`
  margin-bottom: 10px;
  width: 100%;
`;

export const Details = styled.div`
  font-size: 15px;
  padding: 10px 20px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  background: linear-gradient(to right, #272f3c 0%, #242b38 100%);
  font-family: Gorga Grotesque Regular;
`;

export const Values = styled.div`
  padding: 10px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  background: linear-gradient(#272f3c 30%, #2a3240 50%, #2c3543 100%);
`;

export const Amount = styled.h1`
  display: flex;
  align-items: center;

  font-family: Gorga Grotesque Regular;

  color: ${({ status }) => (status === 'Out' ? '#EF5D5F' : ' #cbf96a ')};
  font-size: 15px;
`;

export const Description = styled.h1`
  font-size: 14px;

  color: #ffffff;

  display: flex;
  align-items: center;
  font-family: Gorga Grotesque Regular;
`;

export const Date = styled.h1`
  font-size: 10px;
  padding-top: 2px;

  color: #ffffff;

  font-family: Gorga Grotesque Light;
  display: inline-block;
`;

export const Operation = styled.h1`
  font-size: 10px;

  color: #ffffff;

  display: flex;
  align-items: center;
  font-family: Gorga Grotesque Regular;
`;

export const Balance = styled.span`
  font-size: 12px;

  color: #ffffff;

  display: flex;
  align-items: center;
  font-family: Gorga Grotesque Regular;
`;

export const Icon = styled.div`
  color: white;
  transform: ${({ show }) => (show ? 'rotate(90deg)' : 'rotate(-90deg)')};
  transition: transform 400ms ease;

  &:hover {
    color: #a6aebc;
  }
`;

export const Button = styled.button`
  margin-left: 3px;
  height: 30px;
  width: 30px;

  border-radius: 50%;

  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
`;

export const Dropdown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
