import { formatCurrency } from '../utils/number';
import { useReactiveVar } from '@apollo/client';
import { partnerInfoVar } from '../components/Apollo/vars';

export const useNumberFormat = () => {
  const partnerInfo = useReactiveVar(partnerInfoVar);

  const currency = partnerInfo.currency;

  const currencyFunction = (defaultOptions) => {
    return (value, options) => {
      const properties = {
        digits: 2,
        short: true,
        abbrev_py: true,
        showSymbol: true,
        prize: false,
        ...defaultOptions,
        ...options,
      };

      return ` ${
        properties.showSymbol && partnerInfo ? currency : ``
      } ${formatCurrency({
        num: value,
        digits: properties.digits,
        int: !properties.short,
        abbrev_py: properties.abbrev_py,
        prize: properties.prize,
      })}`;
    };
  };

  return {
    currencySymbol: () => {
      switch (partnerInfo ? currency : '') {
        case 'PYG':
          return 'Gs';
        case 'BRL':
          return 'R$';
        case 'USD':
          return '$';
        case 'TZS':
          return 'Tsh';
        case 'ARS':
          return '$';
        default:
          return partnerInfo ? currency : '';
      }
    },
    currency: currencyFunction({}),
    money: currencyFunction({ showSymbol: false }),
    prize: currencyFunction({ prize: true }),
    shortValue: currencyFunction({ showSymbol: false, abbrev_py: false }),
    separator: currencyFunction({
      digits: 0,
      short: false,
      abbrev_py: false,
      showSymbol: false,
      prize: true,
    }),
    moneyIntl: (value) => {
      let val = value || 0;
      let maximumFractionDigits = 0;

      switch (currency) {
        case 'USD':
          return;
        case 'BRL':
          maximumFractionDigits = 2;
          return;
        case 'ARS':
          maximumFractionDigits = 2;
      }

      return new Intl.NumberFormat(partnerInfo.language.replace('_', '-'), {
        currency: currency,
        maximumFractionDigits,
      }).format(val);
    },
  };
};
