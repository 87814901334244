import styled from 'styled-components';
import { Share2 } from 'react-feather';

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
`;

export const IconShare = styled(Share2)`
  color: ${({ theme }) => theme.primary};

  width: 20px;
  height: 20px;
`;

export const ShareLink = styled.div`
  position: relative;

  font-size: 12px;
`;

export const ContainerPopOver = styled.div`
  font-family: Gorga Grotesque Light;

  color: ${({ theme }) => theme.primary};

  padding: 5px;
  font-size: 14px;
`;
