import styled from 'styled-components';

import background from '../../../assets/img/banner-background.svg';
import Cup from '../../Svg/Cup';

export const Wrapper = styled.div`
  @media (max-width: 600px) {
    margin-top: 43px;
  }
`;

export const SubWrapper = styled.div`
  position: relative;
  display: flex;
  color: white;
  background: url(${background}) center no-repeat;
  background-size: cover;

  min-height: 98px;
  padding: 10px;
`;

export const LeftGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 55%;

  flex: 1;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

export const Title = styled.h1`
  font-family: Gorga Grotesque Light;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;

  font-size: 14px;

  color: #fff;

  -webkit-line-clamp: 3;
`;

export const Group = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const IdentifyText = styled.h1`
  font-family: Gorga Grotesque Light;

  font-size: 10px;
  margin: 2px 0;

  opacity: 0.5;
`;

export const IdentifyNumber = styled.span`
  font-family: Gorga Grotesque Light;

  font-size: 10px;
  margin: 2px 0;

  opacity: 0.5;
`;

export const Border = styled.div`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.secondary} 100%
  );

  height: 1px;
`;

export const IconType = styled.img`
  margin-left: 2px;
`;

export const RightGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  width: 45%;
`;

export const PrizeGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrizeText = styled.h1`
  font-family: Gorga Grotesque Light;
  align-self: flex-start;
  text-transform: uppercase;

  font-size: 7px;

  color: ${({ theme }) => theme.primary};
`;
export const Prize = styled.h1`
  font-family: Gorga Grotesque Regular;

  font-size: 18px;
`;

export const PrizeSymbol = styled.span`
  font-family: Gorga Grotesque Regular;
  align-self: flex-end;
  text-transform: uppercase;

  font-size: 10px;

  color: #9da7b9;
`;

export const WinAmount = styled.span`
  font-family: Gorga Grotesque Light;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  font-size: 10px;

  color: #9da7b9;
`;

export const IconCup = styled(Cup)`
  margin-right: 3px;
  width: 12px;
  height: 12px;
`;

export const WinCount = styled.b`
  font-family: Gorga Grotesque Regular;

  margin-right: 2px;

  color: #fafafa;
`;

export const MinMessage = styled.span`
  font-family: Gorga Grotesque Light;

  padding: 2px 0px;
  font-size: 10px;
  margin-top: 2px;

  opacity: 0.5;
`;
