import styled, { css } from 'styled-components';

import { animated } from 'react-spring';

import LottieGlobal from 'lottie-react';
import WinSvg from '../../../Svg/Win';

import EndFlagSvg from '../../../Svg/endFlag';

import { ChevronLeft } from 'react-feather';

import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;

export const WrapColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;

export const IconBg = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;

  border-width: 5px;
  border-radius: 50px;

  background: ${({ background }) => background || `#272e38`};
  border-color: #1e242e;

  ${({ size }) =>
    size &&
    `
    height: ${size}px;
    width: ${size}px;`};
`;

export const CircleIcon = styled.div`
  height: 20px;
  width: 20px;

  border-radius: 50%;

  background: ${({ theme }) => theme.primary};
`;

export const FlagWrapper = styled(IconBg)`
  position: relative;
  height: 60px;
  width: 60px;
  margin: 5px auto 50px;

  background: white;
  &:before {
    content: '';
    position: absolute;
    border-left: 1px dashed #7e889a;
    height: 50px;
    transform: translateY(60px);
  }
`;

export const EndFlag = styled(EndFlagSvg)`
  height: 30px;
  width: 30px;

  border-radius: 50%;
  transform: rotate(-45deg);
`;

export const GroupCurrency = styled.div`
  display: flex;
  justify-content: center;
`;

export const CurrencySymbol = styled.h1`
  font-family: Gorga Grotesque Regular;

  margin-right: 5px;
  font-size: 25px;

  color: #fafafa;
`;

export const CurrencyValue = styled.h1`
  font-family: Gorga Grotesque Regular;

  font-size: 25px;

  color: #fafafa;
`;

export const SubtitleMessage = styled.h2`
  font-family: Gorga Grotesque Regular;
  text-align: center;
  text-transform: uppercase;

  /* padding: 5px; */
  font-size: 12px;
  line-height: 14px;

  color: #fafafa;
`;

export const Message = styled.h2`
  font-family: Gorga Grotesque Light;
  text-align: center;

  padding: 5px;
  font-size: 12px;
  line-height: 16px;

  color: #fafafa;
`;

export const SubtitleMessageBold = styled.h2`
  text-align: center;

  padding: 5px;
  line-height: 16px;
  font-family: Gorga Grotesque Regular;
  font-size: 14px;

  color: #fafafa;
`;

export const Title = styled.h1`
  text-align: center;

  padding: 5px;
  line-height: 16px;
  font-family: Gorga Grotesque Light;
  font-size: 14px;

  color: ${({ theme }) => theme.primary};
`;

export const SubtitleMessageColor = styled(SubtitleMessage)`
  color: ${({ theme }) => theme.primary};
  font-size: 14px;
  font-size: 10px;
  font-family: Gorga Grotesque Light;
  margin-top: 20px;
`;

export const ButtonChallenge = styled.div`
  font-family: Gorga Grotesque Regular;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;

  font-size: 10px;
  border-radius: 30px;
  margin: 5px 0px;
  padding: 7px;

  color: #00000090;
  background: #9ff191;
`;

export const Lottie = styled.div`
  position: absolute;
  pointer-events: none;

  bottom: 0px;
  right: 0px;
  left: 0px;
  margin-bottom: 50px;
`;

export const LottieFestive = styled.div`
  width: 80px;

  top: 0;
`;

export const LottieLoad = styled(LottieGlobal)``;

export const GroupImage = styled.div`
  margin: 20px 0 0;
  ${({ typeWinnerBlurBg }) => {
    switch (typeWinnerBlurBg) {
      case 'AllPlayersWrong':
        return css`
          filter: grayscale(1) blur(0.7px);
          opacity: 0.5;
        `;
      case 'SurviveWithWrong':
        return css`
          filter: grayscale(1) blur(0.7px);
          opacity: 0.5;
        `;
    }
  }}
`;

export const WinImage = styled(WinSvg)``;

export const Description = styled.span`
  font-family: Gorga Grotesque light;
  text-align: center;

  font-size: 10px;

  color: #7e889a;
`;

export const TextBold = styled.h2`
  font-family: Gorga Grotesque Regular;
  display: inline;

  font-size: 14px;
`;

export const TitleColor = styled.h1`
  color: ${({ color, theme }) => (color ? color : theme.primary)};
  font-family: Gorga Grotesque Light;
  text-align: center;

  padding: 5px;
  font-size: 18px;
  margin-top: 10px;
`;

export const List = styled(motion.ul)`
  overflow: hidden;
`;

export const ListItem = styled.li`
  text-align: center;
  color: #fafafa;
  font-size: 12px;
  font-family: Gorga Grotesque Light;
  line-height: 15px;
`;

export const ButtonShow = styled(ButtonChallenge)`
  background: #fafafa;
  color: #222a36;
  font-size: 8px;
  padding: 5px;

  opacity: ${({ hide }) => (hide ? 0 : 1)};
  cursor: ${({ hide }) => (hide ? 'default' : 'cursor')};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'initial')};
  transition: opacity 100ms 200ms;
`;

export const Group = styled(motion.div)`
  overflow: hidden;
  display: flex;
  justify-content: center;

  gap: 20px;
`;

export const WrapperCollapse = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 100%;
  padding: 5px;
`;

export const TitleGroup = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonCollapse = styled(ChevronLeft)`
  color: ${({ theme }) => theme.primary};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')};
  transition: transform 0.5s;

  margin-right: 10px;
`;
