import { SUB_CHALLENGES } from '../../queries/challenge';
import produce from 'immer';

export const listenToChallengeSlug = (subHandler, challengeId, callback) => {
  return subHandler({
    document: SUB_CHALLENGES,
    variables: {
      formatDate: 'DD',
      formatHour: 'HH:mm',
      appChallengeOnChangeParams: {
        ids: [challengeId],
      },
    },
    updateQuery: (prev, { subscriptionData: { data } }) => {
      const { appChallengeBySlug: prevData } = prev;
      const { payload } = data.appChallengeOnChange;

      const { id: _id } = prevData;
      const { id } = payload;

      if (id !== _id) {
        return;
      }

      callback(payload);
    },
  });
};

export const listenToChallenges = (subHandler, keyName, current, status) => {
  return subHandler({
    // onError: (e) => console.log('err em sub', e),
    document: SUB_CHALLENGES,
    variables: {
      formatDate: 'DD',
      formatHour: 'HH:mm',
    },
    updateQuery: (prev, { subscriptionData: { data } }) => {
      // console.log(prev);
      // console.log(data);

      if (!prev[keyName]) return;
      if (!data) return;

      const prevList = prev[keyName];

      const { action, payload } = data.appChallengeOnChange;

      const findIndex = prevList.findIndex((obj) => obj.id == payload.id);

      if (action === 'DELETED') {
        if (findIndex > -1) {
          const newData = [...prevList];
          newData.splice(findIndex, 1);

          return {
            [keyName]: newData,
          };
        }
      }

      if (action === 'UPDATED') {
        let newData = [];

        if (findIndex === -1 && status[current].includes(payload.status)) {
          newData = [payload, ...prevList];
        } else {
          newData = produce(prevList, (draft) => {
            draft[findIndex] = payload;
          });
        }

        if (newData) {
          return {
            [keyName]: newData,
          };
        }
      }
    },
  });
};
