import styled from 'styled-components';

import { User as UserProfile } from 'react-feather';

export const Wrapper = styled.div``;

export const Header = styled.div`
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const LinearGradient = styled.div`
  height: 1px;
  border-radius: 3px;

  max-width: 100%;

  background: linear-gradient(
    to right,
    ${({ theme }) => theme.primary} 0%,
    ${({ theme }) => theme.primary} 100%
  );
`;

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 3px;
  padding: 10px;

  background-color: #212833;

  border-color: #fafafa;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-style: solid;
`;

export const UserIcon = styled(UserProfile)`
  color: #fafafa;

  width: 70px;
  height: 70px;

  stroke-width: 1px;
`;

export const Content = styled.div`
  padding: 10px;

  color: #ffff;

  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const List = styled.div`
  margin-bottom: 10px;

  width: 100%;

  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Value = styled.div`
  font-size: 14px;
  padding: 10px;
  border-radius: 3px 0 0 3px;

  width: 100%;
  min-width: 50%;

  flex-grow: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: linear-gradient(to top, #272f3c 0%, #242b38 100%);
`;

export const Data = styled.div`
  font-size: 14px;
  padding: 10px;
  border-radius: 0 3px 3px 0;

  flex-grow: 1;

  width: 100%;
  min-width: 50%;

  background-color: #2a3240;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: linear-gradient(#272f3c 30%, #2a3240 70%, #2c3543 100%);
`;

export const TextData = styled.h1`
  font-size: 14px;

  width: 100%;

  color: #fafafa;

  word-wrap: break-word;
  font-family: Gorga Grotesque Light;
  text-align: right;
`;

export const TextValue = styled.h1`
  font-size: 14px;

  width: 100%;

  color: #fafafa;

  word-wrap: break-word;
  font-family: Gorga Grotesque Regular;
  text-align: left;
`;

export const Balance = styled.h1`
  font-size: 15px;
  margin-top: 10px;

  color: ${({ theme }) => theme.primary};

  text-align: center;
  font-family: Gorga Grotesque Regular;
`;

export const GroupActions = styled.div`
  margin: 5px 12px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonAction = styled.button`
  padding: 10px;
  border-radius: 3px;

  border: none;
  cursor: pointer;

  background: transparent;
`;

export const ButtonText = styled.div`
  font-size: 12px;
  margin-left: 5px;

  color: #ffff;

  font-family: Gorga Grotesque Regular;
  text-align: center;
  vertical-align: middle;
  display: inline-block;

  &:hover {
    color: #8c8c8c;

    text-decoration: underline;
  }
`;
