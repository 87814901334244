import React, { useRef, useEffect } from 'react';
import { Wrapper, Content, Icon, Title, SubTitle } from './style';
import Alert from './../../assets/alert.json';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

const ExpiredSession = () => {
  const { t } = useTranslation();

  const lottieRef = useRef(null);

  useEffect(() => {
    setTimeout(lottieRef.current?.play, 500);
  }, []);

  return (
    <Wrapper>
      <Icon>
        <Lottie
          lottieRef={lottieRef}
          loop={false}
          autoplay={false}
          animationData={Alert}
          style={{ width: '100%' }}
        />
      </Icon>

      <Content>
        <Title>{t('session.invalidSessions.title')}</Title>
        <SubTitle>{t('session.invalidSessions.subttile')}</SubTitle>
      </Content>
    </Wrapper>
  );
};

export default ExpiredSession;
