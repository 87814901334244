import firebase from 'firebase/app';
import 'firebase/messaging';
import { app } from './app';

const firebaseConfig = {
  ...app.firebase,
};

firebase.initializeApp(firebaseConfig);

export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export default firebase;
