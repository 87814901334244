import styled from 'styled-components';

import FeatherIcon from 'feather-icons-react';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #222a36;

  width: 100%;
  min-height: 40px;
  padding-right: 10px;
  border-bottom: 1px solid #293140;

  @media (max-width: 600px) {
    margin-top: ${({ fixed }) => fixed && '50px'};
    position: ${({ fixed }) => fixed && 'fixed'};
    top: ${({ fixed }) => fixed && 0};
    z-index: ${({ fixed }) => fixed && 2};
    height: 42px;
  }

  box-shadow: 0px 5px 7px rgb(0 0 0 / 15%);
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Aside = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`;

export const BadgeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  padding-right: 2px;
  cursor: pointer;
`;

export const Icon = styled(FeatherIcon).attrs(({ size, icon }) => {
  return {
    icon: icon,
    size: size,
  };
})`
  color: #a6aebc;
`;

export const Length = styled.h1`
  font-family: Gorga Grotesque Regular;
  text-align: center;

  color: #ffffff;

  padding-top: 3px;
  padding-right: 10px;
  font-size: 17px;
`;
