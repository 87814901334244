import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Floating,
  Header,
  Title,
  IconX,
  Content,
  Description,
  Overlay,
} from './style';

const Modal = ({
  isShowing,
  handleClose,
  title,
  children,
  showingStyle,
  top,
}) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (isShowing) return (document.body.style.overflow = 'hidden');

    // eslint-disable-next-line no-undef
    if (!isShowing) return (document.body.style.overflow = 'unset');

    return () => {
      // eslint-disable-next-line no-undef
      document.body.style.overflow = 'unset';
    };
  }, [isShowing]);

  if (showingStyle)
    return (
      <Wrapper isShowing={isShowing}>
        <Overlay isShowing={isShowing} />

        <Floating isShowing={isShowing} style={top ? { top } : {}}>
          <Description>{children}</Description>
        </Floating>
      </Wrapper>
    );

  return (
    <Wrapper>
      <Overlay isShowing={isShowing} />

      <Floating isShowing={isShowing} style={top ? { top } : {}}>
        {title && (
          <Header onClick={handleClose}>
            <IconX size={17} /> <Title>{title}</Title>
          </Header>
        )}
        <Content>
          <Description>{children}</Description>
        </Content>
      </Floating>
    </Wrapper>
  );
};

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.object.isRequired,
  showingStyle: PropTypes.bool,
  top: PropTypes.string,
};

Modal.defaultProps = {
  isShowing: false,
  top: null,
  handleClose: () => {},
};

export default Modal;
