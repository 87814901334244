import * as React from 'react';
const Survive = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26.052}
      height={48.621}
      viewBox="0 0 26.052 48.621"
      {...props}
    >
      <g data-name="Grupo 1" fill="#6c7a92">
        <path
          data-name="Caminho 1"
          d="M16.512 15.526l-3.083 3.789.7-4.7L12 12.032H8.364l-.567-3.06-1.055-1.695 1.706.518h6.4l1.616-1.94-.261-1.688H3.299L2.52 9.81l-.234 1.8-.689 4.95 4.766 8.1L0 48.619h16.714l3.393-24.542 3.416-2.885.329-2.363.45-3.294zm-3.15 11.341l-3.82 17.51 2.66-17.506-2.916.378 4.077-2.052 2.669 2.336z"
        />
        <path
          data-name="Caminho 2"
          d="M10.386.392l-3.9.225-.639 2.448h4.271z"
        />
        <path
          data-name="Caminho 3"
          d="M16.975.108L12.498 0l-.5 3.065 4.509-.1z"
        />
        <path
          data-name="Caminho 4"
          d="M17.313 11.067l2.579.333 1.863-3.2 1.562-5.531-4.473-1.9-.977 6.53z"
        />
        <path
          data-name="Caminho 5"
          d="M23.883 6.804l-2.165 5.738 2.165.738 1.868-3.24.3-1.863z"
        />
        <path data-name="Caminho 6" d="M11.395 10.283h3.141l.59-1.323h-3.83z" />
      </g>
    </svg>
  );
};
export default Survive;
