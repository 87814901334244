import React from 'react';
import {
  Description,
  DescriptionContent,
  Content,
  Button,
  Title,
  Icon,
  LineGradient,
  Container,
} from './style.js';
import { ChevronLeft } from 'react-feather';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';

const timeAnimation = 400;

export const AcordionItem = ({ item, index, active, setActive }) => {
  const onClick = () => {
    if (active === index) {
      return setActive(null);
    }
    setActive(index);
  };

  const activeTransition = useSpring({
    reset: false,
    from: { opacity: 0, maxHeight: '0' },
    to: {
      maxHeight: active === index ? '1000px' : '0',
      opacity: active === index ? 1 : 0,
    },
    config: { duration: timeAnimation },
  });

  return (
    <Content>
      <Button onClick={onClick}>
        <Container>
          <Title>{item.question}</Title>
          <Icon index={index} active={active}>
            <ChevronLeft />
          </Icon>
        </Container>
        <LineGradient />
      </Button>
      <Description index={index} active={active} style={activeTransition}>
        <DescriptionContent
          dangerouslySetInnerHTML={{ __html: item.answer }}
        ></DescriptionContent>
      </Description>
    </Content>
  );
};

AcordionItem.propTypes = {
  active: PropTypes.number,
  setActive: PropTypes.func,
  item: PropTypes.object,
  index: PropTypes.number,
  onClick: PropTypes.func,
  data: PropTypes.array,
};

export default AcordionItem;
