/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import confetti from '../../../../assets/confetti.json';

import * as S from './style';

const Extra = ({ typePrize, typeWinner, myResult }) => {
  const { t } = useTranslation();

  if (!['Survive', 'Awarded'].includes(myResult)) {
    return null;
  }

  return (
    <>
      {myResult === 'Survive' && (
        <S.Lottie>
          <S.LottieLoad animationData={confetti} />
        </S.Lottie>
      )}

      <S.GroupImage
        typeWinnerBlurBg={myResult === 'Survive' ? null : typeWinner}
      >
        <S.WinImage />
      </S.GroupImage>
      <S.Description>
        {typePrize === 'Other'
          ? t('endGame.prizeInstructions.other')
          : t('endGame.prizeInstructions')}
      </S.Description>
    </>
  );
};

export default Extra;
