import styled from 'styled-components';
import { Clock as ClockTimer } from 'react-feather';

export const Wrapper = styled.div`
  padding: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Timer = styled.h1`
  font-family: Gorga Grotesque Light;

  color: #8d98ab;

  width: 55px;
  font-size: 13px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Icon = styled.div`
  width: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Clock = styled(ClockTimer).attrs(({ size, icon, width }) => {
  return {
    icon: icon,
    size: size,
    width: width,
  };
})`
  height: 15px;
  color: #8d98ab;
`;
