import React, { useRef, useEffect, useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';

import { useTranslation } from 'react-i18next';

import useLocalStorage from '../../../hooks/useLocalStorage';

import Image1 from '../../../assets/img/SURVIVE_1.jpg';
import Image2 from '../../../assets/img/SURVIVE_2.jpg';
import Image3 from '../../../assets/img/SURVIVE_3.jpg';
import Image4 from '../../../assets/img/SURVIVE_4.jpg';
import Image5 from '../../../assets/img/SURVIVE_5.jpg';

import * as S from './style';

const controls = true;
const autoplay = false;
const interval = '5000';

const Images = [
  {
    src: Image1,
  },
  {
    src: Image2,
  },
  {
    src: Image3,
  },
  {
    src: Image4,
  },
  {
    src: Image5,
  },
];

// eslint-disable-next-line react/display-name
const Item = memo(({ item }) => {
  return (
    <S.Slide>
      <S.Image src={item.src} />
    </S.Slide>
  );
});

const InitialInfo = () => {
  const [openModal, setOpenModal] = useState(false);

  const [countImages, setCountImages] = useState(0);

  const [localStg, setLocalStg] = useLocalStorage('isFirstVisit', 'yes');

  useEffect(() => {
    setOpenModal(localStg === 'yes');
  }, []);

  const slideShow = useRef(null);
  const intervalSlideShow = useRef(null);

  const { t } = useTranslation();

  const indexImages = slideShow.current?.children.length - 1;

  const next = useCallback(() => {
    if (slideShow.current.children.length > 0) {
      if (countImages >= indexImages) return;

      setCountImages(countImages + 1);

      const firtsElement = slideShow.current.children[0];

      const sizeSlider = slideShow.current.children[0].offsetWidth;
      slideShow.current.style.transform = `translateX(-${sizeSlider}px)`;

      slideShow.current.style.transform = `translateX(0)`;
      slideShow.current.appendChild(firtsElement);
    }
  }, [indexImages, countImages]);

  const prev = useCallback(() => {
    if (slideShow.current.children.length > 0) {
      if (countImages <= 0) return;

      setCountImages(countImages - 1);

      const lastElement = slideShow.current.children[indexImages];

      slideShow.current.insertBefore(lastElement, slideShow.current.firstChild);

      const sizeSlider = slideShow.current.children[0].offsetWidth;

      slideShow.current.style.transform = `translateX(-${sizeSlider}px)`;

      slideShow.current.style.transform = `translateX(0)`;
    }
  }, [indexImages, countImages]);

  useEffect(() => {
    if (autoplay) {
      intervalSlideShow.current = setInterval(() => {
        next();
      }, interval);

      slideShow.current.addEventListener('mouseenter', () => {
        clearInterval(intervalSlideShow.current);
      });

      slideShow.current.addEventListener('mouseleave', () => {
        intervalSlideShow.current = setInterval(() => {
          next();
        }, 2000);
      });
    }
  }, [autoplay, interval, next]);

  return (
    <Modal isShowing={openModal} showingStyle={false}>
      <S.Wrapper>
        <S.Content>
          <S.SlideContainer ref={slideShow}>
            {Images.map((item, idx) => {
              return <Item key={idx} item={item} />;
            })}
          </S.SlideContainer>

          {controls && (
            <S.ContainerArrows>
              <S.ArrowLeftIcon onClick={prev} disabled={countImages <= 0} />

              <S.ArrowRightIcon
                onClick={next}
                disabled={countImages >= indexImages}
              />
            </S.ContainerArrows>
          )}

          <S.GroupButton>
            <S.ButtonAction
              onClick={() => {
                setLocalStg('no');
                setOpenModal(false);
              }}
            >
              {t('initialInfo.checkText.button.closeAndSeeNoMore')}
            </S.ButtonAction>
            <S.Button
              className="primary"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              {t('initialInfo.checkText.button.closeAndSeeLater')}
            </S.Button>
          </S.GroupButton>
        </S.Content>
      </S.Wrapper>
    </Modal>
  );
};

InitialInfo.propTypes = {
  data: PropTypes.array.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  interval: PropTypes.string,
  children: PropTypes.object.isRequired,
  controls: PropTypes.bool,
  speed: PropTypes.string,
  autoplay: PropTypes.bool,
};

InitialInfo.defaultProps = {
  isShowing: false,
  controls: false,
  handleConfirm: () => {},
  speed: '200',
  autoplay: false,
  interval: '2000',
};

Item.propTypes = {
  item: PropTypes.object,
};

export default InitialInfo;
