import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #1b212a;

  bottom: 0;
  width: 100%;
  height: 100px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border-top-width: 0.1px;
  border-bottom-width: 0.1px;

  padding: 10px;
  width: 100%;

  background: #1b212a;
`;

export const Info = styled.h1`
  font-family: Gorga Grotesque Light;
  color: #8d98ab;
  font-size: 9px;
  padding: 10px;
  text-align: center;
`;

export const GroupLogos = styled.div``;
