import React from 'react';

import PrivateRoute from './PrivateRoute';

// import Home from '../../pages/Home/homeTestCountDown';
import Home from '../../pages/Home/Home';
import Profile from '../../pages/Profile/Profile';
import MyChallenges from '../../pages/MyChallenges/MyChallenges';
import Faq from '../../pages/Faq/Faq';
import NotFound from '../../pages/NotFound/NotFound';
import ChallengeDetails from '../../pages/ChallengeDetails/ChallengeDetails';
import About from '../../pages/About/About';
import BrokenPage from '../../pages/BrokenPage/BrokenPage';
import ExpiredSession from '../../pages/ExpiredSession/ExpiredSession';

import { Switch, Route, Redirect } from 'react-router-dom';
import { useSurviveAuth } from '../SurviveAuth';

const routes = [
  { path: '/', Component: Home, exact: true, compactModeRoute: true },
  {
    path: '/my-challenges',
    Component: MyChallenges,
    exact: true,
    compactModeRoute: true,
    isPrivate: true,
  },
  {
    path: '/challenge/:slug',
    Component: ChallengeDetails,
    exact: true,
    compactModeRoute: true,
    isPrivate: false,
  },
  {
    path: '/profile',
    Component: Profile,
    exact: true,
    compactModeRoute: false,
    isPrivate: true,
  },
  { path: '/faq', Component: Faq, compactModeRoute: true, isPrivate: false },
  {
    path: '/about',
    Component: About,
    compactModeRoute: true,
    isPrivate: false,
  },
  {
    path: '/404',
    Component: NotFound,
    compactModeRoute: true,
    isPrivate: false,
  },
  {
    path: '/brokenpage',
    Component: BrokenPage,
    compactModeRoute: true,
    isPrivate: false,
  },
  {
    path: '/expiredsession',
    Component: ExpiredSession,
    compactModeRoute: true,
    isPrivate: false,
  },
];

const Routes = () => {
  const { isAuthenticated } = useSurviveAuth();

  const compactMode = process.env.REACT_APP_COMPACT_MODE === 'true';

  return (
    <Switch>
      {routes.map(
        ({ path, Component, exact, compactModeRoute, isPrivate }, idx) => {
          if (compactMode && !compactModeRoute) return;

          return (
            (isPrivate && (
              <PrivateRoute
                path={path}
                component={Component}
                key={idx}
                exact={exact}
              />
            )) || (
              <Route
                path={path}
                component={Component}
                key={idx}
                exact={exact}
              />
            )
          );
        }
      )}

      {isAuthenticated() !== undefined && (
        <Route path="*" component={NotFound}>
          <Redirect to="/404" />
        </Route>
      )}
    </Switch>
  );
};

export default Routes;
